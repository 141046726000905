import React from "react";
import { Switch, Route } from "react-router-dom";

import { Header, Navigator, Routes } from ".";

function Portal(props) {
  const routes = [
    { path: "/", key: "dashboard", Component: Routes.DashboardPage },

    // IsoAcoustics
    { path: "/products", key: "products", Component: Routes.ProductsPage },
    { path: "/products/:id", key: "product", Component: Routes.ProductPage },
    {
      path: "/product-series",
      key: "product-serieses",
      Component: Routes.ProductSeriesesPage,
    },
    {
      path: "/product-series/:id",
      key: "product-series",
      Component: Routes.ProductSeriesPage,
    },
    {
      path: "/categories",
      key: "categories",
      Component: Routes.CategoriesPage,
    },
    {
      path: "/categories/:id",
      key: "category",
      Component: Routes.CategoryPage,
    },
    { path: "/adapters", key: "adapters", Component: Routes.AdaptersPage },
    { path: "/adapters/:id", key: "adapter", Component: Routes.AdapterPage },
    { path: "/dealers", key: "dealers", Component: Routes.DealersPage },
    { path: "/dealers/:id", key: "dealer", Component: Routes.DealerPage },

    // Manufacturers
    { path: "/makes", key: "makes", Component: Routes.MakesPage },
    { path: "/makes/:id", key: "make", Component: Routes.MakePage },
    { path: "/models", key: "models", Component: Routes.ModelsPage },
    { path: "/models/:id", key: "model", Component: Routes.ModelPage },

    // Code Tables
    {
      path: "/thread-sizes",
      key: "thread-sizes",
      Component: Routes.ThreadSizesPage,
    },
    {
      path: "/thread-sizes/:id",
      key: "thread-size",
      Component: Routes.ThreadSizePage,
    },
    {
      path: "/thread-standards",
      key: "thread-standards",
      Component: Routes.ThreadStandardsPage,
    },
    {
      path: "/thread-standards/:id",
      key: "thread-standard",
      Component: Routes.ThreadStandardPage,
    },
    {
      path: "/input-questions",
      key: "input-questions",
      Component: Routes.InputQuestionsPage,
    },
    {
      path: "/input-questions/:id",
      key: "input-question",
      Component: Routes.InputQuestionPage,
    },
    {
      path: "/product-types",
      key: "product-types",
      Component: Routes.ProductTypesPage,
    },
    {
      path: "/product-types/:id",
      key: "product-type",
      Component: Routes.ProductTypePage,
    },
    {
      path: "/orientations",
      key: "orientations",
      Component: Routes.OrientationsPage,
    },
    {
      path: "/orientations/:id",
      key: "orientation",
      Component: Routes.OrientationPage,
    },
    {
      path: "/locales",
      key: "locales",
      Component: Routes.LocalesPage,
    },
    {
      path: "/locales/:id",
      key: "locale",
      Component: Routes.LocalePage,
    },
    {
      path: "/countries",
      key: "countries",
      Component: Routes.CountriesPage,
    },
    {
      path: "/countries/:id",
      key: "country",
      Component: Routes.CountryPage,
    },
    {
      path: "/languages",
      key: "languages",
      Component: Routes.LanguagesPage,
    },
    {
      path: "/languages/:id",
      key: "language",
      Component: Routes.LanguagePage,
    },

    // Access
    { path: "/users", key: "users", Component: Routes.UsersPage },
    { path: "/users/:id", key: "user", Component: Routes.UserPage },

    // Telemetry
    {
      path: "/customer-inputs",
      key: "customer-inputs",
      Component: Routes.CustomerInputsPage,
    },
    { path: "/logs", key: "logs", Component: Routes.LogsPage },
  ];
  return (
    <>
      <div className="header">
        <Header />
      </div>
      <div className="body">
        <div className="navigator" style={{ overflow: "auto" }}>
          <Navigator />
        </div>
        <div className="content">
          <Switch>
            {routes.map(({ path, key, Component }) => {
              return (
                <Route exact path={path} key={key}>
                  <Component />
                </Route>
              );
            })}
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Portal;
