import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import validator from "validator";

import {
  CategoryHandler,
  DealerHandler,
  LocaleHandler,
  ProductHandler
} from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Selection,
  SelectionMode,
  Shimmer,
  ShimmerElementType,
  ShimmeredDetailsList,
  Text,
  TextField
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

import { SelectPanel } from "../";
import { CategoryTable } from "./CategoriesPage";

const styles = mergeStyleSets(routeStyleSets);

function DealerPage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [locales, setLocales] = useState([]);
  const [products, setProducts] = useState([]);

  const [isSelectCategories, setIsSelectCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [dialogProduct, setDialogProduct] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { from } = location.state || {
    from: { pathname: "/dealers" }
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form, _locales, _products;
          // if dealer location, retrieve dealer, else set defaults
          if (location.pathname.endsWith("new")) {
            const values = await Promise.all([
              LocaleHandler.list(),
              ProductHandler.list()
            ]);
            _locales = values[0];
            _products = values[1];

            _form = { default_locale: state.locale_id };
          } else {
            const values = await Promise.all([
              LocaleHandler.list(),
              ProductHandler.list(),
              DealerHandler.get(params.id)
            ]);
            _locales = values[0];
            _products = values[1];

            const _dealer = values[2];
            _dealer.default_locale = _dealer.default_locale
              ? _dealer.default_locale.locale_id
              : "";
            _form = _dealer;
          }

          setLocales(_locales);
          setProducts(
            _products.sort((a, b) => {
              return a.name.localeCompare(b.name);
            })
          );
          setForm(_form);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);
  const shimmers = mergeStyles({
    marginTop: "20px",
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0"
      }
    }
  });

  const title = location.pathname.endsWith("new")
    ? "New Dealer"
    : `Dealer: ${form.name || ""}`;

  const [isValidated, setValidated] = useState(false);
  const isCodeValid = !validator.isEmpty((form.code || "").trim());
  const isNameValid = !validator.isEmpty((form.name || "").trim());
  const isDomainValid = !validator.isEmpty((form.domain || "").trim());
  const isEmailValid = !validator.isEmpty((form.email || "").trim());
  const isDefaultLocaleValid = !validator.isEmpty(
    (form.default_locale || "").toString()
  );

  // general
  const _codeTextField = React.createRef();
  const _nameTextField = React.createRef();
  const _domainTextField = React.createRef();
  const _emailTextField = React.createRef();
  const _uriTextField = React.createRef();
  const _defaultLocaleDropdownField = React.createRef();

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text
    });
  };

  const _onChangeDropdown = (attr, event, item) => {
    setForm({
      ...form,
      [attr]: item.key
    });
  };

  const _onClickSave = () => {
    if (
      isCodeValid &&
      isNameValid &&
      isDomainValid &&
      isEmailValid &&
      isDefaultLocaleValid
    ) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [
      isCodeValid,
      isNameValid,
      isDomainValid,
      isEmailValid,
      isDefaultLocaleValid
    ]
  };
  const _getInvalidPivotItemFormCount = itemKey => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          data.default_locale = data.default_locale
            ? {
                locale_id: data.default_locale
              }
            : null;
          data.products = (data.products || []).map(product => {
            return {
              product_id: product.product_id,
              dealer_product_uri: product.dealer_product_uri
            };
          });
          data.categories = (data.categories || []).map(category => {
            return { category_id: category.category_id };
          });

          if (data.dealer_id) {
            await DealerHandler.update(data);
          } else {
            await DealerHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const _selectionProducts = useConst(
    new Selection({
      getKey: product => {
        return product.product_id + "";
      },
      onSelectionChanged: () => {
        setSelectedProducts(_selectionProducts.getSelection());
      }
    })
  );

  const _selectionCategories = useConst(
    new Selection({
      getKey: category => {
        return category.category_id + "";
      },
      onSelectionChanged: () => {
        setSelectedCategories(_selectionCategories.getSelection());
      }
    })
  );

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "DEALERS" }]}
        dividerAs={dividerProps => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || ""
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" }
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 }
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" }
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" }
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText: "Please be patient while the dealer is being saved."
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } }
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount
                }}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" }
                        }}
                      >
                        Information
                      </Separator>
                      <TextField
                        componentRef={_codeTextField}
                        label="Code"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "code")}
                        value={form.code}
                        required={true}
                        errorMessage={
                          isValidated && !isCodeValid
                            ? "Code must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_nameTextField}
                        label="Name"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "name")}
                        value={form.name}
                        required={true}
                        errorMessage={
                          isValidated && !isNameValid
                            ? "Name must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_domainTextField}
                        label="Domain"
                        description="The provided domain must match the dealer's host that will be referencing the online calculator; subdomain and port are required if applicable. i.e. If the dealer's URI is https://www.codenstein.com:8080/isoacoustics the domain entered should be www.codenstein.com:8080"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "domain")}
                        value={form.domain}
                        required={true}
                        errorMessage={
                          isValidated && !isDomainValid
                            ? "Domain must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_emailTextField}
                        label="Email"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "email")}
                        value={form.email}
                        required={true}
                        errorMessage={
                          isValidated && !isEmailValid
                            ? "Email must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_uriTextField}
                        label="URI"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "uri")}
                        value={form.uri || ""}
                      ></TextField>
                    </div>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" }
                        }}
                      >
                        Details
                      </Separator>
                      <Dropdown
                        componentRef={_defaultLocaleDropdownField}
                        label="Default Locale"
                        placeholder="Select a locale"
                        styles={{ root: styles.field }}
                        selectedKey={form.default_locale || ""}
                        onChange={_onChangeDropdown.bind(
                          this,
                          "default_locale"
                        )}
                        options={locales.map(locale => {
                          return {
                            key: locale.locale_id,
                            text: locale.language ? locale.language.code : "-"
                          };
                        })}
                        required={true}
                        errorMessage={
                          isValidated && !isDefaultLocaleValid
                            ? "A locale must be selected."
                            : ""
                        }
                      ></Dropdown>
                    </div>
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey="categories"
                  headerText="Categories"
                  itemCount={_getInvalidPivotItemFormCount("categories")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "add",
                        text: "Add",
                        iconProps: { iconName: "Add" },
                        onClick: () => {
                          setIsSelectCategories(true);
                        }
                      },
                      {
                        key: "remove",
                        text: "Remove",
                        iconProps: { iconName: "Remove" },
                        disabled: selectedCategories.length === 0,
                        onClick: () => {
                          setForm({
                            ...form,
                            categories: (form.categories || []).filter(
                              category => {
                                return !selectedCategories.includes(category);
                              }
                            )
                          });
                        }
                      }
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  <CategoryTable
                    setKey="categories"
                    items={form.categories || []}
                    selection={_selectionCategories}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    hideRelated={true}
                  />
                  <SelectPanel
                    title="Select Categories"
                    isOpen={isSelectCategories}
                    dismissPanel={categories => {
                      setIsSelectCategories(false);
                      if (categories && categories.length > 0) {
                        setForm({
                          ...form,
                          categories: (form.categories || []).concat(categories)
                        });
                      }
                    }}
                    handler={CategoryHandler}
                    table={CategoryTable}
                    current={form.categories || []}
                  />
                </PivotItem>
                <PivotItem
                  itemKey="products"
                  headerText="Products"
                  itemCount={_getInvalidPivotItemFormCount("products")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "add",
                        text: "Add",
                        iconProps: { iconName: "Add" },
                        onClick: () => {
                          setDialogProduct({
                            product_id: -1,
                            dealer_product_uri: ""
                          });
                        }
                      },
                      {
                        key: "modify",
                        text: "Modify",
                        iconProps: { iconName: "Edit" },
                        disabled: selectedProducts.length !== 1,
                        onClick: () => {
                          setDialogProduct(selectedProducts[0]);
                        }
                      },
                      {
                        key: "remove",
                        text: "Remove",
                        iconProps: { iconName: "Remove" },
                        disabled: selectedProducts.length === 0,
                        onClick: () => {
                          setForm({
                            ...form,
                            products: (form.products || []).filter(product => {
                              return !selectedProducts.includes(product);
                            })
                          });
                        }
                      }
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  <DealerProductTable
                    setKey="products"
                    items={form.products || []}
                    selection={_selectionProducts}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    hideRelated={true}
                  />
                  {dialogProduct ? (
                    <ProductDialog
                      products={products}
                      dealer={form}
                      product={dialogProduct}
                      dismissDialog={product => {
                        const _dialogProduct = dialogProduct;
                        setDialogProduct(null);
                        if (product) {
                          const products = [...(form.products || [])];
                          if (_dialogProduct.product_id === -1) {
                            products.push(product);
                          } else {
                            const index = products.findIndex(
                              _product =>
                                _product.product_id === product.product_id
                            );
                            if (index >= 0) {
                              products[index] = product;
                            }
                            setSelectedProducts([product]);
                          }
                          setForm({
                            ...form,
                            products: products
                          });
                        }
                      }}
                    />
                  ) : null}
                </PivotItem>
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const ProductDialog = props => {
  const _dealer = props.dealer;
  const _product = props.product;

  const [product, setProduct] = useState(_product.product_id);
  const [uri, setUri] = useState(_product.dealer_product_uri);

  const _productDropdownField = React.createRef();
  const _uriTextField = React.createRef();

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: "Product",
        subText: "Enter the dealer product details:"
      }}
      hidden={false}
      minWidth={450}
      maxWidth={600}
      modalProps={{
        isBlocking: true
      }}
    >
      <Dropdown
        componentRef={_productDropdownField}
        label="Product"
        placeholder="Select a product"
        styles={{ root: styles.field }}
        selectedKey={product || ""}
        options={(props.products || []).map(product => {
          return {
            key: product.product_id,
            text: product.name,
            disabled: !!(_dealer.products || []).find(
              _product => _product.product_id === product.product_id
            )
          };
        })}
        onChange={(evt, item) => {
          setProduct(item.key);
        }}
        disabled={_product.product_id !== -1}
        required={true}
      />
      <TextField
        componentRef={_uriTextField}
        label="URI"
        styles={{ root: { marginBottom: "35px" } }}
        value={uri || ""}
        onChange={(evt, text) => {
          setUri(text);
        }}
      />
      <DialogFooter>
        <PrimaryButton
          text={_product.product_id === -1 ? "Add" : "Modify"}
          onClick={() => {
            let selectedProduct = Object.assign(
              { dealer_product_uri: uri },
              props.products.find(_product => _product.product_id === product)
            );
            props.dismissDialog(selectedProduct);
          }}
        />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            props.dismissDialog();
          }}
        />
      </DialogFooter>
    </Dialog>
  );
};

const DealerProductTable = props => {
  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 350,
      isSorted: true
    },
    {
      ...columnAttrCommon,
      name: "Dealer Product URI",
      key: "dealer_product_uri",
      fieldName: "dealer_product_uri",
      data: "string",
      minWidth: 250,
      maxWidth: 550
    }
  ];

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={(props.items || []).sort((a, b) => {
        return a.name.localeCompare(b.name);
      })}
    />
  );
};

export default DealerPage;
