import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import filesize from "filesize";
import validator from "validator";

import {
  AdapterHandler,
  CategoryHandler,
  OrientationHandler,
  ProductHandler,
  ProductSeriesHandler,
  ThreadSizeHandler,
  ThreadStandardHandler,
  Utilities,
} from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  getId,
  Breadcrumb,
  Checkbox,
  ComboBox,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Image,
  ImageFit,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  SelectableOptionMenuItemType,
  Selection,
  SelectionMode,
  Separator,
  Shimmer,
  ShimmerElementType,
  ShimmeredDetailsList,
  Stack,
  Text,
  TextField,
  VerticalDivider,
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

import { SelectPanel } from "../";
import { AdapterTable } from "./AdaptersPage";
import { CategoryTable } from "./CategoriesPage";
import { MarkdownPreview } from "../preview/MarkdownPreview";
import { TagsDialog } from "../dialogs/TagsDialog";

import TextFieldLocale from "../TextFieldLocale";

const styles = mergeStyleSets(routeStyleSets);
const shimmers = mergeStyles({
  marginTop: "20px",
  selectors: {
    "& > .ms-Shimmer-container": {
      margin: "10px 0",
    },
  },
});

const _getImagesSorted = (images) => {
  return (images || []).sort((a, b) => {
    return a.order - b.order;
  });
};

function cacheImage(image) {
  const img = new window.Image();
  img.src = `data:${image.mime_type};base64,${image.content}`;
}

function ProductPage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [productSeries, setProductSeries] = useState([]);
  const [threadStandards, setThreadStandards] = useState([]);
  const [threadSizes, setThreadSizes] = useState([]);
  const [orientations, setOrientations] = useState([]);

  const [isSelectCategories, setIsSelectCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSelectAdapters, setIsSelectAdapters] = useState(false);
  const [selectedAdapters, setSelectedAdapters] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [dialogConstraint, setDialogConstraint] = useState(false);
  const [selectedConstraints, setSelectedConstraints] = useState([]);

  const { from } = location.state || {
    from: { pathname: "/products" },
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form,
            _productSeries,
            _threadStandards,
            _threadSizes,
            _orientations;
          // if product location, retrieve product, else set defaults
          if (location.pathname.endsWith("new")) {
            const values = await Promise.all([
              ProductSeriesHandler.sorted(state.locale_id),
              ThreadStandardHandler.list(),
              ThreadSizeHandler.sorted(),
              OrientationHandler.list(),
            ]);
            _productSeries = values[0];
            _threadStandards = values[1];
            _threadSizes = values[2];
            _orientations = values[3];

            _form = {};
          } else {
            const values = await Promise.all([
              ProductSeriesHandler.sorted(state.locale_id),
              ThreadStandardHandler.list(),
              ThreadSizeHandler.sorted(),
              OrientationHandler.list(),
              ProductHandler.get(params.id),
            ]);
            _productSeries = values[0];
            _threadStandards = values[1];
            _threadSizes = values[2];
            _orientations = values[3];

            const _product = values[4];
            _product.product_series = _product.product_series
              ? _product.product_series.product_series_id
              : "";
            _product.thread_sizes = (_product.thread_sizes || []).map(
              (threadSize) => {
                return threadSize.thread_size_id;
              }
            );
            _form = _product;
          }

          Utilities.initResources(_form, state.locales, {
            description: "",
            uri: "",
            details: "",
            specifications: "",
            product_id: _form.product_id,
          });

          _form.images = _getImagesSorted(_form.images || []);
          _form.images.forEach((image) => {
            cacheImage(image);
          });

          setTimeout(() => {
            setProductSeries(_productSeries);
            setThreadStandards(_threadStandards);
            setThreadSizes(_threadSizes);
            setOrientations(_orientations);
            setForm(_form);
            setIsLoading(false);
          });
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const title = location.pathname.endsWith("new")
    ? "New Product"
    : `Product: ${form.name || ""}`;

  const [isValidated, setValidated] = useState(false);
  const isCodeValid = !validator.isEmpty((form.code || "").trim());
  const isNameValid = !validator.isEmpty((form.name || "").trim());

  // general
  const _codeTextField = React.createRef();
  const _nameTextField = React.createRef();
  const _productSeriesDropdownField = React.createRef();
  const _threadSizesComboboxField = React.createRef();
  const [preview, setPreview] = useState("");

  // images
  const _imageUploadField = React.createRef();
  const [dialogTags, setDialogTags] = useState(false);

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text,
    });
  };

  const _onChangeCheckbox = (attr, event, checked) => {
    setForm({
      ...form,
      [attr]: checked,
    });
  };

  const _onChangeComboBox = (attr, event, item) => {
    const selected = [...(form[attr] || [])];
    if (item.selected) {
      selected.push(item.key);
    } else {
      const index = selected.indexOf(item.key);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setForm({
      ...form,
      [attr]: selected,
    });
  };

  const _onChangeDropdown = (attr, event, item) => {
    setForm({
      ...form,
      [attr]: item.key,
    });
  };

  const _onClickSave = () => {
    if (isCodeValid && isNameValid) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [isCodeValid, isNameValid],
  };
  const _getInvalidPivotItemFormCount = (itemKey) => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          // dropdown
          data.product_series = data.product_series
            ? {
                product_series_id: data.product_series,
              }
            : null;

          // combobox
          data.thread_sizes = (data.thread_sizes || []).map(
            (thread_size_id) => {
              return { thread_size_id: thread_size_id };
            }
          );

          // lists
          data.categories = (data.categories || []).map((category) => {
            return { category_id: category.category_id };
          });
          data.adapters = (data.adapters || []).map((adapter) => {
            return { adapter_id: adapter.adapter_id };
          });

          data.images.forEach((image) => {
            if (Number.isFinite(image.product_image_id)) {
              // only update image order
              delete image.content;
              delete image.mime_type;
              delete image.filename;
              delete image.filesize;
            } else {
              delete image.product_image_id;
            }
          });

          data.constraints = (data.constraints || []).map((constraint) => {
            return {
              product_constraint_id: Number.isFinite(
                constraint.product_constraint_id
              )
                ? constraint.product_constraint_id
                : undefined,
              min_width: parseFloat(constraint.min_width, 10),
              max_width: parseFloat(constraint.max_width, 10),
              min_height: parseFloat(constraint.min_height, 10),
              max_height: parseFloat(constraint.max_height, 10),
              min_depth: parseFloat(constraint.min_depth, 10),
              max_depth: parseFloat(constraint.max_depth, 10),
              min_weight: parseFloat(constraint.min_weight, 10),
              max_weight: parseFloat(constraint.max_weight, 10),
              quantity: parseInt(constraint.quantity, 10),
            };
          });

          delete data.dealers;
          delete data.recommendations;

          if (data.product_id) {
            await ProductHandler.update(data);
          } else {
            await ProductHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const _selectionCategories = useConst(
    new Selection({
      getKey: (category) => {
        return category.category_id + "";
      },
      onSelectionChanged: () => {
        setSelectedCategories(_selectionCategories.getSelection());
      },
    })
  );

  const _selectionAdapters = useConst(
    new Selection({
      getKey: (adapter) => {
        return adapter.adapter_id + "";
      },
      onSelectionChanged: () => {
        setSelectedAdapters(_selectionAdapters.getSelection());
      },
    })
  );

  const _selectionConstraints = useConst(
    new Selection({
      getKey: (constraint) => {
        return constraint.product_constraint_id + "";
      },
      onSelectionChanged: () => {
        setSelectedConstraints(_selectionConstraints.getSelection());
      },
    })
  );

  const _selectionImages = useConst(
    new Selection({
      getKey: (image) => {
        return image.product_image_id + "";
      },
      onSelectionChanged: () => {
        setSelectedImages(_selectionImages.getSelection());
      },
    })
  );

  const _insertBeforeItem = (item) => {
    let dragIndex = form.images.indexOf(_draggedItem);
    let insertIndex = form.images.indexOf(item);

    const items = [...form.images];
    items.splice(dragIndex, 1);
    items.splice(insertIndex, 0, _draggedItem);
    // set orders
    items.forEach((item, index) => {
      item.order = index + 1;
    });
    setForm({
      ...form,
      images: _getImagesSorted(items),
    });
  };
  let _draggedItem = null;

  const _dragDropEvents = {
    canDrop: (dropContext, dragContext) => {
      return true;
    },
    canDrag: (item) => {
      return true;
    },
    onDragEnter: (item, event) => {
      // return string is the css classes that will be added to the entering element.
      return "details-list-drag-enter";
    },
    onDragLeave: (item, event) => {
      return;
    },
    onDrop: (item, event) => {
      if (_draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (item, itemIndex, selectedItems, event) => {
      _draggedItem = item;
    },
    onDragEnd: (item, event) => {
      _draggedItem = null;
    },
  };

  const _onChangeUploadFile = () => {
    if (_imageUploadField.current) {
      const file = _imageUploadField.current.files[0];
      if (file) {
        const reader = new FileReader();

        reader.addEventListener(
          "load",
          function () {
            // convert image file to base64 string
            const image = {
              product_image_id: getId(),
              filename: file.name,
              filesize: file.size,
              content: reader.result.substring(
                reader.result.indexOf("base64,") + "base64,".length
              ),
              mime_type: file.type,
              order: form.images.length + 1,
            };
            cacheImage(image);

            setTimeout(() => {
              setForm({
                ...form,
                images: _getImagesSorted(form.images.concat([image])),
              });
            });
          },
          false
        );
        reader.readAsDataURL(file);
      }
    }
  };

  const _onClickDownloadImage = () => {
    if (selectedImages.length === 1) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:${selectedImages[0].mime_type};base64, ${selectedImages[0].content}`
      );
      element.setAttribute("download", selectedImages[0].filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  };

  const _onClickDeleteImage = () => {
    if (selectedImages.length > 0) {
      const images = [...form.images];
      selectedImages.forEach((selectedImage) => {
        let imageIndex = images.indexOf(selectedImage);
        images.splice(imageIndex, 1);
      });
      images.forEach((item, index) => {
        item.order = index + 1;
      });
      setForm({
        ...form,
        images: _getImagesSorted(images),
      });
    }
  };

  const _onClickTagImage = () => {
    if (selectedImages.length > 0) {
      setDialogTags(selectedImages[0]);
    }
  };

  const onSpecificationsLocaleChanged = useCallback(
    (locale) => {
      if (locale) {
        const specifications = Utilities.getResourceFieldByLocale(
          form,
          "specifications",
          locale.locale_id
        );
        setPreview(specifications);
      } else {
        setPreview("");
      }
    },
    [form, setPreview]
  );

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "PRODUCTS" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText:
                    "Please be patient while the product is being saved.",
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } },
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount,
                }}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Information
                      </Separator>
                      <TextField
                        componentRef={_codeTextField}
                        label="Code"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "code")}
                        value={form.code}
                        required={true}
                        errorMessage={
                          isValidated && !isCodeValid
                            ? "Code must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_nameTextField}
                        label="Name"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "name")}
                        value={form.name}
                        required={true}
                        errorMessage={
                          isValidated && !isNameValid
                            ? "Name must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextFieldLocale
                        field="description"
                        label="Description"
                        form={form}
                        setForm={setForm}
                        styles={{ root: styles.field }}
                        multiline
                        rows={5}
                      />
                      <TextFieldLocale
                        field="uri"
                        label="URI"
                        form={form}
                        setForm={setForm}
                        styles={{ root: styles.field }}
                      />
                    </div>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Details
                      </Separator>
                      <Dropdown
                        componentRef={_productSeriesDropdownField}
                        label="Product Series"
                        placeholder="Select a product series"
                        styles={{ root: styles.field }}
                        selectedKey={form.product_series || ""}
                        onChange={_onChangeDropdown.bind(
                          this,
                          "product_series"
                        )}
                        options={productSeries.map((_productSeries) => {
                          let resource = (_productSeries.resources || []).find(
                            (resource) => {
                              return resource.locale_id === state.locale_id;
                            }
                          );

                          return {
                            key: _productSeries.product_series_id,
                            text: resource ? resource.name : "",
                          };
                        })}
                      ></Dropdown>
                      <ComboBox
                        componentRef={_threadSizesComboboxField}
                        multiSelect
                        selectedKey={form.thread_sizes || []}
                        label="Thread Sizes"
                        allowFreeform={false}
                        autoComplete="off"
                        styles={{ container: styles.field }}
                        onChange={_onChangeComboBox.bind(this, "thread_sizes")}
                        options={(threadSizes || [])
                          .reduce((list, threadSize, index) => {
                            if (
                              index === 0 ||
                              (threadSize.thread_standard &&
                                threadSizes[index - 1].thread_standard &&
                                threadSize.thread_standard
                                  .thread_standard_id !==
                                  threadSizes[index - 1].thread_standard
                                    .thread_standard_id)
                            ) {
                              const threadStandard = threadSize.thread_standard
                                ? threadStandards.find(function (
                                    threadStandard
                                  ) {
                                    return (
                                      threadStandard.thread_standard_id ===
                                      threadSize.thread_standard
                                        .thread_standard_id
                                    );
                                  })
                                : null;
                              const resource = threadStandard
                                ? Utilities.getResource(
                                    threadStandard,
                                    state.locale_id
                                  )
                                : null;
                              list.push(Utilities.getField(resource, "name"));
                            }
                            list.push(threadSize);
                            return list;
                          }, [])
                          .map((threadSize) => {
                            if (typeof threadSize === "string") {
                              return {
                                key: threadSize,
                                text: threadSize,
                                itemType: SelectableOptionMenuItemType.Header,
                              };
                            } else {
                              const resource = Utilities.getResource(
                                threadSize,
                                state.locale_id
                              );
                              const extra = Utilities.getField(
                                resource,
                                "extra"
                              );
                              return {
                                key: threadSize.thread_size_id,
                                text: [threadSize.description, extra]
                                  .join(" ")
                                  .trim(),
                              };
                            }
                          })}
                      />
                      <Checkbox
                        label="Is threaded"
                        styles={{ root: styles.checkbox }}
                        checked={form.threaded}
                        onChange={_onChangeCheckbox.bind(this, "threaded")}
                      />
                    </div>
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey="constraints"
                  headerText="Constraints"
                  itemCount={_getInvalidPivotItemFormCount("constraints")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "add",
                        text: "Add",
                        iconProps: { iconName: "Add" },
                        onClick: () => {
                          setDialogConstraint({
                            product_constraint_id: -1,
                            min_width: "",
                            max_width: "",
                            min_height: "",
                            max_height: "",
                            min_depth: "",
                            max_depth: "",
                            min_weight: "",
                            max_weight: "",
                            quantity: "",
                          });
                        },
                      },
                      {
                        key: "modify",
                        text: "Modify",
                        iconProps: { iconName: "Edit" },
                        disabled: selectedConstraints.length !== 1,
                        onClick: () => {
                          setDialogConstraint(selectedConstraints[0]);
                        },
                      },
                      {
                        key: "remove",
                        text: "Remove",
                        iconProps: { iconName: "Remove" },
                        disabled: selectedConstraints.length === 0,
                        onClick: () => {
                          setForm({
                            ...form,
                            constraints: (form.constraints || []).filter(
                              (constraint) => {
                                return !selectedConstraints.includes(
                                  constraint
                                );
                              }
                            ),
                          });
                        },
                      },
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  <ConstraintTable
                    setKey="constraints"
                    items={form.constraints || []}
                    selection={_selectionConstraints}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    hideRelated={true}
                  />
                  {dialogConstraint ? (
                    <ConstraintDialog
                      product={form}
                      constraint={dialogConstraint}
                      dismissDialog={(constraint) => {
                        setDialogConstraint(null);
                        if (constraint) {
                          const constraints = [...(form.constraints || [])];
                          if (constraint.product_constraint_id === -1) {
                            constraint.product_constraint_id = getId();
                            constraints.push(constraint);
                          } else {
                            const index = constraints.findIndex(
                              (_constraint) =>
                                _constraint.product_constraint_id ===
                                constraint.product_constraint_id
                            );
                            if (index >= 0) {
                              constraints[index] = constraint;
                            }
                            setSelectedConstraints([constraint]);
                          }
                          setForm({
                            ...form,
                            constraints: constraints,
                          });
                        }
                      }}
                    />
                  ) : null}
                </PivotItem>
                <PivotItem
                  itemKey="categories"
                  headerText="Categories"
                  itemCount={_getInvalidPivotItemFormCount("categories")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "add",
                        text: "Add",
                        iconProps: { iconName: "Add" },
                        onClick: () => {
                          setIsSelectCategories(true);
                        },
                      },
                      {
                        key: "remove",
                        text: "Remove",
                        iconProps: { iconName: "Remove" },
                        disabled: selectedCategories.length === 0,
                        onClick: () => {
                          setForm({
                            ...form,
                            categories: (form.categories || []).filter(
                              (category) => {
                                return !selectedCategories.includes(category);
                              }
                            ),
                          });
                        },
                      },
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  <CategoryTable
                    setKey="categories"
                    items={form.categories || []}
                    selection={_selectionCategories}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    hideRelated={true}
                  />
                  <SelectPanel
                    title="Select Categories"
                    isOpen={isSelectCategories}
                    dismissPanel={(categories) => {
                      setIsSelectCategories(false);
                      if (categories && categories.length > 0) {
                        setForm({
                          ...form,
                          categories: (form.categories || []).concat(
                            categories
                          ),
                        });
                      }
                    }}
                    handler={CategoryHandler}
                    table={CategoryTable}
                    current={form.categories || []}
                  />
                </PivotItem>
                <PivotItem
                  itemKey="adapters"
                  headerText="Adapters"
                  itemCount={_getInvalidPivotItemFormCount("adapters")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "add",
                        text: "Add",
                        iconProps: { iconName: "Add" },
                        onClick: () => {
                          setIsSelectAdapters(true);
                        },
                      },
                      {
                        key: "remove",
                        text: "Remove",
                        iconProps: { iconName: "Remove" },
                        disabled: selectedAdapters.length === 0,
                        onClick: () => {
                          setForm({
                            ...form,
                            adapters: (form.adapters || []).filter(
                              (adapter) => {
                                return !selectedAdapters.includes(adapter);
                              }
                            ),
                          });
                        },
                      },
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  <AdapterTable
                    setKey="adapters"
                    items={form.adapters || []}
                    selection={_selectionAdapters}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    hideRelated={true}
                  />
                  <SelectPanel
                    title="Select Adapters"
                    isOpen={isSelectAdapters}
                    dismissPanel={(adapters) => {
                      setIsSelectAdapters(false);
                      if (adapters && adapters.length > 0) {
                        setForm({
                          ...form,
                          adapters: (form.adapters || []).concat(adapters),
                        });
                      }
                    }}
                    handler={AdapterHandler}
                    table={AdapterTable}
                    current={form.adapters || []}
                  />
                </PivotItem>
                <PivotItem itemKey="specifications" headerText="Specifications">
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                    }}
                  >
                    <div style={{ padding: "0 10px" }}>
                      <div>
                        <div
                          style={{
                            backgroundColor: "rgba(0,0,0,0.1)",
                            padding: "8px",
                            margin: "12px 0",
                            fontWeight: "600",
                          }}
                        >
                          Markdown Editor
                        </div>
                        <Text>
                          Use the default template as a guide for creating
                          sections, headers and images.
                        </Text>
                        <TextFieldLocale
                          placeholder="Enter section details here"
                          field="specifications"
                          form={form}
                          setForm={setForm}
                          styles={{ root: styles.field }}
                          multiline
                          rows={20}
                          onLocaleChanged={onSpecificationsLocaleChanged}
                        />
                      </div>
                    </div>
                    <div style={{ width: "360px", overflowX: "hidden" }}>
                      <div
                        style={{
                          backgroundColor: "rgba(0,0,0,0.1)",
                          padding: "8px",
                          margin: "12px 0",
                          fontWeight: "600",
                        }}
                      >
                        Preview
                      </div>
                      <div style={{ padding: "0 20px 20px 20px" }}>
                        <MarkdownPreview
                          markdown={preview}
                          images={form.images}
                        />
                      </div>
                    </div>
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey="images"
                  headerText="Images"
                  itemCount={_getInvalidPivotItemFormCount("images")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "upload",
                        text: "Upload",
                        iconProps: { iconName: "Upload" },
                        onClick: () => {
                          if (_imageUploadField.current) {
                            _imageUploadField.current.click();
                          }
                        },
                      },
                      {
                        key: "upload-image",
                        onRender: () => (
                          <input
                            ref={_imageUploadField}
                            style={{ display: "none" }}
                            type="file"
                            accept="image/jpg,image/jpeg,image/png,image/svg+xml"
                            onChange={_onChangeUploadFile}
                          />
                        ),
                      },
                      {
                        key: "download",
                        text: "Download",
                        iconProps: { iconName: "Download" },
                        disabled: selectedImages.length !== 1,
                        onClick: _onClickDownloadImage,
                      },
                      {
                        key: "divider",
                        onRender: () => {
                          return (
                            <div
                              style={{
                                margin: "8px 4px",
                              }}
                            >
                              <VerticalDivider />
                            </div>
                          );
                        },
                      },
                      {
                        key: "delete",
                        iconProps: { iconName: "Delete" },
                        disabled: selectedImages.length === 0,
                        onClick: _onClickDeleteImage,
                      },
                    ]}
                    farItems={[
                      {
                        key: "tags",
                        text: "Set tags ...",
                        iconProps: { iconName: "Tag" },
                        disabled: selectedImages.length !== 1,
                        onClick: _onClickTagImage,
                      },
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  {dialogTags ? (
                    <TagsDialog
                      image={dialogTags}
                      dismissDialog={(tags) => {
                        const selectedImage = dialogTags;
                        setDialogTags(null);

                        if (tags !== undefined) {
                          const images = [...form.images];
                          const currentImage = images.find((image) => {
                            return image === selectedImage;
                          });
                          if (currentImage) {
                            currentImage.tags = tags;
                            setForm({
                              ...form,
                              images: images,
                            });
                          }
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <ImageTable
                    setKey="images"
                    items={form.images || []}
                    selection={_selectionImages}
                    selectionPreservedOnEmptyClick={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    dragDropEvents={_dragDropEvents}
                  />
                </PivotItem>
                {form && form.product_id ? (
                  <PivotItem
                    itemKey="dealers"
                    headerText="Dealers"
                    itemCount={_getInvalidPivotItemFormCount("dealers")}
                  >
                    <DealerProductTable
                      setKey="dealers"
                      items={form.dealers || []}
                      selectionPreservedOnEmptyClick={true}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      hideRelated={true}
                    />
                  </PivotItem>
                ) : null}
                {form && form.product_id ? (
                  <PivotItem
                    itemKey="recommendations"
                    headerText="Recommendations"
                    itemCount={_getInvalidPivotItemFormCount("recommendations")}
                  >
                    <RecommendationTable
                      setKey="recommendations"
                      orientations={orientations || []}
                      items={form.recommendations || []}
                      selectionPreservedOnEmptyClick={true}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      hideRelated={true}
                    />
                  </PivotItem>
                ) : null}
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const ConstraintDialog = (props) => {
  const constraint = props.constraint;

  const [minWidth, setMinWidth] = useState(constraint.min_width || "");
  const [maxWidth, setMaxWidth] = useState(constraint.max_width || "");
  const [minHeight, setMinHeight] = useState(constraint.min_height || "");
  const [maxHeight, setMaxHeight] = useState(constraint.max_height || "");
  const [minDepth, setMinDepth] = useState(constraint.min_depth || "");
  const [maxDepth, setMaxDepth] = useState(constraint.max_depth || "");
  const [minWeight, setMinWeight] = useState(constraint.min_weight || "");
  const [maxWeight, setMaxWeight] = useState(constraint.max_weight || "");
  const [quantity, setQuantity] = useState(constraint.quantity || "");

  const _minWidthTextField = React.createRef();
  const _maxWidthTextField = React.createRef();
  const _minHeightTextField = React.createRef();
  const _maxHeightTextField = React.createRef();
  const _minDepthTextField = React.createRef();
  const _maxDepthTextField = React.createRef();
  const _minWeightTextField = React.createRef();
  const _maxWeightTextField = React.createRef();
  const _quantityTextField = React.createRef();

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: "Constraints",
        subText: "Enter the constraint details for this product:",
      }}
      hidden={false}
      minWidth={450}
      maxWidth={600}
      modalProps={{
        isBlocking: true,
      }}
      onDismiss={() => {
        props.dismissDialog();
      }}
    >
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="top">
        <Stack.Item grow>
          <TextField
            componentRef={_minWidthTextField}
            label="Width"
            placeholder="Min"
            value={minWidth}
            onChange={(evt, text) => {
              setMinWidth(text);
            }}
          />
        </Stack.Item>
        <Stack.Item
          styles={{
            root: { width: "10px", paddingTop: "34px" },
          }}
        >
          <Text>-</Text>
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            componentRef={_maxWidthTextField}
            label="&nbsp;"
            placeholder="Max"
            value={maxWidth}
            onChange={(evt, text) => {
              setMaxWidth(text);
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <Text
            styles={{
              root: {
                width: "55px",
                paddingTop: "34px",
                display: "inline-block",
              },
            }}
          >
            (inches)
          </Text>
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 5 }}
        verticalAlign="top"
        styles={{ root: styles.field }}
      >
        <Stack.Item grow>
          <TextField
            componentRef={_minHeightTextField}
            label="Height"
            placeholder="Min"
            value={minHeight}
            onChange={(evt, text) => {
              setMinHeight(text);
            }}
          />
        </Stack.Item>
        <Stack.Item
          styles={{
            root: { width: "10px", paddingTop: "34px" },
          }}
        >
          <Text>-</Text>
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            componentRef={_maxHeightTextField}
            label="&nbsp;"
            placeholder="Max"
            value={maxHeight}
            onChange={(evt, text) => {
              setMaxHeight(text);
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <Text
            styles={{
              root: {
                width: "55px",
                paddingTop: "34px",
                display: "inline-block",
              },
            }}
          >
            (inches)
          </Text>
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 5 }}
        verticalAlign="top"
        styles={{ root: styles.field }}
      >
        <Stack.Item grow>
          <TextField
            componentRef={_minDepthTextField}
            label="Depth"
            placeholder="Min"
            value={minDepth}
            onChange={(evt, text) => {
              setMinDepth(text);
            }}
          />
        </Stack.Item>
        <Stack.Item
          styles={{
            root: { width: "10px", paddingTop: "34px" },
          }}
        >
          <Text>-</Text>
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            componentRef={_maxDepthTextField}
            label="&nbsp;"
            placeholder="Max"
            value={maxDepth}
            onChange={(evt, text) => {
              setMaxDepth(text);
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <Text
            styles={{
              root: {
                width: "55px",
                paddingTop: "34px",
                display: "inline-block",
              },
            }}
          >
            (inches)
          </Text>
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 5 }}
        verticalAlign="top"
        styles={{ root: styles.field }}
      >
        <Stack.Item grow>
          <TextField
            componentRef={_minWeightTextField}
            label="Weight"
            placeholder="Min"
            value={minWeight}
            onChange={(evt, text) => {
              setMinWeight(text);
            }}
          />
        </Stack.Item>
        <Stack.Item
          styles={{
            root: { width: "10px", paddingTop: "34px" },
          }}
        >
          <Text>-</Text>
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            componentRef={_maxWeightTextField}
            label="&nbsp;"
            placeholder="Max"
            value={maxWeight}
            onChange={(evt, text) => {
              setMaxWeight(text);
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <Text
            styles={{
              root: {
                width: "55px",
                paddingTop: "34px",
                display: "inline-block",
              },
            }}
          >
            (lbs)
          </Text>
        </Stack.Item>
      </Stack>
      <TextField
        componentRef={_quantityTextField}
        label="Quantity"
        styles={{ root: { marginBottom: "35px" } }}
        value={quantity || ""}
        onChange={(evt, text) => {
          setQuantity(text);
        }}
      />
      <DialogFooter>
        <PrimaryButton
          text={constraint.product_constraint_id === -1 ? "Add" : "Modify"}
          onClick={() => {
            props.dismissDialog({
              product_constraint_id: constraint.product_constraint_id,
              min_width: minWidth,
              max_width: maxWidth,
              min_height: minHeight,
              max_height: maxHeight,
              min_depth: minDepth,
              max_depth: maxDepth,
              min_weight: minWeight,
              max_weight: maxWeight,
              quantity,
            });
          }}
        />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            props.dismissDialog();
          }}
        />
      </DialogFooter>
    </Dialog>
  );
};

const ConstraintTable = (props) => {
  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Width (Min-Max)",
      key: "width",
      fieldName: "width",
      data: "string",
      minWidth: 120,
      maxWidth: 150,
      onRender: (item) => {
        return [
          item.min_width !== undefined ? item.min_width : "",
          item.max_width !== undefined ? item.max_width : "",
        ].join("-");
      },
    },
    {
      ...columnAttrCommon,
      name: "Height (Min-Max)",
      key: "height",
      fieldName: "height",
      data: "string",
      minWidth: 120,
      maxWidth: 150,
      onRender: (item) => {
        return [
          item.min_height !== undefined ? item.min_height : "",
          item.max_height !== undefined ? item.max_height : "",
        ].join("-");
      },
    },
    {
      ...columnAttrCommon,
      name: "Depth (Min-Max)",
      key: "depth",
      fieldName: "depth",
      data: "string",
      minWidth: 120,
      maxWidth: 150,
      onRender: (item) => {
        return [
          item.min_depth !== undefined ? item.min_depth : "",
          item.max_depth !== undefined ? item.max_depth : "",
        ].join("-");
      },
    },
    {
      ...columnAttrCommon,
      name: "Weight (Min-Max)",
      key: "weight",
      fieldName: "weight",
      data: "string",
      minWidth: 120,
      maxWidth: 150,
      onRender: (item) => {
        return [
          item.min_weight !== undefined ? item.min_weight : "",
          item.max_weight !== undefined ? item.max_weight : "",
        ].join("-");
      },
    },
    {
      ...columnAttrCommon,
      name: "Quantity",
      key: "quantity",
      fieldName: "quantity",
      data: "number",
      minWidth: 120,
      maxWidth: 150,
    },
  ];

  return <ShimmeredDetailsList {...props} columns={_columns} />;
};

const ImageTable = (props) => {
  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      isRowHeader: true,
      key: "thumbnail",
      iconName: "Picture",
      isIconOnly: true,
      minWidth: 150,
      maxWidth: 150,
      onRender: (item) => {
        return (
          <Image
            src={`data:${item.mime_type};base64,${item.content}`}
            width={150}
            height={150}
            imageFit={ImageFit.centerContain}
          />
        );
      },
    },
    {
      ...columnAttrCommon,
      name: "Filename",
      key: "filename",
      fieldName: "filename",
      data: "string",
      minWidth: 100,
      maxWidth: 550,
    },
    {
      ...columnAttrCommon,
      name: "Tags",
      key: "tags",
      fieldName: "tags",
      data: "string",
      minWidth: 100,
      maxWidth: 300,
      onRender: (item) => {
        return (item.tags || []).join(", ");
      },
    },
    {
      ...columnAttrCommon,
      name: "Dimensions",
      key: "dimensions",
      fieldName: "dimensions",
      data: "string",
      minWidth: 70,
      maxWidth: 120,
      onRender: (item) => {
        const img = new window.Image();
        img.src = `data:${item.mime_type};base64,${item.content}`;

        return img.width === 0 ? (
          <ProgressIndicator />
        ) : (
          `${img.width} x ${img.height}`
        );
      },
    },
    {
      ...columnAttrCommon,
      name: "Size",
      key: "filesize",
      fieldName: "filesize",
      data: "number",
      minWidth: 60,
      maxWidth: 90,
      onRender: (item) => {
        return filesize(item.filesize);
      },
    },
    {
      ...columnAttrCommon,
      name: "Mime Type",
      key: "mime",
      fieldName: "mime_type",
      data: "string",
      minWidth: 80,
      maxWidth: 200,
    },
    {
      ...columnAttrCommon,
      name: "Order",
      key: "order",
      fieldName: "order",
      data: "number",
      minWidth: 50,
      maxWidth: 90,
      isSorted: true,
    },
  ];

  return <ShimmeredDetailsList {...props} columns={_columns} />;
};

const DealerProductTable = (props) => {
  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 350,
      isSorted: true,
    },
    {
      ...columnAttrCommon,
      name: "Dealer Product URI",
      key: "dealer_product_uri",
      fieldName: "dealer_product_uri",
      data: "string",
      minWidth: 250,
      maxWidth: 550,
    },
  ];

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={(props.items || []).sort((a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      })}
    />
  );
};

const RecommendationTable = (props) => {
  const [state] = useStateContext();
  const { orientations = [] } = props;

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
      onRender: (item) => {
        return item.model ? item.model.code : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 550,
      isSorted: true,
      onRender: (item) => {
        return item.model ? item.model.name : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Orientation",
      key: "orientation",
      fieldName: "orientation",
      data: "string",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item) => {
        const orientation = item.orientation
          ? orientations.find(function (orientation) {
              return (
                orientation.orientation_id === item.orientation.orientation_id
              );
            })
          : null;
        const resource = orientation
          ? Utilities.getResource(orientation, state.locale_id)
          : null;

        return item.orientation ? Utilities.getField(resource, "name") : "N/A";
      },
    },
    {
      ...columnAttrCommon,
      name: "Quantity",
      key: "quantity",
      fieldName: "quantity",
      data: "number",
      minWidth: 150,
      maxWidth: 150,
    },
  ];

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={(props.items || []).sort((a, b) => {
        return (a.model ? a.model.name : "").localeCompare(
          b.model ? b.model.name : ""
        );
      })}
    />
  );
};

export default ProductPage;
