import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { StateProvider, useStateContext } from "./state";
import { LoginPage, ProtectedRoute, Portal } from "./components";

import {
  initializeIcons,
  loadTheme,
  Fabric,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";

import "./App.css";
import { SessionHandler, LocaleHandler } from "./api";

// Load Custom Fabric UI Theme
initializeIcons();
loadTheme({
  defaultFontStyle: {
    fontFamily: "Quicksand, sans-serif",
    fontWeight: "regular",
  },
  palette: {
    themePrimary: "#148647",
    themeLighterAlt: "#f2faf6",
    themeLighter: "#ccecda",
    themeLight: "#a3dbbc",
    themeTertiary: "#5ab784",
    themeSecondary: "#269658",
    themeDarkAlt: "#127a41",
    themeDark: "#0f6737",
    themeDarker: "#0b4c28",
    neutralLighterAlt: "#f8f8f8",
    neutralLighter: "#f4f4f4",
    neutralLight: "#eaeaea",
    neutralQuaternaryAlt: "#dadada",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c8c8",
    neutralTertiary: "#bab8b7",
    neutralSecondary: "#a3a2a0",
    neutralPrimaryAlt: "#8d8b8a",
    neutralPrimary: "#323130",
    neutralDark: "#605e5d",
    black: "#494847",
    white: "#ffffff",
  },
});

SuperTokensRequest.init(SessionHandler.REFRESH_URL, 440);
axios.defaults.withCredentials = true;

function App() {
  const initialState = {
    sessionUser: null,
    locales: [],
    locale: null,
    locale_id: null,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "sessionUser":
        return {
          ...state,
          sessionUser: action.sessionUser,
        };
      case "locale":
        return {
          ...state,
          locales: action.locales || [],
          locale: action.locale,
          locale_id: action.locale ? action.locale.locale_id : null,
        };
      default:
        return state;
    }
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Fabric className="fabric">
        <AppSession />
      </Fabric>
    </StateProvider>
  );
}

function AppSession() {
  const [state, dispatch] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          const locales = await LocaleHandler.list();
          dispatch({
            type: "locale",
            locales,
            locale:
              (locales || []).find((locale) => {
                return locale.language && locale.language.code.includes("en");
              }) || null,
          });

          const sessionUser = await SessionHandler.validate();
          dispatch({ type: "sessionUser", sessionUser });
        } catch (e) {}
        setIsLoading(false);
      })();
    }
  }, [isLoading, dispatch]);

  return isLoading ? (
    <Spinner
      size={SpinnerSize.large}
      styles={{ root: { flex: 1 } }}
      label="Loading..."
    />
  ) : (
    <Router>
      <Switch>
        <Route path="/login">
          {!state.sessionUser ? <LoginPage /> : <Redirect to="/" />}
        </Route>
        <ProtectedRoute path="/" Component={Portal} />
      </Switch>
    </Router>
  );
}
export default App;
