import React, { useEffect, useState } from "react";

import { LogHandler } from "../../api";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Pivot,
  PivotItem,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Text
} from "office-ui-fabric-react";

import { Pagination } from "@uifabric/experiments";

function LogsPage() {
  const [isLoadingAccessLogs, setIsLoadingAccessLogs] = useState(true);
  const [isLoadingApplicationLogs, setIsLoadingApplicationLogs] = useState(
    true
  );
  const [accessLogs, setAccessLogs] = useState([]);
  const [accessLogPage, setAccessLogPage] = useState(0);
  const [applicationLogs, setApplicationLogs] = useState([]);
  const [applicationLogPage, setApplicationLogPage] = useState(0);
  const [error, setError] = useState(null);

  const LOGS_PER_PAGE = 25;

  useEffect(() => {
    if (isLoadingAccessLogs) {
      (async () => {
        try {
          const accessLogs = await LogHandler.list("access", {
            start: accessLogPage * LOGS_PER_PAGE,
            limit: LOGS_PER_PAGE
          });
          setAccessLogs(accessLogs);
          setIsLoadingAccessLogs(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAccessLogs]);

  useEffect(() => {
    if (isLoadingApplicationLogs) {
      (async () => {
        try {
          const applicationLogs = await LogHandler.list("application", {
            start: applicationLogPage * LOGS_PER_PAGE,
            limit: LOGS_PER_PAGE
          });
          setApplicationLogs(applicationLogs);
          setIsLoadingApplicationLogs(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingApplicationLogs]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _onColumnClick = () => {};

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: _onColumnClick
  };

  const columns = [
    {
      ...columnAttrCommon,
      name: "Level",
      key: "level",
      fieldName: "level",
      data: "string",
      minWidth: 50,
      maxWidth: 50
    },
    {
      ...columnAttrCommon,
      name: "Timestamp",
      key: "timestamp",
      fieldName: "timestamp",
      data: "string",
      minWidth: 175,
      maxWidth: 175,
      isSorted: true,
      isSortedDescending: true
    },
    {
      ...columnAttrCommon,
      name: "Message",
      key: "message",
      fieldName: "message",
      data: "string",
      minWidth: 150,
      maxWidth: 550,
      isMultiline: true
    }
  ];

  return (
    <>
      <Breadcrumb
        items={[{ text: "TELEMETRY" }, { text: "LOGS" }]}
        dividerAs={dividerProps => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Logs
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || ""
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>

        <div style={{ marginTop: "10px" }}>
          <Pivot
            styles={{
              root: styles.pivotControl,
              count: styles.pivotItemInvalidCount
            }}
          >
            <PivotItem itemKey="access" headerText="Access">
              <CommandBar
                items={[
                  {
                    key: "refresh",
                    text: "Refresh",
                    iconProps: { iconName: "Refresh" },
                    onClick: () => {
                      setIsLoadingAccessLogs(true);
                    }
                  }
                ]}
                farItems={[{ key: "pagination", text: "pagination" }]}
                buttonAs={buttonProps => {
                  return buttonProps.text === "pagination" ? (
                    <Pagination
                      pageCount={
                        parseInt(accessLogs.count / LOGS_PER_PAGE, 10) + 1
                      }
                      itemsPerPage={LOGS_PER_PAGE}
                      totalItemCount={accessLogs.count}
                      selectedPageIndex={accessLogPage}
                      format="buttons"
                      styles={{
                        root: styles.pagination,
                        pageNumber: styles.paginationPageNumber
                      }}
                      onPageChange={index => {
                        setAccessLogPage(index);
                        setIsLoadingAccessLogs(true);
                      }}
                    />
                  ) : (
                    <buttonProps.defaultRender {...buttonProps} />
                  );
                }}
                styles={{ root: styles.commandbar }}
              />
              <ShimmeredDetailsList
                items={accessLogs.logs}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                compact={true}
                enableShimmer={isLoadingAccessLogs}
              />
            </PivotItem>
            <PivotItem itemKey="application" headerText="Application">
              <CommandBar
                items={[
                  {
                    key: "refresh",
                    text: "Refresh",
                    iconProps: { iconName: "Refresh" },
                    onClick: () => {
                      setIsLoadingApplicationLogs(true);
                    }
                  }
                ]}
                farItems={[{ key: "pagination", text: "pagination" }]}
                buttonAs={buttonProps => {
                  return buttonProps.text === "pagination" ? (
                    <Pagination
                      pageCount={
                        parseInt(applicationLogs.count / LOGS_PER_PAGE, 10) + 1
                      }
                      itemsPerPage={LOGS_PER_PAGE}
                      totalItemCount={applicationLogs.count}
                      selectedPageIndex={applicationLogPage}
                      format="buttons"
                      styles={{
                        root: styles.pagination,
                        pageNumber: styles.paginationPageNumber
                      }}
                      onPageChange={index => {
                        setApplicationLogPage(index);
                        setIsLoadingApplicationLogs(true);
                      }}
                    />
                  ) : (
                    <buttonProps.defaultRender {...buttonProps} />
                  );
                }}
                styles={{ root: styles.commandbar }}
              />

              <ShimmeredDetailsList
                items={applicationLogs.logs}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                compact={true}
                enableShimmer={isLoadingApplicationLogs}
              />
            </PivotItem>
          </Pivot>
        </div>
      </div>
    </>
  );
}

export default LogsPage;
