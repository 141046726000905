import { EntityHandler } from "./EntityHandler";

class LanguageHandler extends EntityHandler {
  constructor() {
    super("languages", "language_id");
  }

  async sorted(locale_id) {
    const list = await this.list();
    return list
      .map((language) => {
        const resource = (language.resources || []).find((resource) => {
          return resource.locale_id === locale_id;
        });
        language._name = resource ? resource.name : "";
        return language;
      })
      .sort((a, b) => {
        return (a._name || "").localeCompare(b._name || "");
      })
      .map((language) => {
        delete language._name;
        return language;
      });
  }
}

export default new LanguageHandler();
