import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";
import { ApiHandler } from "./ApiHandler";

SuperTokensRequest.makeSuper(axios);

export class BulkHandler extends ApiHandler {
  constructor(path) {
    super("bulk");
    this.path = path;
  }

  async import(file) {
    const formData = new FormData();
    formData.append("file", file);

    let response = await axios.post(
      `${this.uri}/${this.path}`,
      formData
      // {
      //   headers: {
      //     "Content-Type": "multipart/form-data"
      //   }
      // }
    );

    return response.data;
  }

  async export() {
    let response = await axios.get(`${this.uri}/${this.path}`);
    return response.data;
  }

  async download(path) {
    let response = await axios({
      url: `${process.env.REACT_APP_API_URL}${path}`,
      method: "GET",
      responseType: "blob",
    });
    return response.data;
  }
}
