import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { DealerHandler } from "../../api";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text
} from "office-ui-fabric-react";

const _getItemsSortedByName = items => {
  return (items || []).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

function DealersPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let dealers = await DealerHandler.list();
          setItems(dealers);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _getKey = item => {
    return item ? item.dealer_id : undefined;
  };
  const _onItemInvoked = item => {
    history.push("/dealers/" + item.dealer_id);
  };

  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(_selection.getSelection());
    }
  });

  const _onClickDeleteButton = () => {
    setIsDeleting(true);

    (async () => {
      try {
        await DealerHandler.delete(selectedItems);
        setItems(
          items.filter(item => {
            return !selectedItems.includes(item);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteDialogVisible(false);
      setIsDeleting(false);
    })();
  };

  const _onClickCancelButton = () => {
    setIsDeleteDialogVisible(false);
    setIsDeleting(false);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "DEALERS" }]}
        dividerAs={dividerProps => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Dealers
          </Text>
        </div>
        <CommandBar
          items={[
            {
              key: "newItem",
              text: "New dealer",
              iconProps: { iconName: "Add" },
              onClick: () => history.push("/dealers/new")
            },
            {
              key: "editItem",
              text: "Edit",
              disabled: selectedItems.length !== 1,
              iconProps: { iconName: "Edit" },
              onClick: () =>
                history.push("/dealers/" + selectedItems[0].dealer_id)
            },
            {
              key: "deleteItem",
              text: "Delete",
              disabled: selectedItems.length <= 0,
              iconProps: { iconName: "Trash" },
              onClick: () => {
                setIsDeleteDialogVisible(true);
              }
            }
          ]}
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || ""
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Dealer(s)",
            subText: "Are you sure you want to delete the selected dealer(s)?"
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {}
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteButton}
              text="Delete"
              disabled={isDeleting}
            />
            <DefaultButton onClick={_onClickCancelButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isDeleting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <DealerTable
          items={items}
          selectionMode={SelectionMode.multiple}
          getKey={_getKey}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={_selection}
          onItemInvoked={_onItemInvoked}
          enableShimmer={isLoading}
        />
      </div>
    </>
  );
}

export const DealerTable = props => {
  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 250,
      isSorted: true
    },
    {
      ...columnAttrCommon,
      name: "Domain",
      key: "domain",
      fieldName: "domain",
      data: "string",
      minWidth: 150,
      maxWidth: 350
    },
    {
      ...columnAttrCommon,
      name: "Email",
      key: "email",
      fieldName: "email",
      data: "string",
      minWidth: 150,
      maxWidth: 350
    },
    {
      ...columnAttrCommon,
      name: "URI",
      key: "uri",
      fieldName: "uri",
      data: "string",
      minWidth: 150,
      maxWidth: 350
    },
    {
      ...columnAttrCommon,
      name: "Default Locale",
      key: "locale",
      fieldName: "default_locale",
      data: "string",
      minWidth: 100,
      maxWidth: 200,
      onRender: item => {
        return item.default_locale && item.default_locale.language
          ? item.default_locale.language.code
          : "";
      }
    }
  ].concat(
    !props.hideRelated
      ? [
          {
            ...columnAttrCommon,
            name: "# Categories",
            key: "categories",
            fieldName: "category_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90
          },
          {
            ...columnAttrCommon,
            name: "# Products",
            key: "products",
            fieldName: "product_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90
          }
        ]
      : []
  );

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={_getItemsSortedByName(props.items || [])}
    />
  );
};

export default DealersPage;
