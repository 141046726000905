import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { EntityHandler } from "./EntityHandler";

SuperTokensRequest.makeSuper(axios);

class ProductSeriesHandler extends EntityHandler {
  constructor() {
    super("product-series", "product_series_id");
  }

  async order(objects) {
    let response = await axios.put(
      `${this.uri}/order`,
      (objects || []).map((obj) => {
        return obj[this.idAttr];
      })
    );
    return response.data;
  }

  async sorted(locale_id) {
    const list = await this.list();
    return list
      .map((productSeries) => {
        const resource = (productSeries.resources || []).find((resource) => {
          return resource.locale_id === locale_id;
        });
        productSeries._name = resource ? resource.name : "";
        return productSeries;
      })
      .sort((a, b) => {
        return (a._name || "").localeCompare(b._name || "");
      });
  }
}

export default new ProductSeriesHandler();
