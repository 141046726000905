import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { UserHandler } from "../../api";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text
} from "office-ui-fabric-react";

const _getItemsSortedByName = items => {
  return (items || []).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

function UsersPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  useEffect(() => {
    _getItemsSortedByName(items);
  }, [items]);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let users = await UserHandler.list();
          setItems(users);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _getKey = item => {
    return item ? item.user_id : undefined;
  };
  const _onItemInvoked = item => {
    history.push("/users/" + item.user_id);
  };
  const _onColumnClick = () => {};

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: _onColumnClick
  };

  const columns = [
    {
      ...columnAttrCommon,
      name: "Username",
      key: "username",
      fieldName: "username",
      data: "string",
      minWidth: 50,
      maxWidth: 90
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 250,
      isSorted: true
    },
    {
      ...columnAttrCommon,
      name: "Email",
      key: "email",
      fieldName: "email",
      data: "string",
      minWidth: 150,
      maxWidth: 550
    },
    {
      ...columnAttrCommon,
      name: "Roles",
      key: "roles",
      fieldName: "roles",
      data: "string",
      minWidth: 100,
      maxWidth: 200,
      onRender: item => {
        return (item.roles || [])
          .map(role => {
            return role.name;
          })
          .join(", ");
      }
    }
  ];

  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(_selection.getSelection());
    }
  });

  const _onClickDeleteButton = () => {
    setIsDeleting(true);

    (async () => {
      try {
        await UserHandler.delete(selectedItems);
        setItems(
          items.filter(item => {
            return !selectedItems.includes(item);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteDialogVisible(false);
      setIsDeleting(false);
    })();
  };

  const _onClickCancelButton = () => {
    setIsDeleteDialogVisible(false);
    setIsDeleting(false);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "SETTINGS" }, { text: "USERS" }]}
        dividerAs={dividerProps => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Users
          </Text>
        </div>
        <CommandBar
          items={[
            {
              key: "newItem",
              text: "New user",
              iconProps: { iconName: "Add" },
              onClick: () => history.push("/users/new")
            },
            {
              key: "editItem",
              text: "Edit",
              disabled: selectedItems.length !== 1,
              iconProps: { iconName: "Edit" },
              onClick: () => history.push("/users/" + selectedItems[0].user_id)
            },
            {
              key: "deleteItem",
              text: "Delete",
              disabled: selectedItems.length <= 0,
              iconProps: { iconName: "Trash" },
              onClick: () => {
                setIsDeleteDialogVisible(true);
              }
            }
          ]}
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || ""
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete User(s)",
            subText: "Are you sure you want to delete the selected user(s)?"
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {}
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteButton}
              text="Delete"
              disabled={isDeleting}
            />
            <DefaultButton onClick={_onClickCancelButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isDeleting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <ShimmeredDetailsList
          items={items}
          columns={columns}
          selectionMode={SelectionMode.multiple}
          getKey={_getKey}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={_selection}
          onItemInvoked={_onItemInvoked}
          enableShimmer={isLoading}
        />
      </div>
    </>
  );
}

export default UsersPage;
