import { EntityHandler } from "./EntityHandler";
import Utilities from "./Utilities";

const getThreadStandard = (threadSize) => {
  return threadSize.thread_standard ? threadSize.thread_standard.code : "-";
};

class ThreadSizeHandler extends EntityHandler {
  constructor() {
    super("thread-sizes", "thread_size_id");
  }

  async sorted() {
    const list = await this.list();
    return list.sort((a, b) => {
      const a_standard = getThreadStandard(a);
      const b_standard = getThreadStandard(b);

      return a_standard === b_standard
        ? a.nominal_size === b.nominal_size
          ? a.pitch_tpi === b.pitch_tpi
            ? Utilities.getField(a, "extra").localeCompare(
                Utilities.getField(b, "extra")
              )
            : a.pitch_tpi - b.pitch_tpi
          : a.nominal_size - b.nominal_size
        : a_standard.localeCompare(b_standard);
    });
  }
}

export default new ThreadSizeHandler();
