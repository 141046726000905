import React, { useEffect, useState } from "react";

import routeStyleSets from "./styles";

import { mergeStyleSets, Breadcrumb, Text } from "office-ui-fabric-react";

function DashboardPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        setIsLoading(false);
      })();
    }
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);

  return (
    <>
      <Breadcrumb
        items={[{ text: "DASHBOARD" }]}
        dividerAs={dividerProps => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Welcome
          </Text>
        </div>
      </div>
    </>
  );
}

export default DashboardPage;
