import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import validator from "validator";

import {
  MakeHandler,
  OrientationHandler,
  ThreadSizeHandler,
  ThreadStandardHandler,
  Utilities,
} from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  Breadcrumb,
  ComboBox,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  SelectableOptionMenuItemType,
  SelectionMode,
  Separator,
  Shimmer,
  ShimmerElementType,
  Text,
  TextField,
} from "office-ui-fabric-react";

import { ModelTable } from "./ModelsPage";
// import { ProductTable } from "./ProductsPage";

function MakePage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [threadStandards, setThreadStandards] = useState([]);
  const [threadSizes, setThreadSizes] = useState([]);
  const [orientations, setOrientations] = useState([]);

  const { from } = location.state || {
    from: { pathname: "/makes" },
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form, _threadStandards, _threadSizes, _orientations;
          // if make location, retrieve make, else set defaults
          if (location.pathname.endsWith("new")) {
            const values = await Promise.all([
              ThreadStandardHandler.list(),
              ThreadSizeHandler.sorted(),
              OrientationHandler.list(),
            ]);
            _threadStandards = values[0];
            _threadSizes = values[1];
            _orientations = values[2];

            _form = { thread_sizes: [] };
          } else {
            const values = await Promise.all([
              ThreadStandardHandler.list(),
              ThreadSizeHandler.sorted(),
              OrientationHandler.list(),
              MakeHandler.get(params.id),
            ]);
            _threadStandards = values[0];
            _threadSizes = values[1];
            _orientations = values[2];

            const _make = values[3];
            _make.thread_sizes = (_make.thread_sizes || []).map(
              (threadSize) => {
                return threadSize.thread_size_id;
              }
            );
            _form = _make;
          }

          setThreadStandards(_threadStandards || []);
          setThreadSizes(_threadSizes || []);
          setOrientations(_orientations || []);

          setForm(_form);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);
  const shimmers = mergeStyles({
    marginTop: "20px",
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });

  const title = location.pathname.endsWith("new")
    ? "New Make"
    : `Make: ${form.name || ""}`;

  const [isValidated, setValidated] = useState(false);
  const isCodeValid = !validator.isEmpty((form.code || "").trim());
  const isNameValid = !validator.isEmpty((form.name || "").trim());

  // general
  const _codeTextField = React.createRef();
  const _nameTextField = React.createRef();
  const _threadSizesComboboxField = React.createRef();

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text,
    });
  };

  const _onChangeComboBox = (attr, event, item) => {
    const selected = [...(form[attr] || [])];
    if (item.selected) {
      selected.push(item.key);
    } else {
      const index = selected.indexOf(item.key);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setForm({
      ...form,
      [attr]: selected,
    });
  };

  const _onClickSave = () => {
    if (isCodeValid && isNameValid) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [isCodeValid, isNameValid],
  };
  const _getInvalidPivotItemFormCount = (itemKey) => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          data.thread_sizes = data.thread_sizes.map((thread_size_id) => {
            return {
              thread_size_id: thread_size_id,
            };
          });

          if (data.make_id) {
            await MakeHandler.update(data);
          } else {
            await MakeHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "MANUFACTURERS" }, { text: "MAKES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText: "Please be patient while the make is being saved.",
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } },
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount,
                }}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Information
                      </Separator>
                      <TextField
                        componentRef={_codeTextField}
                        label="Code"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "code")}
                        value={form.code}
                        required={true}
                        errorMessage={
                          isValidated && !isCodeValid
                            ? "Code must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_nameTextField}
                        label="Name"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "name")}
                        value={form.name}
                        required={true}
                        errorMessage={
                          isValidated && !isNameValid
                            ? "Name must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                    </div>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Details
                      </Separator>
                      <ComboBox
                        componentRef={_threadSizesComboboxField}
                        multiSelect
                        selectedKey={form.thread_sizes || []}
                        label="Thread Sizes"
                        allowFreeform={false}
                        autoComplete="off"
                        onChange={_onChangeComboBox.bind(this, "thread_sizes")}
                        options={(threadSizes || [])
                          .reduce((list, threadSize, index) => {
                            if (
                              index === 0 ||
                              (threadSize.thread_standard &&
                                threadSizes[index - 1].thread_standard &&
                                threadSize.thread_standard
                                  .thread_standard_id !==
                                  threadSizes[index - 1].thread_standard
                                    .thread_standard_id)
                            ) {
                              const threadStandard = threadSize.thread_standard
                                ? threadStandards.find(function (
                                    threadStandard
                                  ) {
                                    return (
                                      threadStandard.thread_standard_id ===
                                      threadSize.thread_standard
                                        .thread_standard_id
                                    );
                                  })
                                : null;
                              const resource = threadStandard
                                ? Utilities.getResource(
                                    threadStandard,
                                    state.locale_id
                                  )
                                : null;
                              list.push(Utilities.getField(resource, "name"));
                            }
                            list.push(threadSize);
                            return list;
                          }, [])
                          .map((threadSize) => {
                            if (typeof threadSize === "string") {
                              return {
                                key: threadSize,
                                text: threadSize,
                                itemType: SelectableOptionMenuItemType.Header,
                              };
                            } else {
                              const resource = Utilities.getResource(
                                threadSize,
                                state.locale_id
                              );
                              const extra = Utilities.getField(resource, "extra");
                              return {
                                key: threadSize.thread_size_id,
                                text: [threadSize.description, extra]
                                  .join(" ")
                                  .trim(),
                              };
                            }
                          })}
                      />
                    </div>
                  </div>
                </PivotItem>
                {form && form.make_id ? (
                  <PivotItem
                    itemKey="models"
                    headerText="Models"
                    itemCount={_getInvalidPivotItemFormCount("models")}
                  >
                    <ModelTable
                      setKey="models"
                      orientations={orientations || []}
                      items={form.models || []}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      hideMake={true}
                      hideRelated={true}
                    />
                  </PivotItem>
                ) : null}
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default MakePage;
