import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ProductSeriesHandler, Utilities } from "../../api";
import { useStateContext } from "../../state";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

const _getItemsSortedByOrder = (items) => {
  return (items || []).sort((a, b) => {
    return a.order - b.order;
  });
};

const _getItemsSortedByName = (items, locale_id) => {
  return (items || []).sort((a, b) => {
    let aResource = Utilities.getResource(a, locale_id);
    let bResource = Utilities.getResource(b, locale_id);
    return Utilities.getField(aResource, "name").localeCompare(
      Utilities.getField(bResource, "name")
    );
  });
};

function ProductSeriesesPage() {
  const history = useHistory();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const [ordering, setOrdering] = useState({ visible: false, items: [] });

  useEffect(() => {
    _getItemsSortedByName(items, state.locale_id);
  }, [items, state]);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let productSeries = await ProductSeriesHandler.list();
          setItems(productSeries);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _getKey = (item) => {
    return item ? item.product_series_id : undefined;
  };
  const _onItemInvoked = (item) => {
    history.push("/product-series/" + item.product_series_id);
  };
  const _onColumnClick = () => {};

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: _onColumnClick,
  };

  const columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 250,
      isSorted: true,
      onRender: (item) => {
        let resource = (item.resources || []).find((resource) => {
          return resource.locale_id === state.locale_id;
        });
        return resource ? resource.name : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Description",
      key: "description",
      fieldName: "description",
      data: "string",
      minWidth: 150,
      maxWidth: 550,
      onRender: (item) => {
        let resource = (item.resources || []).find((resource) => {
          return resource.locale_id === state.locale_id;
        });
        return resource ? resource.description : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Product Type",
      key: "type",
      fieldName: "product_type",
      data: "string",
      minWidth: 70,
      maxWidth: 90,
      onRender: (item) => {
        return item.product_type ? item.product_type.code : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Order",
      key: "order",
      fieldName: "order",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "# Images",
      key: "images",
      fieldName: "image_count",
      data: "number",
      minWidth: 80,
      maxWidth: 90,
    },
  ];

  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(_selection.getSelection());
    },
  });

  const _onClickDeleteButton = () => {
    setIsDeleting(true);

    (async () => {
      try {
        await ProductSeriesHandler.delete(selectedItems);
        setItems(
          items.filter((item) => {
            return !selectedItems.includes(item);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteDialogVisible(false);
      setIsDeleting(false);
    })();
  };

  const _onClickCancelButton = () => {
    setIsDeleteDialogVisible(false);
    setIsDeleting(false);
  };

  const _onClickSaveOrderingButton = () => {
    setIsOrdering(true);

    (async () => {
      try {
        await ProductSeriesHandler.order(
          _getItemsSortedByOrder(ordering.items)
        );
        setItems(ordering.items);
        setOrdering({ visible: false, items: [] });
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsOrdering(false);
    })();
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "PRODUCT SERIES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Product Series
          </Text>
        </div>
        <CommandBar
          items={[
            {
              key: "newItem",
              text: "New product series",
              iconProps: { iconName: "Add" },
              onClick: () => history.push("/product-series/new"),
            },
            {
              key: "editItem",
              text: "Edit",
              disabled: selectedItems.length !== 1,
              iconProps: { iconName: "Edit" },
              onClick: () =>
                history.push(
                  "/product-series/" + selectedItems[0].product_series_id
                ),
            },
            {
              key: "deleteItem",
              text: "Delete",
              disabled: selectedItems.length <= 0,
              iconProps: { iconName: "Trash" },
              onClick: () => {
                setIsDeleteDialogVisible(true);
              },
            },
          ]}
          farItems={
            ordering.visible
              ? [
                  {
                    key: "saveOrderItems",
                    text: "Save",
                    iconProps: { iconName: "Save" },
                    onClick: _onClickSaveOrderingButton,
                  },
                  {
                    key: "cancelOrderItems",
                    text: "Cancel",
                    iconProps: { iconName: "Cancel" },
                    onClick: () => {
                      setOrdering({ visible: false, items: [] });
                    },
                  },
                ]
              : [
                  {
                    key: "ordertItems",
                    text: "Set order",
                    iconProps: { iconName: "SortLines" },
                    onClick: () => {
                      setOrdering({
                        visible: true,
                        items: _getItemsSortedByOrder(
                          // deep clone items
                          JSON.parse(JSON.stringify(items)) || []
                        ),
                      });
                    },
                  },
                ]
          }
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Product Series(s)",
            subText:
              "Are you sure you want to delete the selected product series(s)?",
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {},
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteButton}
              text="Delete"
              disabled={isDeleting}
            />
            <DefaultButton onClick={_onClickCancelButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isDeleting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isOrdering}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Saving...",
            subText:
              "Please be patient while the product series list order is being saved.",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <ProgressIndicator />
        </Dialog>
        {ordering.visible ? (
          <OrderableProductSeriesTable items={ordering.items} />
        ) : (
          <ShimmeredDetailsList
            items={items}
            columns={columns}
            selectionMode={SelectionMode.multiple}
            getKey={_getKey}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            selection={_selection}
            onItemInvoked={_onItemInvoked}
            enableShimmer={isLoading}
          />
        )}
      </div>
    </>
  );
}

const OrderableProductSeriesTable = (props) => {
  const [state] = useStateContext();

  const [items, setItems] = useState(props.items);
  const [, setSelectedItems] = useState([]);

  const _selection = useConst(
    new Selection({
      getKey: (productSeries) => {
        return productSeries.product_series_id + "";
      },
      onSelectionChanged: () => {
        setSelectedItems(_selection.getSelection());
      },
    })
  );

  const _insertBeforeItem = (item) => {
    let dragIndex = items.indexOf(_draggedItem);
    let insertIndex = items.indexOf(item);

    const _items = [...items];
    _items.splice(dragIndex, 1);
    _items.splice(insertIndex, 0, _draggedItem);
    // set orders
    _items.forEach((_item, index) => {
      _item.order = index + 1;
    });
    setItems(_getItemsSortedByOrder(_items));
  };

  let _draggedItem = null;

  const _dragDropEvents = {
    canDrop: (dropContext, dragContext) => {
      return true;
    },
    canDrag: (item) => {
      return true;
    },
    onDragEnter: (item, event) => {
      // return string is the css classes that will be added to the entering element.
      return "details-list-drag-enter";
    },
    onDragLeave: (item, event) => {
      return;
    },
    onDrop: (item, event) => {
      if (_draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (item, itemIndex, selectedItems, event) => {
      _draggedItem = item;
    },
    onDragEnd: (item, event) => {
      _draggedItem = null;
    },
  };

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 250,
      onRender: (item) => {
        let resource = (item.resources || []).find((resource) => {
          return resource.locale_id === state.locale_id;
        });
        return resource ? resource.name : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Order",
      key: "order",
      fieldName: "order",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
      isSorted: true,
    },
  ];

  return (
    <ShimmeredDetailsList
      setKey="orderable-product-series"
      columns={_columns}
      items={items || []}
      selection={_selection}
      selectionPreservedOnEmptyClick={true}
      selectionMode={SelectionMode.single}
      layoutMode={DetailsListLayoutMode.justified}
      dragDropEvents={_dragDropEvents}
      isHeaderVisible={true}
    />
  );
};

export default ProductSeriesesPage;
