import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import validator from "validator";

import { ThreadSizeHandler, ThreadStandardHandler, Utilities } from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  Breadcrumb,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Shimmer,
  ShimmerElementType,
  Text,
  TextField,
} from "office-ui-fabric-react";

import TextFieldLocale from "../TextFieldLocale";

function ThreadSizePage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [threadStandards, setThreadStandards] = useState([]);

  const { from } = location.state || {
    from: { pathname: "/thread-sizes" },
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form, _threadStandards;
          // if thread size location, retrieve thread standards, else set defaults
          if (location.pathname.endsWith("new")) {
            _threadStandards = await ThreadStandardHandler.list();
            _form = {};
          } else {
            const values = await Promise.all([
              ThreadStandardHandler.list(),
              ThreadSizeHandler.get(params.id),
            ]);
            _threadStandards = values[0];

            const _threadSize = values[1];
            _threadSize.thread_standard = _threadSize.thread_standard
              ? _threadSize.thread_standard.code
              : "";
            _form = _threadSize;
          }

          Utilities.initResources(_form, state.locales, {
            extra: "",
            thread_size_id: _form.thread_size_id,
          });

          setThreadStandards(_threadStandards);
          setForm(_form);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);
  const shimmers = mergeStyles({
    marginTop: "20px",
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });

  const title = location.pathname.endsWith("new")
    ? "New Thread Size"
    : `Thread Size: ${form.description || ""}`;

  const [isValidated, setValidated] = useState(false);
  const isDescriptionValid = !validator.isEmpty(
    (form.description || "").trim()
  );
  const isNominalSizeValid =
    !validator.isEmpty(
      (form.nominal_size !== undefined ? form.nominal_size + "" : "").trim()
    ) && !isNaN(parseFloat(form.nominal_size, 10));
  const isPitchTpiValid =
    !validator.isEmpty(
      (form.pitch_tpi !== undefined ? form.pitch_tpi + "" : "").trim()
    ) && !isNaN(parseFloat(form.pitch_tpi, 10));
  const isThreadStandardValid = !validator.isEmpty(
    (form.thread_standard || "").trim()
  );

  // general
  const _descriptionTextField = React.createRef();
  const _nominalSizeTextField = React.createRef();
  const _pitchTpiTextField = React.createRef();
  const _threadStandardDropdownField = React.createRef();

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text,
    });
  };

  const _onChangeDropdown = (attr, event, item) => {
    setForm({
      ...form,
      [attr]: item.key,
    });
  };

  const _onClickSave = () => {
    if (
      isDescriptionValid &&
      isNominalSizeValid &&
      isPitchTpiValid &&
      isThreadStandardValid
    ) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [
      isDescriptionValid,
      isNominalSizeValid,
      isPitchTpiValid,
      isThreadStandardValid,
    ],
  };
  const _getInvalidPivotItemFormCount = (itemKey) => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          data.thread_standard = data.thread_standard
            ? threadStandards.find((threadStandard) => {
                return threadStandard.code === data.thread_standard;
              })
            : null;

          if (form.thread_size_id) {
            await ThreadSizeHandler.update(data);
          } else {
            await ThreadSizeHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "SETTINGS" }, { text: "CODE TABLES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText:
                    "Please be patient while the thread size is being saved.",
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } },
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount,
                }}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Information
                      </Separator>
                      <TextField
                        componentRef={_descriptionTextField}
                        label="Description"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "description")}
                        value={form.description}
                        required={true}
                        errorMessage={
                          isValidated && !isDescriptionValid
                            ? "Description must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_nominalSizeTextField}
                        label="Nominal Size"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "nominal_size")}
                        value={form.nominal_size}
                        required={true}
                        errorMessage={
                          isValidated && !isNominalSizeValid
                            ? "Nominal size must have a valid decimal number."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_pitchTpiTextField}
                        label="Pitch/TPI"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "pitch_tpi")}
                        value={form.pitch_tpi}
                        required={true}
                        errorMessage={
                          isValidated && !isPitchTpiValid
                            ? "Pitch/TPI must have a valid decimal number."
                            : ""
                        }
                      ></TextField>
                      <TextFieldLocale
                        field="extra"
                        label="Extra"
                        form={form}
                        setForm={setForm}
                        styles={{ root: styles.field }}
                      />
                    </div>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Classification
                      </Separator>
                      <Dropdown
                        componentRef={_threadStandardDropdownField}
                        label="Standard"
                        placeholder="Select a thread standard"
                        styles={{ root: styles.field }}
                        selectedKey={form.thread_standard || ""}
                        onChange={_onChangeDropdown.bind(
                          this,
                          "thread_standard"
                        )}
                        options={threadStandards.map((threadStandard) => {
                          const _resource = Utilities.getResource(
                            threadStandard,
                            state.locale_id
                          );
                          return {
                            key: threadStandard.code,
                            text: [
                              (threadStandard.code || "").toUpperCase(),
                              _resource ? _resource.name : "",
                            ].join(" - "),
                          };
                        })}
                        required={true}
                        errorMessage={
                          isValidated && !isThreadStandardValid
                            ? "A thread standard must be selected."
                            : ""
                        }
                      ></Dropdown>
                    </div>
                  </div>
                </PivotItem>
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ThreadSizePage;
