import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { ApiHandler } from "./ApiHandler";

SuperTokensRequest.makeSuper(axios);

class UserHandler extends ApiHandler {
  constructor() {
    super("users");
  }
  async list() {
    let response = await axios.get(`${this.uri}`);
    return response.data;
  }
  async get(userId) {
    let response = await axios.get(`${this.uri}/${userId}`);
    return response.data;
  }
  async new(user) {
    let response = await axios.post(`${this.uri}/users`, user);
    return response.data;
  }
  async update(user) {
    let response = await axios.put(`${this.uri}/${user.user_id}`, user);
    return response.data;
  }
  async delete(users) {
    let response = await axios.delete(`${this.uri}`, {
      data: (users || []).map((user) => {
        return user.user_id;
      }),
    });
    return response.data;
  }
}

export default new UserHandler();
