import { EntityHandler } from "./EntityHandler";

class CountryHandler extends EntityHandler {
  constructor() {
    super("countries", "country_id");
  }

  async sorted(locale_id) {
    const list = await this.list();
    return list
      .map((country) => {
        const resource = (country.resources || []).find((resource) => {
          return resource.locale_id === locale_id;
        });
        country._name = resource ? resource.name : "";
        return country;
      })
      .sort((a, b) => {
        return (a._name || "").localeCompare(b._name || "");
      })
      .map((country) => {
        delete country._name;
        return country;
      });
  }
}

export default new CountryHandler();
