import axios from "axios";
import SuperTokensRequest from "supertokens-website/axios";

import { EntityHandler } from "./EntityHandler";

SuperTokensRequest.makeSuper(axios);

class ProductHandler extends EntityHandler {
  constructor() {
    super("products", "product_id");
  }

  async order(objects) {
    let response = await axios.put(
      `${this.uri}/order`,
      (objects || []).map((obj) => {
        return obj[this.idAttr];
      })
    );
    return response.data;
  }
}

export default new ProductHandler();
