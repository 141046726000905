import React, { useRef, useState } from "react";

import { useStateContext } from "../state";
import { SessionHandler, Utilities } from "../api";
import logo from "../logo.svg";

import {
  getId,
  getTheme,
  mergeStyleSets,
  Callout,
  DefaultButton,
  DirectionalHint,
  FontWeights,
  Persona,
  PersonaSize
} from "office-ui-fabric-react";

function Header(props) {
  const theme = getTheme();
  const [state, dispatch] = useStateContext();

  const styles = mergeStyleSets({
    callout: {
      minWidth: 250,
      maxWidth: 300
    },
    header: {
      padding: "15px 15px 5px"
    },
    title: [
      theme.fonts.large,
      {
        margin: 0,
        fontWeight: FontWeights.semilight
      }
    ],
    inner: {
      height: "100%",
      padding: "0 15px 10px"
    },
    actions: {
      position: "relative",
      marginTop: 20,
      width: "100%",
      whiteSpace: "nowrap",
      textAlign: "right"
    },
    subtext: [
      theme.fonts.small,
      {
        margin: 0,
        fontWeight: FontWeights.semilight
      }
    ],
    link: [
      theme.fonts.medium,
      {
        color: theme.palette.neutralPrimary
      }
    ]
  });

  const [calloutVisible, setCalloutVisible] = useState(false);

  const _labelId = getId("callout-label");
  const _descriptionId = getId("callout-description");

  const _onPersonaClicked = () => {
    setCalloutVisible(!calloutVisible);
  };

  const _onCalloutDismiss = () => {
    setCalloutVisible(false);
  };

  const _onSignOutClicked = () => {
    (async () => {
      try {
        await SessionHandler.signout();
        dispatch({
          type: "sessionUser",
          sessionUser: null
        });
      } catch (e) {}
    })();
  };

  const _personaMenuButton = useRef(null);

  return (
    <>
      <img src={logo} alt="" style={{ height: "40px" }} />
      <div
        style={{ float: "right", cursor: "pointer" }}
        ref={_personaMenuButton}
      >
        <Persona
          imageInitials={Utilities.getUserInitials(state.sessionUser)}
          size={PersonaSize.size40}
          hidePersonaDetails={true}
          initialsColor={theme.palette.themePrimary}
          styles={{
            root: { flexDirection: "row-reverse" },
            details: { textAlign: "right" }
          }}
          onClick={_onPersonaClicked}
        />
        {calloutVisible ? (
          <Callout
            className={styles.callout}
            target={_personaMenuButton.current}
            isBeakVisible={false}
            onDismiss={_onCalloutDismiss}
            directionalHint={DirectionalHint.bottomAutoEdge}
            setInitialFocus={true}
          >
            <div className={styles.header}>
              <p className={styles.title} id={_labelId}>
                {state.sessionUser.name}
              </p>
            </div>
            <div className={styles.inner}>
              <p className={styles.subtext} id={_descriptionId}>
                {state.sessionUser.email}
              </p>
              <div className={styles.actions}>
                <DefaultButton onClick={_onSignOutClicked} text="Sign out" />
              </div>
            </div>
          </Callout>
        ) : null}
      </div>
    </>
  );
}

export default Header;
