export default {
  body: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  header: {
    paddingLeft: "2px"
  },
  breadcrumb: {
    position: "absolute",
    top: -60,
    left: -8,
    margin: 0,
    paddingRight: 50
  },
  breadcrumbItem: { fontSize: 10 },
  breadcrumbDivider: { fontSize: 10 },
  commandbar: { paddingLeft: "0px", marginTop: "8px" },
  buttonbar: { margin: "15px 0 0 0" },
  progress: { position: "absolute", top: "-20px", width: "100%" },
  pivot: {
    marginTop: "8px",
    padding: "16px 10px",
    borderTop: "1px solid #eee"
  },
  form: { margin: "10px", maxWidth: "500px" },
  formSectioned: {
    margin: "10px 0",
    maxWidth: "1000px"
  },
  formSection: {
    boxSizing: "border-box",
    float: "left",
    width: "50%",
    padding: "0 10px",
    selectors: {
      "@media(max-width: 1000px)": {
        width: "100%"
      }
    }
  },
  field: {
    margin: "5px 0"
    // maxWidth: "400px",
  },
  checkbox: { margin: "15px 0" },
  separator: { margin: "10px 0 0 0" },
  pivotControl: {},
  pivotItemInvalidCount: { color: "red" },
  pagination: { flexDirection: "row" },
  paginationPageNumber: { verticalAlign: "top" }
};
