import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { CategoryHandler, Utilities } from "../../api";
import { useStateContext } from "../../state";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
  PrimaryButton,
  ProgressIndicator,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

const _getItemsSortedByOrder = (items) => {
  return (items || []).sort((a, b) => {
    return a.order - b.order;
  });
};

const _getItemsSortedByName = (items, locale_id) => {
  return (items || []).sort((a, b) => {
    let aResource = Utilities.getResource(a, locale_id);
    let bResource = Utilities.getResource(b, locale_id);
    return Utilities.getField(aResource, "name").localeCompare(
      Utilities.getField(bResource, "name")
    );
  });
};

function CategoriesPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const [ordering, setOrdering] = useState({ visible: false, items: [] });

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let categories = await CategoryHandler.list();
          setItems(categories);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _getKey = (item) => {
    return item ? item.category_id : undefined;
  };
  const _onItemInvoked = (item) => {
    history.push("/categories/" + item.category_id);
  };

  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(_selection.getSelection());
    },
  });

  const _onClickDeleteButton = () => {
    setIsDeleting(true);

    (async () => {
      try {
        await CategoryHandler.delete(selectedItems);
        setItems(
          items.filter((item) => {
            return !selectedItems.includes(item);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteDialogVisible(false);
      setIsDeleting(false);
    })();
  };

  const _onClickCancelButton = () => {
    setIsDeleteDialogVisible(false);
    setIsDeleting(false);
  };

  const _onClickSaveOrderingButton = () => {
    setIsOrdering(true);

    (async () => {
      try {
        await CategoryHandler.order(_getItemsSortedByOrder(ordering.items));
        setItems(ordering.items);
        setOrdering({ visible: false, items: [] });
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsOrdering(false);
    })();
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "CATEGORIES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Categories
          </Text>
        </div>
        <CommandBar
          items={
            ordering.visible
              ? [
                  {
                    key: "orderItemsHelp",
                    text: "Drag the rows around to re-order and click Save.",
                    commandBarButtonAs: (props) => {
                      return (
                        <Label style={{ lineHeight: "34px" }}>
                          {props.text}
                        </Label>
                      );
                    },
                  },
                ]
              : [
                  {
                    key: "newItem",
                    text: "New category",
                    iconProps: { iconName: "Add" },
                    onClick: () => history.push("/categories/new"),
                  },
                  {
                    key: "editItem",
                    text: "Edit",
                    disabled: selectedItems.length !== 1,
                    iconProps: { iconName: "Edit" },
                    onClick: () =>
                      history.push(
                        "/categories/" + selectedItems[0].category_id
                      ),
                  },
                  {
                    key: "deleteItem",
                    text: "Delete",
                    disabled: selectedItems.length <= 0,
                    iconProps: { iconName: "Trash" },
                    onClick: () => {
                      setIsDeleteDialogVisible(true);
                    },
                  },
                ]
          }
          farItems={
            ordering.visible
              ? [
                  {
                    key: "saveOrderItems",
                    text: "Save",
                    iconProps: { iconName: "Save" },
                    onClick: _onClickSaveOrderingButton,
                  },
                  {
                    key: "cancelOrderItems",
                    text: "Cancel",
                    iconProps: { iconName: "Cancel" },
                    onClick: () => {
                      setOrdering({ visible: false, items: [] });
                    },
                  },
                ]
              : [
                  {
                    key: "ordertItems",
                    text: "Set order",
                    iconProps: { iconName: "SortLines" },
                    onClick: () => {
                      setOrdering({
                        visible: true,
                        items: _getItemsSortedByOrder(
                          // deep clone items
                          JSON.parse(JSON.stringify(items)) || []
                        ),
                      });
                    },
                  },
                ]
          }
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Category(s)",
            subText:
              "Are you sure you want to delete the selected category(s)?",
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {},
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteButton}
              text="Delete"
              disabled={isDeleting}
            />
            <DefaultButton onClick={_onClickCancelButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isDeleting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isOrdering}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Saving...",
            subText:
              "Please be patient while the category list order is being saved.",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <ProgressIndicator />
        </Dialog>
        {ordering.visible ? (
          <OrderableCategoryTable items={ordering.items} />
        ) : (
          <CategoryTable
            items={items}
            selectionMode={SelectionMode.multiple}
            getKey={_getKey}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            selection={_selection}
            onItemInvoked={_onItemInvoked}
            enableShimmer={isLoading}
          />
        )}
      </div>
    </>
  );
}

const OrderableCategoryTable = (props) => {
  const [state] = useStateContext();

  const [items, setItems] = useState(props.items);
  const [, setSelectedItems] = useState([]);

  const _selection = useConst(
    new Selection({
      getKey: (category) => {
        return category.category_id + "";
      },
      onSelectionChanged: () => {
        setSelectedItems(_selection.getSelection());
      },
    })
  );

  const _insertBeforeItem = (item) => {
    let dragIndex = items.indexOf(_draggedItem);
    let insertIndex = items.indexOf(item);

    const _items = [...items];
    _items.splice(dragIndex, 1);
    _items.splice(insertIndex, 0, _draggedItem);
    // set orders
    _items.forEach((_item, index) => {
      _item.order = index + 1;
    });
    setItems(_getItemsSortedByOrder(_items));
  };

  let _draggedItem = null;

  const _dragDropEvents = {
    canDrop: (dropContext, dragContext) => {
      return true;
    },
    canDrag: (item) => {
      return true;
    },
    onDragEnter: (item, event) => {
      // return string is the css classes that will be added to the entering element.
      return "details-list-drag-enter";
    },
    onDragLeave: (item, event) => {
      return;
    },
    onDrop: (item, event) => {
      if (_draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (item, itemIndex, selectedItems, event) => {
      _draggedItem = item;
    },
    onDragEnd: (item, event) => {
      _draggedItem = null;
    },
  };

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 250,
      onRender: (item) => {
        let resource = (item.resources || []).find((resource) => {
          return resource.locale_id === state.locale_id;
        });
        return resource ? resource.name : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Order",
      key: "order",
      fieldName: "order",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
      isSorted: true,
    },
  ];

  return (
    <ShimmeredDetailsList
      setKey="orderable-categories"
      columns={_columns}
      items={items || []}
      selection={_selection}
      selectionPreservedOnEmptyClick={true}
      selectionMode={SelectionMode.single}
      layoutMode={DetailsListLayoutMode.justified}
      dragDropEvents={_dragDropEvents}
      isHeaderVisible={true}
    />
  );
};

export const CategoryTable = (props) => {
  const [state] = useStateContext();

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 250,
      isSorted: true,
      onRender: (item) => {
        let resource = (item.resources || []).find((resource) => {
          return resource.locale_id === state.locale_id;
        });
        return resource ? resource.name : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Multi-Orientation",
      key: "orientation",
      fieldName: "multi_orientation",
      data: "boolean",
      minWidth: 70,
      maxWidth: 90,
      onRender: (item) => {
        return item.multi_orientation ? "Yes" : "No";
      },
    },
    {
      ...columnAttrCommon,
      name: "Allow Non-Threaded",
      key: "threaded",
      fieldName: "allow_non_threaded",
      data: "boolean",
      minWidth: 70,
      maxWidth: 90,
      onRender: (item) => {
        return item.allow_non_threaded ? "Yes" : "No";
      },
    },
    {
      ...columnAttrCommon,
      name: "Order",
      key: "order",
      fieldName: "order",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
    },
  ].concat(
    !props.hideRelated
      ? [
          {
            ...columnAttrCommon,
            name: "# Input Questions",
            key: "input_questions",
            fieldName: "input_question_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90,
          },
          {
            ...columnAttrCommon,
            name: "# Products",
            key: "products",
            fieldName: "product_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90,
          },
          {
            ...columnAttrCommon,
            name: "# Models",
            key: "models",
            fieldName: "model_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90,
          },
          {
            ...columnAttrCommon,
            name: "# Dealers",
            key: "dealers",
            fieldName: "dealer_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90,
          },
        ]
      : []
  );

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={_getItemsSortedByName(props.items || [], state.locale_id)}
    />
  );
};

export default CategoriesPage;
