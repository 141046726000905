import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { ApiHandler } from "./ApiHandler";

SuperTokensRequest.makeSuper(axios);

class LogHandler extends ApiHandler {
  constructor() {
    super("logs");
  }

  async list(type, options = {}) {
    let response = await axios.get(
      `${this.uri}/${type}?start=${options.start}&limit=${options.limit}`
    );
    return response.data;
  }
}

export default new LogHandler();
