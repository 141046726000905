import React, { useEffect, useMemo, useState } from "react";

import {
  DefaultButton,
  DetailsListLayoutMode,
  DetailsRow,
  Dialog,
  DialogFooter,
  DialogType,
  Panel,
  PanelType,
  PrimaryButton,
  Selection,
  SelectionMode
} from "office-ui-fabric-react";

function SelectPanel(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const buttonStyles = { root: { marginRight: 8 } };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let items = await props.handler.list();
          setItems(items);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const _onOpen = () => {
    setIsLoading(true);
  };

  const _onClickCloseErrorDialog = () => {
    setError(null);
    setIsLoading(false);
    props.dismissPanel();
  };

  const _onReturnSelectedItems = () => {
    props.dismissPanel(selectedItems);
  };

  const _onRenderFooterContent = () => (
    <div>
      <PrimaryButton
        disabled={selectedItems.length === 0}
        onClick={_onReturnSelectedItems}
        styles={buttonStyles}
      >
        Select
      </PrimaryButton>
      <DefaultButton onClick={props.dismissPanel}>Cancel</DefaultButton>
    </div>
  );

  const _selection = useMemo(() => {
    setSelectedItems([]);
    return new Selection({
      onSelectionChanged: () => {
        setSelectedItems(_selection.getSelection());
      },
      canSelectItem: item => {
        const found = (props.current || []).find(current => {
          return current.code === item.code;
        });
        return found === undefined;
      }
    });
    // even though props.current is mutable it is assumed that the value is "managed" by its parent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, props.current]);

  const _onRenderRow = props => {
    const selectable = props.selection.canSelectItem(props.item);
    return (
      <div className={!selectable ? "details-list-disabled" : ""}>
        <DetailsRow {...props} />
      </div>
    );
  };

  const _onItemInvoked = item => {
    if (_selection.canSelectItem(item)) {
      _onReturnSelectedItems();
    }
  };

  return (
    <Panel
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      headerText={props.title}
      onOpen={_onOpen}
      onRenderFooterContent={_onRenderFooterContent}
      // Stretch panel content to fill the available height so the footer is positioned
      // at the bottom of the page
      isFooterAtBottom={true}
      type={PanelType.medium}
    >
      <Dialog
        hidden={!error}
        dialogContentProps={{
          type: DialogType.close,
          title: "Error",
          subText: error || ""
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 450 } }
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
        </DialogFooter>
      </Dialog>

      <props.table
        items={items}
        setKey="items"
        layoutMode={DetailsListLayoutMode.justified}
        initialFocusedIndex={0}
        selection={_selection}
        selectionPreservedOnEmptyClick={true}
        selectionMode={SelectionMode.multiple}
        onItemInvoked={_onItemInvoked}
        onRenderRow={_onRenderRow}
        enableShimmer={isLoading}
        hideRelated={true}
      />
    </Panel>
  );
}

export default SelectPanel;
