import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import validator from "validator";
import { ControlledEditor } from "@monaco-editor/react";

import { CountryHandler, LanguageHandler, LocaleHandler } from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  Breadcrumb,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Shimmer,
  ShimmerElementType,
  Text,
} from "office-ui-fabric-react";

function LocalePage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);

  const { from } = location.state || {
    from: { pathname: "/locales" },
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form, _languages, _countries;
          // if locale location, retrieve locale, else set defaults
          if (location.pathname.endsWith("new")) {
            const values = await Promise.all([
              LanguageHandler.sorted(state.locale_id),
              CountryHandler.sorted(state.locale_id),
            ]);
            _languages = values[0];
            _countries = values[1];

            _form = {};
          } else {
            const values = await Promise.all([
              LanguageHandler.sorted(state.locale_id),
              CountryHandler.sorted(state.locale_id),
              LocaleHandler.get(params.id),
            ]);

            _languages = values[0];
            _countries = values[1];

            const _locale = values[2];
            _locale.language = _locale.language ? _locale.language.code : "";
            _locale.country = _locale.country ? _locale.country.code : "";
            _form = _locale;
          }

          if (_form.resources) {
            // pretty print JSON
            _form.resources = JSON.stringify(
              JSON.parse(_form.resources),
              null,
              "\t"
            );
          }

          setLanguages(_languages);
          setCountries(_countries);
          setForm(_form);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);
  const shimmers = mergeStyles({
    marginTop: "20px",
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });

  const title = location.pathname.endsWith("new")
    ? "New Locale"
    : `Locale: ${form.language || ""}_${(form.country || "").toUpperCase()}`;

  const [isValidated, setValidated] = useState(false);
  const isLanguageValid = !validator.isEmpty((form.language || "").trim());
  const isCountryValid = !validator.isEmpty((form.country || "").trim());
  let isResourcesValid = false;
  try {
    JSON.parse(form.resources || "{}");
    isResourcesValid = true;
  } catch (e) {
    isResourcesValid = false;
  }

  // general
  const _languagesDropdownField = React.createRef();
  const _countriesDropdownField = React.createRef();

  // resources
  const _onChangeDropdown = (attr, event, item) => {
    setForm({
      ...form,
      [attr]: item.key,
    });
  };

  const _onClickSave = () => {
    if (isLanguageValid && isCountryValid && isResourcesValid) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [isLanguageValid, isCountryValid],
    resources: [isResourcesValid],
  };
  const _getInvalidPivotItemFormCount = (itemKey) => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          // dropdowns
          data.language = data.language
            ? languages.find((language) => {
                return language.code === data.language;
              })
            : null;

          data.country = data.country
            ? countries.find((country) => {
                return country.code === data.country;
              })
            : null;

          if (data.language) {
            data.language = Object.assign({}, data.language);
            delete data.language.resources;
          }

          if (data.country) {
            data.country = Object.assign({}, data.country);
            delete data.country.resources;
          }

          if (data.locale_id) {
            await LocaleHandler.update(data);
          } else {
            await LocaleHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "CODE TABLES" }, { text: "LOCALES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText: "Please be patient while the locale is being saved.",
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } },
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount,
                }}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Information
                      </Separator>
                      <Dropdown
                        componentRef={_languagesDropdownField}
                        label="Language"
                        placeholder="Select a language"
                        styles={{ root: styles.field }}
                        selectedKey={form.language || ""}
                        onChange={_onChangeDropdown.bind(this, "language")}
                        options={languages.map((_language) => {
                          let resource = (_language.resources || []).find(
                            (resource) => {
                              return resource.locale_id === state.locale_id;
                            }
                          );

                          return {
                            key: _language.code,
                            text: resource ? resource.name : "",
                          };
                        })}
                        required={true}
                        errorMessage={
                          isValidated && !isLanguageValid
                            ? "A language must be selected."
                            : ""
                        }
                      ></Dropdown>
                      <Dropdown
                        componentRef={_countriesDropdownField}
                        label="Country"
                        placeholder="Select a country"
                        styles={{ root: styles.field }}
                        selectedKey={form.country || ""}
                        onChange={_onChangeDropdown.bind(this, "country")}
                        options={countries.map((_country) => {
                          let resource = (_country.resources || []).find(
                            (resource) => {
                              return resource.locale_id === state.locale_id;
                            }
                          );

                          return {
                            key: _country.code,
                            text: resource ? resource.name : "",
                          };
                        })}
                        required={true}
                        errorMessage={
                          isValidated && !isCountryValid
                            ? "A country must be selected."
                            : ""
                        }
                      ></Dropdown>
                    </div>
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey="resources"
                  headerText="Resources"
                  itemCount={_getInvalidPivotItemFormCount("resources")}
                >
                  <div style={{ paddingTop: 10 }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "solid 1px rgb(238,238,238)",
                      }}
                    >
                      <ControlledEditor
                        height="90vh"
                        language="json"
                        value={form.resources}
                        onChange={(evt, value) => {
                          setForm({
                            ...form,
                            resources: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </PivotItem>
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LocalePage;
