import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { ApiHandler } from "./ApiHandler";

SuperTokensRequest.makeSuper(axios);

class RolesHandler extends ApiHandler {
  constructor() {
    super("roles");
  }
  async list() {
    let response = await axios.get(`${this.uri}`);
    return response.data;
  }
}

export default new RolesHandler();
