import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

SuperTokensRequest.makeSuper(axios);

export class ApiHandler {
  constructor(path) {
    this.uri =
      `${process.env.REACT_APP_API_URL}/api/admin` +
      (path ? `/${path}` : "");
  }
}
