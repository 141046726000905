import React, { useEffect, useState } from "react";
import validator from "validator";
import { useStateContext } from "../state";
import { Utilities } from "../api";

import { ActionButton, TextField } from "office-ui-fabric-react";

function TextFieldLocale(props) {
  const {
    styles = {},
    form,
    setForm,
    field,
    onLocaleChanged = () => {},
  } = props;

  const [state] = useStateContext();
  const [locale, setLocale] = useState(state.locale);

  const resource = (form.resources || []).find((resource) => {
    return resource.locale_id === locale.locale_id;
  });

  const _onChangeTextField = (event, text) => {
    setForm((form) => {
      const resources = [...form.resources];
      const resource = resources.find(
        (resource) => resource.locale_id === locale.locale_id
      );
      resource[field] = text;

      return {
        ...form,
        resources,
      };
    });
  };

  useEffect(() => {
    onLocaleChanged(locale);
  }, [locale, onLocaleChanged]);

  return (
    <TextField
      {...props}
      styles={Object.assign(styles, {
        suffix: {
          verticalAlign: "top",
          display: "",
        },
      })}
      onChange={_onChangeTextField}
      value={resource ? resource[field] || "" : ""}
      suffix={`${locale.language.code}_${locale.country.code.toUpperCase()}`}
      onRenderSuffix={(props) => {
        return (
          <ActionButton
            styles={{
              root: {
                maxHeight: "100%",
              },
            }}
            menuProps={{
              onItemClick: (evt, item) => {
                const locale = state.locales.find(
                  (locale) => locale.locale_id === item.key
                );
                if (locale) {
                  setLocale(locale);
                }
              },
              items: state.locales.map(function (_locale) {
                return {
                  key: _locale.locale_id,
                  text: `${
                    _locale.language.code
                  }_${_locale.country.code.toUpperCase()}`,
                  canCheck: true,
                  checked: _locale.locale_id === locale.locale_id,
                  secondaryText: !validator.isEmpty(
                    Utilities.getResourceFieldByLocale(
                      form,
                      field,
                      _locale.locale_id
                    ) || ""
                  )
                    ? "🟢"
                    : "🔴",
                };
              }),
            }}
          >
            {props.suffix}
          </ActionButton>
        );
      }}
    ></TextField>
  );
}

export default TextFieldLocale;
