import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import filesize from "filesize";
import validator from "validator";

import { ProductSeriesHandler, ProductTypeHandler, Utilities } from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  getId,
  Breadcrumb,
  Checkbox,
  CommandBar,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Image,
  ImageFit,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Selection,
  Shimmer,
  ShimmerElementType,
  Text,
  TextField,
  VerticalDivider,
} from "office-ui-fabric-react";

import { MarkdownPreview } from "../preview/MarkdownPreview";
import { TagsDialog } from "../dialogs/TagsDialog";

import TextFieldLocale from "../TextFieldLocale";

function cacheImage(image) {
  const img = new window.Image();
  img.src = `data:${image.mime_type};base64,${image.content}`;
}

function ProductSeriesPage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [productTypes, setProductTypes] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const { from } = location.state || {
    from: { pathname: "/product-series" },
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form, _producTypes;
          // if user location, retrieve product series, else set defaults
          if (location.pathname.endsWith("new")) {
            _producTypes = await ProductTypeHandler.list();
            _form = {};
          } else {
            const values = await Promise.all([
              ProductTypeHandler.list(),
              ProductSeriesHandler.get(params.id, { tags: "*" }),
            ]);
            _producTypes = values[0];

            const _productSeries = values[1];
            _productSeries.product_type = _productSeries.product_type
              ? _productSeries.product_type.code
              : "";
            _form = _productSeries;
          }

          Utilities.initResources(_form, state.locales, {
            name: "",
            description: "",
            details: "",
            product_series_id: _form.product_series_id,
          });

          _form.images = _getImagesSorted(_form.images || []);
          _form.images.forEach((image) => {
            cacheImage(image);
          });

          setTimeout(() => {
            setProductTypes(_producTypes);
            setForm(_form);
            setIsLoading(false);
          });
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);
  const shimmers = mergeStyles({
    marginTop: "20px",
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });

  const title = location.pathname.endsWith("new")
    ? "New Product Series"
    : `Product Series: ${Utilities.getResourceFieldByLocale(
        form,
        "name",
        state.locale_id
      )}`;

  const [isValidated, setValidated] = useState(false);
  const isCodeValid = !validator.isEmpty((form.code || "").trim());
  const isNameValid = (form.resources || []).every(
    (resource) => !validator.isEmpty((resource.name || "").trim())
  );
  const isProductTypeValid = !validator.isEmpty(
    (form.product_type || "").trim()
  );

  // general
  const _codeTextField = React.createRef();
  const _productTypeDropdownField = React.createRef();
  const [preview, setPreview] = useState("");

  // images
  const _imageUploadField = React.createRef();
  const [dialogTags, setDialogTags] = useState(false);

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text,
    });
  };

  const _onChangeDropdown = (attr, event, item) => {
    setForm({
      ...form,
      [attr]: item.key,
    });
  };

  const _onChangeCheckbox = (attr, event, checked) => {
    setForm({
      ...form,
      [attr]: checked,
    });
  };

  const _onClickSave = () => {
    if (isCodeValid && isNameValid && isProductTypeValid) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [isCodeValid, isNameValid, isProductTypeValid],
  };
  const _getInvalidPivotItemFormCount = (itemKey) => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          data.product_type = data.product_type
            ? productTypes.find((productType) => {
                return productType.code === data.product_type;
              })
            : null;

          data.images.forEach((image) => {
            if (Number.isFinite(image.product_series_image_id)) {
              // only update image order
              delete image.content;
              delete image.mime_type;
              delete image.filename;
              delete image.filesize;
            } else {
              delete image.product_series_image_id;
            }
          });

          if (form.product_series_id) {
            await ProductSeriesHandler.update(data);
          } else {
            await ProductSeriesHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const _getKey = (image) => {
    return image.product_series_image_id + "";
  };
  const _onColumnClick = () => {};

  const _selection = useRef(
    new Selection({
      getKey: _getKey,
      onSelectionChanged: () => {
        setSelectedImages(_selection.current.getSelection());
      },
    })
  );

  const _insertBeforeItem = (item) => {
    let dragIndex = form.images.indexOf(_draggedItem);
    let insertIndex = form.images.indexOf(item);

    const items = [...form.images];
    items.splice(dragIndex, 1);
    items.splice(insertIndex, 0, _draggedItem);
    // set orders
    items.forEach((item, index) => {
      item.order = index + 1;
    });
    setForm({
      ...form,
      images: _getImagesSorted(items),
    });
  };
  let _draggedItem = null;

  const _dragDropEvents = {
    canDrop: (dropContext, dragContext) => {
      return true;
    },
    canDrag: (item) => {
      return true;
    },
    onDragEnter: (item, event) => {
      // return string is the css classes that will be added to the entering element.
      return "details-list-drag-enter";
    },
    onDragLeave: (item, event) => {
      return;
    },
    onDrop: (item, event) => {
      if (_draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (item, itemIndex, selectedItems, event) => {
      _draggedItem = item;
    },
    onDragEnd: (item, event) => {
      _draggedItem = null;
    },
  };

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: _onColumnClick,
  };

  const columns = [
    {
      isRowHeader: true,
      key: "thumbnail",
      iconName: "Picture",
      isIconOnly: true,
      minWidth: 150,
      maxWidth: 150,
      onRender: (item) => {
        return (
          <Image
            src={`data:${item.mime_type};base64,${item.content}`}
            width={150}
            height={150}
            imageFit={ImageFit.centerContain}
          />
        );
      },
    },
    {
      ...columnAttrCommon,
      name: "Filename",
      key: "filename",
      fieldName: "filename",
      data: "string",
      minWidth: 100,
      maxWidth: 550,
    },
    {
      ...columnAttrCommon,
      name: "Tags",
      key: "tags",
      fieldName: "tags",
      data: "string",
      minWidth: 100,
      maxWidth: 300,
      onRender: (item) => {
        return (item.tags || []).join(", ");
      },
    },
    {
      ...columnAttrCommon,
      name: "Dimensions",
      key: "dimensions",
      fieldName: "dimensions",
      data: "string",
      minWidth: 70,
      maxWidth: 120,
      onRender: (item) => {
        const img = new window.Image();
        img.src = `data:${item.mime_type};base64,${item.content}`;

        return img.width === 0 ? (
          <ProgressIndicator />
        ) : (
          `${img.width} x ${img.height}`
        );
      },
    },
    {
      ...columnAttrCommon,
      name: "Size",
      key: "filesize",
      fieldName: "filesize",
      data: "number",
      minWidth: 60,
      maxWidth: 90,
      onRender: (item) => {
        return filesize(item.filesize);
      },
    },
    {
      ...columnAttrCommon,
      name: "Mime Type",
      key: "mime",
      fieldName: "mime_type",
      data: "string",
      minWidth: 80,
      maxWidth: 200,
    },
    {
      ...columnAttrCommon,
      name: "Order",
      key: "order",
      fieldName: "order",
      data: "number",
      minWidth: 50,
      maxWidth: 90,
      isSorted: true,
    },
  ];

  const _getImagesSorted = (images) => {
    return (images || []).sort((a, b) => {
      return a.order - b.order;
    });
  };
  const _onChangeUploadFile = () => {
    if (_imageUploadField.current) {
      const file = _imageUploadField.current.files[0];
      if (file) {
        const reader = new FileReader();

        reader.addEventListener(
          "load",
          function () {
            // convert image file to base64 string
            const image = {
              product_series_image_id: getId(),
              filename: file.name,
              filesize: file.size,
              content: reader.result.substring(
                reader.result.indexOf("base64,") + "base64,".length
              ),
              mime_type: file.type,
              order: form.images.length + 1,
            };

            cacheImage(image);

            setTimeout(() => {
              setForm({
                ...form,
                images: _getImagesSorted(form.images.concat([image])),
              });
            });
          },
          false
        );
        reader.readAsDataURL(file);
      }
    }
  };

  const _onClickDownloadImage = () => {
    if (selectedImages.length === 1) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:${selectedImages[0].mime_type};base64, ${selectedImages[0].content}`
      );
      element.setAttribute("download", selectedImages[0].filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  };

  const _onClickDeleteImage = () => {
    if (selectedImages.length > 0) {
      const images = [...form.images];
      selectedImages.forEach((selectedImage) => {
        let imageIndex = images.indexOf(selectedImage);
        images.splice(imageIndex, 1);
      });
      images.forEach((item, index) => {
        item.order = index + 1;
      });
      setForm({
        ...form,
        images: _getImagesSorted(images),
      });
    }
  };

  const _onClickTagImage = () => {
    if (selectedImages.length > 0) {
      setDialogTags(selectedImages[0]);
    }
  };

  const onDetailsLocaleChanged = useCallback(
    (locale) => {
      if (locale) {
        const details = Utilities.getResourceFieldByLocale(
          form,
          "details",
          locale.locale_id
        );
        setPreview(details);
      } else {
        setPreview("");
      }
    },
    [form, setPreview]
  );

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "PRODUCT SERIES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText: "Please be patient while the user is being saved.",
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } },
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount,
                }}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Information
                      </Separator>
                      <TextField
                        componentRef={_codeTextField}
                        label="Code"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "code")}
                        value={form.code}
                        required={true}
                        errorMessage={
                          isValidated && !isCodeValid
                            ? "Code must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextFieldLocale
                        field="name"
                        label="Name"
                        form={form}
                        setForm={setForm}
                        styles={{ root: styles.field }}
                        required={true}
                        errorMessage={
                          isValidated && !isNameValid
                            ? "Name must be at least one character in length for each locale."
                            : ""
                        }
                      />
                      <TextFieldLocale
                        field="description"
                        label="Description"
                        form={form}
                        setForm={setForm}
                        styles={{ root: styles.field }}
                        multiline
                        rows={5}
                      />
                    </div>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Classification
                      </Separator>
                      <Dropdown
                        componentRef={_productTypeDropdownField}
                        label="Type"
                        placeholder="Select a type"
                        styles={{ root: styles.field }}
                        selectedKey={form.product_type || ""}
                        onChange={_onChangeDropdown.bind(this, "product_type")}
                        options={productTypes.map((productType) => {
                          const resource = Utilities.getResource(
                            productType,
                            state.locale_id
                          );
                          return {
                            key: productType.code,
                            text: Utilities.getField(resource, "name"),
                          };
                        })}
                        required={true}
                        errorMessage={
                          isValidated && !isProductTypeValid
                            ? "A product type must be selected."
                            : ""
                        }
                      ></Dropdown>
                      <Checkbox
                        label="Is a system defined product series."
                        styles={{ root: { margin: "33px 0 15px 0" } }}
                        checked={form.is_system}
                        onChange={_onChangeCheckbox.bind(this, "is_system")}
                      />
                      <Text>
                        <b>Note:</b> If the product series is set as system
                        defined, it will not be displayed within the mobile
                        application.
                      </Text>
                    </div>
                  </div>
                </PivotItem>
                <PivotItem itemKey="details" headerText="Details">
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                    }}
                  >
                    <div style={{ padding: "0 10px" }}>
                      <div>
                        <div
                          style={{
                            backgroundColor: "rgba(0,0,0,0.1)",
                            padding: "8px",
                            margin: "12px 0",
                            fontWeight: "600",
                          }}
                        >
                          Markdown Editor
                        </div>
                        <Text>
                          Use the default template as a guide for creating
                          sections, headers and images.
                        </Text>
                        <TextFieldLocale
                          placeholder="Enter section details here"
                          field="details"
                          form={form}
                          setForm={setForm}
                          styles={{ root: styles.field }}
                          multiline
                          rows={20}
                          onLocaleChanged={onDetailsLocaleChanged}
                        />
                      </div>
                    </div>
                    <div style={{ width: "360px", overflowX: "hidden" }}>
                      <div
                        style={{
                          backgroundColor: "rgba(0,0,0,0.1)",
                          padding: "8px",
                          margin: "12px 0",
                          fontWeight: "600",
                        }}
                      >
                        Preview
                      </div>
                      <div style={{ padding: "0 20px 20px 20px" }}>
                        <MarkdownPreview
                          markdown={preview}
                          images={form.images}
                        />
                      </div>
                    </div>
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey="images"
                  headerText="Images"
                  itemCount={_getInvalidPivotItemFormCount("images")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "upload",
                        text: "Upload",
                        iconProps: { iconName: "Upload" },
                        onClick: () => {
                          if (_imageUploadField.current) {
                            _imageUploadField.current.click();
                          }
                        },
                      },
                      {
                        key: "upload-image",
                        onRender: () => (
                          <input
                            ref={_imageUploadField}
                            style={{ display: "none" }}
                            type="file"
                            accept="image/jpg,image/jpeg,image/png,image/svg+xml"
                            onChange={_onChangeUploadFile}
                          />
                        ),
                      },
                      {
                        key: "download",
                        text: "Download",
                        iconProps: { iconName: "Download" },
                        disabled: selectedImages.length !== 1,
                        onClick: _onClickDownloadImage,
                      },
                      {
                        key: "divider",
                        onRender: () => {
                          return (
                            <div
                              style={{
                                margin: "8px 4px",
                              }}
                            >
                              <VerticalDivider />
                            </div>
                          );
                        },
                      },
                      {
                        key: "delete",
                        iconProps: { iconName: "Delete" },
                        disabled: selectedImages.length === 0,
                        onClick: _onClickDeleteImage,
                      },
                    ]}
                    farItems={[
                      {
                        key: "tags",
                        text: "Set tags ...",
                        iconProps: { iconName: "Tag" },
                        disabled: selectedImages.length !== 1,
                        onClick: _onClickTagImage,
                      },
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  {dialogTags ? (
                    <TagsDialog
                      image={dialogTags}
                      dismissDialog={(tags) => {
                        const selectedImage = dialogTags;
                        setDialogTags(null);

                        if (tags !== undefined) {
                          const images = [...form.images];
                          const currentImage = images.find((image) => {
                            return image === selectedImage;
                          });
                          if (currentImage) {
                            currentImage.tags = tags;
                            setForm({
                              ...form,
                              images: images,
                            });
                          }
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <DetailsList
                    setKey="items"
                    items={form.images || []}
                    columns={columns}
                    selection={_selection.current}
                    selectionPreservedOnEmptyClick={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    // isHeaderVisible={true}
                    // onItemInvoked={_onItemInvoked}
                    dragDropEvents={_dragDropEvents}
                  />
                </PivotItem>
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ProductSeriesPage;
