import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import validator from "validator";

import {
  AdapterHandler,
  CategoryHandler,
  MakeHandler,
  ModelHandler,
  OrientationHandler,
  ProductHandler,
  ThreadSizeHandler,
  ThreadStandardHandler,
  Utilities,
} from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  getId,
  getTheme,
  Breadcrumb,
  Checkbox,
  ComboBox,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  SelectableOptionMenuItemType,
  Selection,
  SelectionMode,
  Separator,
  Shimmer,
  ShimmerElementType,
  ShimmeredDetailsList,
  Stack,
  Text,
  TextField,
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

import { SelectPanel } from "../";
import { AdapterTable } from "./AdaptersPage";
import { CategoryTable } from "./CategoriesPage";

import TextFieldLocale from "../TextFieldLocale";

const theme = getTheme();

const styles = mergeStyleSets(routeStyleSets);
const shimmers = mergeStyles({
  marginTop: "20px",
  selectors: {
    "& > .ms-Shimmer-container": {
      margin: "10px 0",
    },
  },
});

function ModelPage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [makes, setMakes] = useState([]);
  const [orientations, setOrientations] = useState([]);
  const [threadStandards, setThreadStandards] = useState([]);
  const [threadSizes, setThreadSizes] = useState([]);
  const [products, setProducts] = useState([]);

  const [isSelectCategories, setIsSelectCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSelectAdapters, setIsSelectAdapters] = useState(false);
  const [selectedAdapters, setSelectedAdapters] = useState([]);
  const [dialogRecommendation, setDialogRecommendation] = useState(false);
  const [selectedRecommendations, setSelectedRecommendations] = useState([]);

  const { from } = location.state || {
    from: { pathname: "/models" },
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form,
            _makes,
            _orientations,
            _threadStandards,
            _threadSizes,
            _products;
          // if model location, retrieve model, else set defaults
          if (location.pathname.endsWith("new")) {
            const values = await Promise.all([
              MakeHandler.sorted(),
              OrientationHandler.list(),
              ThreadStandardHandler.list(),
              ThreadSizeHandler.sorted(),
              ProductHandler.list(),
            ]);
            _makes = values[0];
            _orientations = values[1];
            _threadStandards = values[2];
            _threadSizes = values[3];
            _products = values[4];

            _form = {};
          } else {
            const values = await Promise.all([
              MakeHandler.sorted(),
              OrientationHandler.list(),
              ThreadStandardHandler.list(),
              ThreadSizeHandler.sorted(),
              ProductHandler.list(),
              ModelHandler.get(params.id),
            ]);
            _makes = values[0];
            _orientations = values[1];
            _threadStandards = values[2];
            _threadSizes = values[3];
            _products = values[4];

            const _model = values[5];
            _model.make = _model.make ? _model.make.make_id : "";
            _model.default_orientation = _model.default_orientation
              ? _model.default_orientation.orientation_id
              : "";
            _model.thread_sizes = (_model.thread_sizes || []).map(
              (threadSize) => {
                return threadSize.thread_size_id;
              }
            );
            _form = _model;
          }

          Utilities.initResources(_form, state.locales, {
            comments: "",
            model_id: _form.model_id,
          });

          setMakes(_makes);
          setOrientations(_orientations);
          setThreadStandards(_threadStandards);
          setThreadSizes(_threadSizes);
          setProducts(
            _products.sort((a, b) => {
              return a.name.localeCompare(b.name);
            })
          );
          setForm(_form);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const title = location.pathname.endsWith("new")
    ? "New Model"
    : `Model: ${form.name || ""}`;

  const [isValidated, setValidated] = useState(false);
  const isCodeValid = !validator.isEmpty((form.code || "").trim());
  const isNameValid = !validator.isEmpty((form.name || "").trim());
  const isMakeValid = !!form.make;
  const isDimensionsValid = true;
  const isDefaultOrientationValid = !!form.default_orientation;

  // general
  const _codeTextField = React.createRef();
  const _nameTextField = React.createRef();
  const _makeDropdownField = React.createRef();
  const _threadSizesComboboxField = React.createRef();
  const _widthTextField = React.createRef();
  const _heightTextField = React.createRef();
  const _depthTextField = React.createRef();
  const _weightTextField = React.createRef();
  const _defaultOrientationDropdownField = React.createRef();

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text,
    });
  };

  const _onChangeCheckbox = (attr, event, checked) => {
    setForm({
      ...form,
      [attr]: checked,
    });
  };

  const _onChangeComboBox = (attr, event, item) => {
    const selected = [...(form[attr] || [])];
    if (item.selected) {
      selected.push(item.key);
    } else {
      const index = selected.indexOf(item.key);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setForm({
      ...form,
      [attr]: selected,
    });
  };

  const _onChangeDropdown = (attr, event, item) => {
    setForm({
      ...form,
      [attr]: item.key,
    });
  };

  const _onClickSave = () => {
    if (isCodeValid && isNameValid) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [isCodeValid, isNameValid],
  };
  const _getInvalidPivotItemFormCount = (itemKey) => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          data.width = parseFloat(data.width, 10);
          data.height = parseFloat(data.height, 10);
          data.depth = parseFloat(data.depth, 10);
          data.weight = parseFloat(data.weight, 10);

          // dropdown
          data.make = data.make
            ? {
                make_id: data.make,
              }
            : null;

          data.default_orientation = data.default_orientation
            ? {
                orientation_id: data.default_orientation,
              }
            : null;

          // combobox
          data.thread_sizes = (data.thread_sizes || []).map(
            (thread_size_id) => {
              return { thread_size_id: thread_size_id };
            }
          );

          // lists
          data.categories = (data.categories || []).map((category) => {
            return { category_id: category.category_id };
          });
          data.adapters = (data.adapters || []).map((adapter) => {
            return { adapter_id: adapter.adapter_id };
          });
          data.recommendations = (data.recommendations || []).map(
            (recommendation) => {
              return {
                recommendation_id: Number.isFinite(
                  recommendation.recommendation_id
                )
                  ? recommendation.recommendation_id
                  : undefined,
                product_id: recommendation.product
                  ? recommendation.product.product_id
                  : null,
                orientation_id: recommendation.orientation
                  ? recommendation.orientation.orientation_id
                  : null,
                quantity: parseInt(recommendation.quantity, 10),
              };
            }
          );

          if (data.model_id) {
            await ModelHandler.update(data);
          } else {
            await ModelHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const _selectionCategories = useConst(
    new Selection({
      getKey: (category) => {
        return category.category_id + "";
      },
      onSelectionChanged: () => {
        setSelectedCategories(_selectionCategories.getSelection());
      },
    })
  );

  const _selectionAdapters = useConst(
    new Selection({
      getKey: (adapter) => {
        return adapter.adapter_id + "";
      },
      onSelectionChanged: () => {
        setSelectedAdapters(_selectionAdapters.getSelection());
      },
    })
  );

  const _selectionRecommendations = useConst(
    new Selection({
      getKey: (recommendation) => {
        return recommendation.recommendation_id + "";
      },
      onSelectionChanged: () => {
        setSelectedRecommendations(_selectionRecommendations.getSelection());
      },
    })
  );

  return (
    <>
      <Breadcrumb
        items={[{ text: "MANUFACTURERS" }, { text: "MODELS" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText: "Please be patient while the model is being saved.",
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } },
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount,
                }}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Information
                      </Separator>
                      <TextField
                        componentRef={_codeTextField}
                        label="Code"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "code")}
                        value={form.code}
                        required={true}
                        errorMessage={
                          isValidated && !isCodeValid
                            ? "Code must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextField
                        componentRef={_nameTextField}
                        label="Name"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "name")}
                        value={form.name}
                        required={true}
                        errorMessage={
                          isValidated && !isNameValid
                            ? "Name must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextFieldLocale
                        field="comments"
                        label="Comments"
                        form={form}
                        setForm={setForm}
                        styles={{ root: styles.field }}
                        multiline
                        rows={15}
                      />
                    </div>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Details
                      </Separator>
                      <Dropdown
                        componentRef={_makeDropdownField}
                        label="Make"
                        placeholder="Select a make"
                        styles={{ root: styles.field }}
                        selectedKey={form.make || ""}
                        onChange={_onChangeDropdown.bind(this, "make")}
                        options={makes.map((make) => {
                          return {
                            key: make.make_id,
                            text: make.name,
                          };
                        })}
                        required={true}
                        errorMessage={
                          isValidated && !isMakeValid
                            ? "A make must be selected."
                            : ""
                        }
                      ></Dropdown>
                      <ComboBox
                        componentRef={_threadSizesComboboxField}
                        multiSelect
                        selectedKey={form.thread_sizes || []}
                        label="Thread Sizes"
                        allowFreeform={false}
                        autoComplete="off"
                        styles={{ container: styles.field }}
                        onChange={_onChangeComboBox.bind(this, "thread_sizes")}
                        options={(threadSizes || [])
                          .reduce((list, threadSize, index) => {
                            if (
                              index === 0 ||
                              (threadSize.thread_standard &&
                                threadSizes[index - 1].thread_standard &&
                                threadSize.thread_standard
                                  .thread_standard_id !==
                                  threadSizes[index - 1].thread_standard
                                    .thread_standard_id)
                            ) {
                              const threadStandard = threadSize.thread_standard
                                ? threadStandards.find(function (
                                    threadStandard
                                  ) {
                                    return (
                                      threadStandard.thread_standard_id ===
                                      threadSize.thread_standard
                                        .thread_standard_id
                                    );
                                  })
                                : null;
                              const resource = threadStandard
                                ? Utilities.getResource(
                                    threadStandard,
                                    state.locale_id
                                  )
                                : null;
                              list.push(Utilities.getField(resource, "name"));
                            }
                            list.push(threadSize);
                            return list;
                          }, [])
                          .map((threadSize) => {
                            if (typeof threadSize === "string") {
                              return {
                                key: threadSize,
                                text: threadSize,
                                itemType: SelectableOptionMenuItemType.Header,
                              };
                            } else {
                              const resource = Utilities.getResource(
                                threadSize,
                                state.locale_id
                              );
                              const extra = Utilities.getField(
                                resource,
                                "extra"
                              );
                              return {
                                key: threadSize.thread_size_id,
                                text: [threadSize.description, extra]
                                  .join(" ")
                                  .trim(),
                              };
                            }
                          })}
                      />
                      <div>
                        <Stack
                          horizontal
                          tokens={{ childrenGap: 5 }}
                          styles={{ root: { maxWidth: 300 } }}
                          verticalAlign="top"
                        >
                          <Stack.Item grow>
                            <TextField
                              componentRef={_widthTextField}
                              label="Dimensions"
                              placeholder="Width"
                              onChange={_onChangeTextField.bind(this, "width")}
                              value={form.width}
                              // errorMessage={validated && !isWidthValid ? "\u00A0" : ""}
                              styles={{
                                errorMessage: { position: "absolute" },
                              }}
                            />
                          </Stack.Item>
                          <Stack.Item
                            styles={{
                              root: { width: "10px", paddingTop: "34px" },
                            }}
                          >
                            <Text>x</Text>
                          </Stack.Item>
                          <Stack.Item grow>
                            <TextField
                              componentRef={_heightTextField}
                              label="&nbsp;"
                              placeholder="Height"
                              onChange={_onChangeTextField.bind(this, "height")}
                              value={form.height}
                              // errorMessage={validated && !isHeightValid ? "\u00A0" : ""}
                              styles={{
                                errorMessage: { position: "absolute" },
                              }}
                            />
                          </Stack.Item>
                          <Stack.Item
                            styles={{
                              root: { width: "10px", paddingTop: "34px" },
                            }}
                          >
                            <Text>x</Text>
                          </Stack.Item>
                          <Stack.Item grow>
                            <TextField
                              componentRef={_depthTextField}
                              label="&nbsp;"
                              placeholder="Depth"
                              onChange={_onChangeTextField.bind(this, "depth")}
                              value={form.depth}
                              // errorMessage={validated && !isDepthValid ? "\u00A0" : ""}
                              styles={{
                                errorMessage: { position: "absolute" },
                              }}
                            />
                          </Stack.Item>
                          <Stack.Item>
                            <Text
                              styles={{
                                root: {
                                  width: "55px",
                                  paddingTop: "34px",
                                  display: "inline-block",
                                },
                              }}
                            >
                              (inches)
                            </Text>
                          </Stack.Item>
                        </Stack>
                        {isValidated && !isDimensionsValid ? (
                          <Text
                            block
                            className="ms-TextField-errorMessage"
                            variant="small"
                            styles={{
                              root: {
                                color: theme.palette.redDark,
                                paddingTop: "5px",
                              },
                            }}
                          >
                            All the dimensions must be entered.
                          </Text>
                        ) : null}
                      </div>
                      <Stack
                        horizontal
                        tokens={{ childrenGap: 5 }}
                        styles={{ root: { maxWidth: 300 } }}
                        verticalAlign="top"
                      >
                        <Stack.Item grow>
                          <TextField
                            componentRef={_weightTextField}
                            label="Weight"
                            onChange={_onChangeTextField.bind(this, "weight")}
                            value={form.weight}
                          />
                        </Stack.Item>
                        <Stack.Item>
                          <Text
                            styles={{
                              root: {
                                width: "55px",
                                paddingTop: "34px",
                                display: "inline-block",
                              },
                            }}
                          >
                            (lbs)
                          </Text>
                        </Stack.Item>
                      </Stack>
                      <Dropdown
                        componentRef={_defaultOrientationDropdownField}
                        label="Default Orientation"
                        placeholder="Select an orientation"
                        styles={{ root: styles.field }}
                        selectedKey={form.default_orientation || ""}
                        onChange={(evt, item) => {
                          setForm({
                            ...form,
                            multi_orientation: !!item.key,
                            default_orientation: item.key,
                          });
                        }}
                        options={[
                          {
                            key: "",
                            text: "N/A",
                          },
                        ].concat(
                          orientations.map((orientation) => {
                            const resource = Utilities.getResource(
                              orientation,
                              state.locale_id
                            );
                            return {
                              key: orientation.orientation_id,
                              text: Utilities.getField(resource, "name"),
                            };
                          })
                        )}
                        required={form.multi_orientation}
                        errorMessage={
                          isValidated &&
                          form.multi_orientation &&
                          !isDefaultOrientationValid
                            ? "An orientation must be selected."
                            : ""
                        }
                      ></Dropdown>
                      <Checkbox
                        label="Allows multiple orientations"
                        styles={{ root: styles.checkbox }}
                        checked={form.multi_orientation}
                        onChange={(evt, checked) => {
                          setForm({
                            ...form,
                            default_orientation: checked
                              ? form.default_orientation
                              : "",
                            multi_orientation: checked,
                          });
                        }}
                      />
                      <Checkbox
                        label="Is threaded"
                        styles={{ root: styles.checkbox }}
                        checked={form.threaded}
                        onChange={_onChangeCheckbox.bind(this, "threaded")}
                      />
                    </div>
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey="categories"
                  headerText="Categories"
                  itemCount={_getInvalidPivotItemFormCount("categories")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "add",
                        text: "Add",
                        iconProps: { iconName: "Add" },
                        onClick: () => {
                          setIsSelectCategories(true);
                        },
                      },
                      {
                        key: "remove",
                        text: "Remove",
                        iconProps: { iconName: "Remove" },
                        disabled: selectedCategories.length === 0,
                        onClick: () => {
                          setForm({
                            ...form,
                            categories: (form.categories || []).filter(
                              (category) => {
                                return !selectedCategories.includes(category);
                              }
                            ),
                          });
                        },
                      },
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  <CategoryTable
                    setKey="categories"
                    items={form.categories || []}
                    selection={_selectionCategories}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    hideRelated={true}
                  />
                  <SelectPanel
                    title="Select Categories"
                    isOpen={isSelectCategories}
                    dismissPanel={(categories) => {
                      setIsSelectCategories(false);
                      if (categories && categories.length > 0) {
                        setForm({
                          ...form,
                          categories: (form.categories || []).concat(
                            categories
                          ),
                        });
                      }
                    }}
                    handler={CategoryHandler}
                    table={CategoryTable}
                    current={form.categories || []}
                  />
                </PivotItem>
                <PivotItem
                  itemKey="adapters"
                  headerText="Adapters"
                  itemCount={_getInvalidPivotItemFormCount("adapters")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "add",
                        text: "Add",
                        iconProps: { iconName: "Add" },
                        onClick: () => {
                          setIsSelectAdapters(true);
                        },
                      },
                      {
                        key: "remove",
                        text: "Remove",
                        iconProps: { iconName: "Remove" },
                        disabled: selectedAdapters.length === 0,
                        onClick: () => {
                          setForm({
                            ...form,
                            adapters: (form.adapters || []).filter(
                              (adapter) => {
                                return !selectedAdapters.includes(adapter);
                              }
                            ),
                          });
                        },
                      },
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  <AdapterTable
                    setKey="adapters"
                    items={form.adapters || []}
                    selection={_selectionAdapters}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    hideRelated={true}
                  />
                  <SelectPanel
                    title="Select Adapters"
                    isOpen={isSelectAdapters}
                    dismissPanel={(adapters) => {
                      setIsSelectAdapters(false);
                      if (adapters && adapters.length > 0) {
                        setForm({
                          ...form,
                          adapters: (form.adapters || []).concat(adapters),
                        });
                      }
                    }}
                    handler={AdapterHandler}
                    table={AdapterTable}
                    current={form.adapters || []}
                  />
                </PivotItem>
                <PivotItem
                  itemKey="recommendations"
                  headerText="Recommendations"
                  itemCount={_getInvalidPivotItemFormCount("recommendations")}
                >
                  <CommandBar
                    items={[
                      {
                        key: "add",
                        text: "Add",
                        iconProps: { iconName: "Add" },
                        onClick: () => {
                          setDialogRecommendation({
                            recommendation_id: -1,
                            product: null,
                            orientation: form.default_orientation
                              ? orientations.find(
                                  (_orientation) =>
                                    _orientation.orientation_id ===
                                    form.default_orientation
                                )
                              : null,
                            quantity: "",
                          });
                        },
                      },
                      {
                        key: "modify",
                        text: "Modify",
                        iconProps: { iconName: "Edit" },
                        disabled: selectedRecommendations.length !== 1,
                        onClick: () => {
                          setDialogRecommendation(selectedRecommendations[0]);
                        },
                      },
                      {
                        key: "remove",
                        text: "Remove",
                        iconProps: { iconName: "Remove" },
                        disabled: selectedRecommendations.length === 0,
                        onClick: () => {
                          setForm({
                            ...form,
                            recommendations: (
                              form.recommendations || []
                            ).filter((recommendation) => {
                              return !selectedRecommendations.includes(
                                recommendation
                              );
                            }),
                          });
                        },
                      },
                    ]}
                    styles={{ root: styles.commandbar }}
                  />
                  <RecommendationTable
                    setKey="recommendations"
                    orientations={orientations || []}
                    items={form.recommendations || []}
                    selection={_selectionRecommendations}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    hideRelated={true}
                  />
                  {dialogRecommendation ? (
                    <RecommendationDialog
                      products={products}
                      orientations={orientations}
                      model={form}
                      recommendation={dialogRecommendation}
                      dismissDialog={(recommendation) => {
                        setDialogRecommendation(null);
                        if (recommendation) {
                          const recommendations = [
                            ...(form.recommendations || []),
                          ];
                          if (recommendation.recommendation_id === -1) {
                            recommendation.recommendation_id = getId();
                            recommendations.push(recommendation);
                          } else {
                            const index = recommendations.findIndex(
                              (_recommendation) =>
                                _recommendation.recommendation_id ===
                                recommendation.recommendation_id
                            );
                            if (index >= 0) {
                              recommendations[index] = recommendation;
                            }
                            setSelectedRecommendations([recommendation]);
                          }
                          setForm({
                            ...form,
                            recommendations: recommendations,
                          });
                        }
                      }}
                    />
                  ) : null}
                </PivotItem>
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const RecommendationDialog = (props) => {
  const [state] = useStateContext();
  const recommendation = props.recommendation;

  const [product, setProduct] = useState(
    recommendation.product ? recommendation.product.product_id : null
  );
  const [orientation, setOrientation] = useState(
    recommendation.orientation
      ? recommendation.orientation.orientation_id
      : null
  );
  const [quantity, setQuantity] = useState(recommendation.quantity || "");

  const _productDropdownField = React.createRef();
  const _orientationDropdownField = React.createRef();
  const _quantityTextField = React.createRef();

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: "Recommendation",
        subText: "Enter the recommended product details for this model:",
      }}
      hidden={false}
      minWidth={450}
      maxWidth={600}
      modalProps={{
        isBlocking: true,
      }}
    >
      <Dropdown
        componentRef={_productDropdownField}
        label="Product"
        placeholder="Select a product"
        styles={{ root: styles.field }}
        selectedKey={product || ""}
        options={(props.products || []).map((product) => {
          return {
            key: product.product_id,
            text: product.name,
          };
        })}
        onChange={(evt, item) => {
          setProduct(item.key);
        }}
        required={true}
      />
      <Dropdown
        componentRef={_orientationDropdownField}
        label="Orientation"
        placeholder="Select an orientation"
        styles={{ root: styles.field }}
        selectedKey={orientation || ""}
        options={[
          {
            key: "",
            text: "N/A",
          },
        ].concat(
          props.orientations.map((orientation) => {
            const resource = Utilities.getResource(
              orientation,
              state.locale_id
            );
            return {
              key: orientation.orientation_id,
              text: Utilities.getField(resource, "name"),
            };
          })
        )}
        onChange={(evt, item) => {
          setOrientation(item.key);
        }}
        required={true}
      />
      <TextField
        componentRef={_quantityTextField}
        label="Quantity"
        styles={{ root: { marginBottom: "35px" } }}
        value={quantity || ""}
        onChange={(evt, text) => {
          setQuantity(text);
        }}
      />
      <DialogFooter>
        <PrimaryButton
          text={recommendation.recommendation_id === -1 ? "Add" : "Modify"}
          onClick={() => {
            props.dismissDialog({
              recommendation_id: recommendation.recommendation_id,
              product: props.products.find(
                (_product) => _product.product_id === product
              ),
              orientation: props.orientations.find(
                (_orientation) => _orientation.orientation_id === orientation
              ),
              quantity,
            });
          }}
        />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            props.dismissDialog();
          }}
        />
      </DialogFooter>
    </Dialog>
  );
};

const RecommendationTable = (props) => {
  const [state] = useStateContext();
  const { orientations = [] } = props;

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
      onRender: (item) => {
        return item.product ? item.product.code : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 550,
      isSorted: true,
      onRender: (item) => {
        return item.product ? item.product.name : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Orientation",
      key: "orientation",
      fieldName: "orientation",
      data: "string",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item) => {
        const orientation = item.orientation
          ? orientations.find(function (orientation) {
              return (
                orientation.orientation_id === item.orientation.orientation_id
              );
            })
          : null;
        const resource = orientation
          ? Utilities.getResource(orientation, state.locale_id)
          : null;

        return item.orientation ? Utilities.getField(resource, "name") : "N/A";
      },
    },
    {
      ...columnAttrCommon,
      name: "Quantity",
      key: "quantity",
      fieldName: "quantity",
      data: "number",
      minWidth: 150,
      maxWidth: 150,
    },
  ];

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={(props.items || []).sort((a, b) => {
        return (a.product ? a.product.name : "").localeCompare(
          b.product ? b.product.name : ""
        );
      })}
    />
  );
};

export default ModelPage;
