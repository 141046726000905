import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ProductHandler } from "../../api";
import { useStateContext } from "../../state";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
  PrimaryButton,
  ProgressIndicator,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

const _getItemsSortedByOrder = items => {
  return (items || []).sort((a, b) => {
    return a.order - b.order;
  });
};

const _getItemsSortedByName = items => {
  return (items || []).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

function ProductsPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const [ordering, setOrdering] = useState({ visible: false, items: [] });

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let products = await ProductHandler.list();
          setItems(products);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _onItemInvoked = item => {
    history.push("/products/" + item.product_id);
  };

  const _selection = useConst(
    new Selection({
      getKey: product => {
        return product.product_id + "";
      },
      onSelectionChanged: () => {
        setSelectedItems(_selection.getSelection());
      }
    })
  );

  const _onClickDeleteButton = () => {
    setIsDeleting(true);

    (async () => {
      try {
        await ProductHandler.delete(selectedItems);
        setItems(
          items.filter(item => {
            return !selectedItems.includes(item);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteDialogVisible(false);
      setIsDeleting(false);
    })();
  };

  const _onClickCancelButton = () => {
    setIsDeleteDialogVisible(false);
    setIsDeleting(false);
  };

  const _onClickSaveOrderingButton = () => {
    setIsOrdering(true);

    (async () => {
      try {
        await ProductHandler.order(_getItemsSortedByOrder(ordering.items));
        setItems(ordering.items);
        setOrdering({ visible: false, items: [] });
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsOrdering(false);
    })();
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "PRODUCTS" }]}
        dividerAs={dividerProps => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Products
          </Text>
        </div>
        <CommandBar
          items={
            ordering.visible
              ? [
                  {
                    key: "orderItemsHelp",
                    text: "Drag the rows around to re-order and click Save.",
                    commandBarButtonAs: props => {
                      return (
                        <Label style={{ lineHeight: "34px" }}>
                          {props.text}
                        </Label>
                      );
                    }
                  }
                ]
              : [
                  {
                    key: "newItem",
                    text: "New product",
                    iconProps: { iconName: "Add" },
                    onClick: () => history.push("/products/new")
                  },
                  {
                    key: "editItem",
                    text: "Edit",
                    disabled: selectedItems.length !== 1,
                    iconProps: { iconName: "Edit" },
                    onClick: () =>
                      history.push("/products/" + selectedItems[0].product_id)
                  },
                  {
                    key: "deleteItem",
                    text: "Delete",
                    disabled: selectedItems.length <= 0,
                    iconProps: { iconName: "Trash" },
                    onClick: () => {
                      setIsDeleteDialogVisible(true);
                    }
                  }
                ]
          }
          farItems={
            ordering.visible
              ? [
                  {
                    key: "saveOrderItems",
                    text: "Save",
                    iconProps: { iconName: "Save" },
                    onClick: _onClickSaveOrderingButton
                  },
                  {
                    key: "cancelOrderItems",
                    text: "Cancel",
                    iconProps: { iconName: "Cancel" },
                    onClick: () => {
                      setOrdering({ visible: false, items: [] });
                    }
                  }
                ]
              : [
                  {
                    key: "ordertItems",
                    text: "Set order",
                    iconProps: { iconName: "SortLines" },
                    onClick: () => {
                      setOrdering({
                        visible: true,
                        items: _getItemsSortedByOrder(
                          // deep clone items
                          JSON.parse(JSON.stringify(items)) || []
                        )
                      });
                    }
                  }
                ]
          }
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || ""
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Product(s)",
            subText: "Are you sure you want to delete the selected product(s)?"
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {}
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteButton}
              text="Delete"
              disabled={isDeleting}
            />
            <DefaultButton onClick={_onClickCancelButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isDeleting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isOrdering}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Saving...",
            subText:
              "Please be patient while the product list order is being saved."
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <ProgressIndicator />
        </Dialog>
        {ordering.visible ? (
          <OrderableProductTable items={ordering.items} />
        ) : (
          <ProductTable
            setKey="products"
            items={items || []}
            selection={_selection}
            selectionPreservedOnEmptyClick={true}
            selectionMode={SelectionMode.multiple}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onItemInvoked={_onItemInvoked}
            enableShimmer={isLoading}
          />
        )}
      </div>
    </>
  );
}

const OrderableProductTable = props => {
  const [state] = useStateContext();

  const [items, setItems] = useState(props.items);
  const [, setSelectedItems] = useState([]);

  const _selection = useConst(
    new Selection({
      getKey: product => {
        return product.product_id + "";
      },
      onSelectionChanged: () => {
        setSelectedItems(_selection.getSelection());
      }
    })
  );

  const _insertBeforeItem = item => {
    let dragIndex = items.indexOf(_draggedItem);
    let insertIndex = items.indexOf(item);

    const _items = [...items];
    _items.splice(dragIndex, 1);
    _items.splice(insertIndex, 0, _draggedItem);
    // set orders
    _items.forEach((_item, index) => {
      _item.order = index + 1;
    });
    setItems(_getItemsSortedByOrder(_items));
  };

  let _draggedItem = null;

  const _dragDropEvents = {
    canDrop: (dropContext, dragContext) => {
      return true;
    },
    canDrag: item => {
      return true;
    },
    onDragEnter: (item, event) => {
      // return string is the css classes that will be added to the entering element.
      return "details-list-drag-enter";
    },
    onDragLeave: (item, event) => {
      return;
    },
    onDrop: (item, event) => {
      if (_draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (item, itemIndex, selectedItems, event) => {
      _draggedItem = item;
    },
    onDragEnd: (item, event) => {
      _draggedItem = null;
    }
  };

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 150
    },
    {
      ...columnAttrCommon,
      name: "Description",
      key: "description",
      fieldName: "description",
      data: "string",
      minWidth: 150,
      maxWidth: 550,
      onRender: item => {
        let resource = (item.resources || []).find(resource => {
          return resource.locale_id === state.locale_id;
        });
        return resource ? resource.description : "";
      }
    },
    {
      ...columnAttrCommon,
      name: "Order",
      key: "order",
      fieldName: "order",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
      isSorted: true
    }
  ];

  return (
    <ShimmeredDetailsList
      setKey="orderable-products"
      columns={_columns}
      items={items || []}
      selection={_selection}
      selectionPreservedOnEmptyClick={true}
      selectionMode={SelectionMode.single}
      layoutMode={DetailsListLayoutMode.justified}
      dragDropEvents={_dragDropEvents}
      isHeaderVisible={true}
    />
  );
};

export const ProductTable = props => {
  const [state] = useStateContext();

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 150,
      isSorted: true
    },
    {
      ...columnAttrCommon,
      name: "Description",
      key: "description",
      fieldName: "description",
      data: "string",
      minWidth: 150,
      maxWidth: 550,
      onRender: item => {
        let resource = (item.resources || []).find(resource => {
          return resource.locale_id === state.locale_id;
        });
        return resource ? resource.description : "";
      }
    },
    {
      ...columnAttrCommon,
      name: "Product Series",
      key: "product_series",
      fieldName: "product_series",
      data: "string",
      minWidth: 125,
      maxWidth: 150,
      onRender: item => {
        let resource = (item.product_series
          ? item.product_series.resources
          : []
        ).find(resource => {
          return resource.locale_id === state.locale_id;
        });
        return resource ? resource.name : "N/A";
      }
    },
    {
      ...columnAttrCommon,
      name: "Categories",
      key: "categories",
      fieldName: "categories",
      data: "string",
      minWidth: 200,
      maxWidth: 300,
      onRender: item => {
        return (
          (item.categories || [])
            .map(category => {
              let resource = (category.resources || []).find(resource => {
                return resource.locale_id === state.locale_id;
              });
              return resource ? resource.name : "";
            })
            .join(", ") || "None"
        );
      }
    },
    {
      ...columnAttrCommon,
      name: "Threaded",
      key: "threaded",
      fieldName: "threaded",
      data: "boolean",
      minWidth: 70,
      maxWidth: 90,
      onRender: item => {
        return item.threaded ? "Yes" : "No";
      }
    },
    {
      ...columnAttrCommon,
      name: "Order",
      key: "order",
      fieldName: "order",
      data: "number",
      minWidth: 70,
      maxWidth: 90
      // isSorted: true
    }
  ].concat(
    !props.hideRelated
      ? [
          {
            ...columnAttrCommon,
            name: "# Adapters",
            key: "adapters",
            fieldName: "adapter_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90
          },
          {
            ...columnAttrCommon,
            name: "# Dealers",
            key: "dealers",
            fieldName: "dealer_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90
          },
          {
            ...columnAttrCommon,
            name: "# Constraints",
            key: "constraints",
            fieldName: "constraint_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90
          },
          {
            ...columnAttrCommon,
            name: "# Images",
            key: "images",
            fieldName: "image_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90
          },
          {
            ...columnAttrCommon,
            name: "# Recommendations",
            key: "recommendations",
            fieldName: "recommendation_count",
            data: "number",
            minWidth: 120,
            maxWidth: 120
          }
        ]
      : []
  );

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={_getItemsSortedByName(props.items || [])}
    />
  );
};

export default ProductsPage;
