import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  ModelHandler,
  BulkHandler,
  OrientationHandler,
  DownloadHandler,
  Utilities,
} from "../../api";
import { useStateContext } from "../../state";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  SearchBox,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

const _getItemsSortedByName = (items) => {
  return (items || []).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

function ModelsPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [orientations, setOrientations] = useState([]);
  const [exports, setExports] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState(null);
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isExportDialogVisible, setIsExportDialogVisible] = useState(false);

  const ModelBulkHandler = new BulkHandler("models");

  const _fileUploadField = React.createRef();

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          const values = await Promise.all([
            OrientationHandler.list(),
            ModelHandler.list(),
            ModelHandler.exports(),
          ]);
          let orientations = values[0];
          let models = values[1];
          let exports = values[2];

          setOrientations(orientations);
          setExports(
            exports.sort((a, b) => {
              return (a.name || "").localeCompare(b.name || "");
            })
          );
          setItems(
            models.sort((a, b) => {
              return (a.name || "").localeCompare(b.name || "");
            })
          );

          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const _onClickCloseUploadSuccessDialog = () => {
    setIsUploadSuccess(false);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _onItemInvoked = (item) => {
    history.push("/models/" + item.model_id);
  };

  const _selection = useConst(
    new Selection({
      getKey: (model) => {
        return model.model_id + "";
      },
      onSelectionChanged: () => {
        setSelectedItems(_selection.getSelection());
      },
    })
  );

  const _onChangeUploadFile = (event) => {
    setIsUploading(true);
    (async () => {
      try {
        const file = event.target.files[0];
        event.target.value = "";
        await ModelBulkHandler.import(file);
        setIsUploading(false);
        setIsUploadSuccess(true);
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
        setIsUploading(false);
      }
    })();
  };

  const _onClickDeleteButton = () => {
    setIsDeleting(true);

    (async () => {
      try {
        await ModelHandler.delete(selectedItems);
        setItems(
          items.filter((item) => {
            return !selectedItems.includes(item);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteDialogVisible(false);
      setIsDeleting(false);
    })();
  };

  const _onClickCancelDeleteButton = () => {
    setIsDeleteDialogVisible(false);
    setIsDeleting(false);
  };

  const _onClickExportButton = () => {
    setIsExporting(true);

    (async () => {
      try {
        await ModelBulkHandler.export();
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsExportDialogVisible(false);
      setIsExporting(false);
    })();
  };

  const _onClickCancelExportButton = () => {
    setIsExportDialogVisible(false);
    setIsExporting(false);
  };

  const _onChangeFilter = (evt, text) => {
    setFilter(text || null);
  };

  const _onClickDownloadExport = function () {
    (async () => {
      try {
        let data = await DownloadHandler.download(
          `/models/exports/${encodeURIComponent(this.filename)}`
        );

        const url = window.URL.createObjectURL(new Blob([data]));
        const element = document.createElement("a");
        element.setAttribute("href", url);
        element.setAttribute("download", this.filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch (e) {
        setError(
          e.response && e.response.data
            ? e.response.data.message || e.message
            : e.message
        );
      }
    })();
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "MANUFACTURERS" }, { text: "MODELS" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Models
          </Text>
        </div>
        <CommandBar
          items={[
            {
              key: "newItem",
              text: "New model",
              iconProps: { iconName: "Add" },
              onClick: () => history.push("/models/new"),
            },
            {
              key: "editItem",
              text: "Edit",
              disabled: selectedItems.length !== 1,
              iconProps: { iconName: "Edit" },
              onClick: () =>
                history.push("/models/" + selectedItems[0].model_id),
            },
            {
              key: "deleteItem",
              text: "Delete",
              disabled: selectedItems.length <= 0,
              iconProps: { iconName: "Trash" },
              onClick: () => {
                setIsDeleteDialogVisible(true);
              },
            },
          ]}
          farItems={[
            {
              key: "import",
              text: "Import",
              iconProps: { iconName: "BulkUpload" },
              onClick: () => {
                if (_fileUploadField.current) {
                  _fileUploadField.current.click();
                }
              },
            },
            {
              key: "import-file",
              onRender: () => (
                <input
                  ref={_fileUploadField}
                  style={{ display: "none" }}
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={_onChangeUploadFile}
                />
              ),
            },
            {
              key: "export",
              text: "Export",
              iconProps: { iconName: "DownloadDocument" },
              split: true,
              subMenuProps: {
                items:
                  exports && exports.length > 0
                    ? exports.map((_export) => {
                        return {
                          key: _export,
                          text: _export,
                          iconProps: { iconName: "ExcelDocument" },
                          onClick: _onClickDownloadExport.bind({
                            filename: _export,
                          }),
                        };
                      })
                    : [
                        {
                          key: "export-none-available",
                          text: "No exports available",
                        },
                      ],
              },
              onClick: () => {
                setIsExportDialogVisible(true);
              },
            },
          ]}
          styles={{ root: styles.commandbar }}
        />
        <SearchBox
          placeholder="Filter"
          iconProps={{ iconName: "Filter" }}
          underlined={true}
          onChange={_onChangeFilter}
        />
        <Dialog
          hidden={!isUploading}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Importing...",
            subText: "Please be patient while the file is being imported.",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <ProgressIndicator />
        </Dialog>
        <Dialog
          hidden={!isUploadSuccess}
          dialogContentProps={{
            type: DialogType.close,
            title: "Message",
            subText:
              "A bulk transaction has been successfully started. Please refer to the logs for its progress.",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickCloseUploadSuccessDialog}
              text="Ok"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Model(s)",
            subText: "Are you sure you want to delete the selected model(s)?",
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {},
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteButton}
              text="Delete"
              disabled={isDeleting}
            />
            <DefaultButton onClick={_onClickCancelDeleteButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isDeleting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isExportDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Export Models",
            subText: "Are you sure you want to export all models?",
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {},
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickExportButton}
              text="Export"
              disabled={isExporting}
            />
            <DefaultButton onClick={_onClickCancelExportButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isExporting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <ModelTable
          setKey="models"
          orientations={orientations || []}
          items={
            filter
              ? items.filter((model) =>
                  [model.name, model.make ? model.make.name : ""].some(
                    (attr) =>
                      attr.toLowerCase().indexOf(filter.toLowerCase()) === 0
                  )
                )
              : items
          }
          selectionMode={SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={_selection}
          onItemInvoked={_onItemInvoked}
          enableShimmer={isLoading}
        />
      </div>
    </>
  );
}

export const ModelTable = (props) => {
  const [state] = useStateContext();
  const { orientations = [] } = props;

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 150,
      maxWidth: 250,
      isSorted: true,
    },
  ]
    .concat(
      props.hideMake
        ? []
        : [
            {
              ...columnAttrCommon,
              name: "Make",
              key: "make",
              fieldName: "make",
              data: "string",
              minWidth: 150,
              maxWidth: 200,
              onRender: (item) => {
                return item.make ? item.make.name : "";
              },
            },
          ]
    )
    .concat([
      {
        ...columnAttrCommon,
        name: "Categories",
        key: "categories",
        fieldName: "categories",
        data: "string",
        minWidth: 150,
        maxWidth: 200,
        onRender: (item) => {
          return (
            (item.categories || [])
              .map((category) => {
                let resource = (category.resources || []).find((resource) => {
                  return resource.locale_id === state.locale_id;
                });
                return resource ? resource.name : "";
              })
              .join(", ") || "-"
          );
        },
      },
      {
        ...columnAttrCommon,
        name: "Width",
        key: "width",
        fieldName: "width",
        data: "number",
        minWidth: 70,
        maxWidth: 90,
      },
      {
        ...columnAttrCommon,
        name: "Height",
        key: "height",
        fieldName: "height",
        data: "number",
        minWidth: 70,
        maxWidth: 90,
      },
      {
        ...columnAttrCommon,
        name: "Depth",
        key: "depth",
        fieldName: "depth",
        data: "number",
        minWidth: 70,
        maxWidth: 90,
      },
      {
        ...columnAttrCommon,
        name: "Weight",
        key: "weight",
        fieldName: "weight",
        data: "number",
        minWidth: 70,
        maxWidth: 90,
      },
      {
        ...columnAttrCommon,
        name: "Is Threaded",
        key: "threaded",
        fieldName: "threaded",
        data: "boolean",
        minWidth: 70,
        maxWidth: 90,
        onRender: (item) => {
          return item.threaded ? "Yes" : "No";
        },
      },
      {
        ...columnAttrCommon,
        name: "Multi-Orientation",
        key: "multi_orientation",
        fieldName: "multi_orientation",
        data: "boolean",
        minWidth: 90,
        maxWidth: 90,
        onRender: (item) => {
          return item.multi_orientation ? "Yes" : "No";
        },
      },
      {
        ...columnAttrCommon,
        name: "Default Orientation",
        key: "default_orientation",
        fieldName: "default_orientation",
        data: "string",
        minWidth: 110,
        maxWidth: 110,
        onRender: (item) => {
          const orientation = item.default_orientation
            ? orientations.find(function (orientation) {
                return (
                  orientation.orientation_id ===
                  item.default_orientation.orientation_id
                );
              })
            : null;
          const resource = orientation
            ? Utilities.getResource(orientation, state.locale_id)
            : null;

          return item.default_orientation
            ? Utilities.getField(resource, "name")
            : "N/A";
        },
      },
    ])
    .concat(
      props.hideRelated
        ? []
        : [
            {
              ...columnAttrCommon,
              name: "# Thread Sizes",
              key: "threads",
              fieldName: "thread_size_count",
              data: "number",
              minWidth: 80,
              maxWidth: 90,
            },
            {
              ...columnAttrCommon,
              name: "# Adapters",
              key: "adapters",
              fieldName: "adapter_count",
              data: "number",
              minWidth: 55,
              maxWidth: 65,
            },
            {
              ...columnAttrCommon,
              name: "# Recommendations",
              key: "recommendations",
              fieldName: "recommendation_count",
              data: "number",
              minWidth: 120,
              maxWidth: 130,
            },
          ]
    );

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={_getItemsSortedByName(props.items || [])}
    />
  );
};

export default ModelsPage;
