import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import validator from "validator";

import { LanguageHandler, Utilities } from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  Breadcrumb,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Shimmer,
  ShimmerElementType,
  Text,
  TextField,
} from "office-ui-fabric-react";

import TextFieldLocale from "../TextFieldLocale";

function LanguagePage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});

  const { from } = location.state || {
    from: { pathname: "/languages" },
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form;
          // if language location, retrieve language, else set defaults
          if (location.pathname.endsWith("new")) {
            _form = {};
          } else {
            const values = await Promise.all([LanguageHandler.get(params.id)]);

            const _language = values[0];
            _form = _language;
          }

          Utilities.initResources(_form, state.locales, {
            name: "",
            language_id: form.language_id,
          });

          setForm(_form);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);
  const shimmers = mergeStyles({
    marginTop: "20px",
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });

  const title = location.pathname.endsWith("new")
    ? "New Language"
    : `Language: ${Utilities.getResourceFieldByLocale(
        form,
        "name",
        state.locale_id
      )}`;

  const [isValidated, setValidated] = useState(false);
  const isCodeValid = !validator.isEmpty((form.code || "").trim());
  const isNameValid = (form.resources || []).every(
    (resource) => !validator.isEmpty((resource.name || "").trim())
  );

  // general
  const _codeTextField = React.createRef();

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text,
    });
  };

  const _onClickSave = () => {
    if (isCodeValid && isNameValid) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [isCodeValid, isNameValid],
  };
  const _getInvalidPivotItemFormCount = (itemKey) => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          if (data.language_id) {
            await LanguageHandler.update(data);
          } else {
            await LanguageHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "CODE TABLES" }, { text: "LANGUAGES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText:
                    "Please be patient while the language is being saved.",
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } },
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount,
                }}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Information
                      </Separator>
                      <TextField
                        componentRef={_codeTextField}
                        label="Code"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "code")}
                        value={form.code}
                        required={true}
                        errorMessage={
                          isValidated && !isCodeValid
                            ? "Code must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextFieldLocale
                        field="name"
                        label="Name"
                        form={form}
                        setForm={setForm}
                        styles={{ root: styles.field }}
                        required={true}
                        errorMessage={
                          isValidated && !isNameValid
                            ? "Name must be at least one character in length for each locale."
                            : ""
                        }
                      />
                    </div>
                  </div>
                </PivotItem>
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LanguagePage;
