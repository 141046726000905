import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { EntityHandler } from "./EntityHandler";

SuperTokensRequest.makeSuper(axios);

class CategoryHandler extends EntityHandler {
  constructor() {
    super("categories", "category_id");
  }

  async order(objects) {
    let response = await axios.put(
      `${this.uri}/order`,
      (objects || []).map((obj) => {
        return obj[this.idAttr];
      })
    );
    return response.data;
  }

  async products(id) {
    let response = await axios.get(`${this.uri}/${id}/products`);
    return response.data;
  }

  async models(id) {
    let response = await axios.get(`${this.uri}/${id}/models`);
    return response.data;
  }

  async dealers(id) {
    let response = await axios.get(`${this.uri}/${id}/dealers`);
    return response.data;
  }
}

export default new CategoryHandler();
