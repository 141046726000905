import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { EntityHandler } from "./EntityHandler";

SuperTokensRequest.makeSuper(axios);

class CustomerInputHandler extends EntityHandler {
  constructor() {
    super("customer-inputs", "customer_input_id");
  }

  async exports() {
    let response = await axios.get(`${this.uri}/exports`);
    return response.data;
  }
}

export default new CustomerInputHandler();
