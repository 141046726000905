import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import validator from "validator";

import { RoleHandler, UserHandler } from "../../api";

import routeStyleSets from "./styles";

import {
  mergeStyles,
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Shimmer,
  ShimmerElementType,
  Text,
  TextField
} from "office-ui-fabric-react";

function UserPage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [roles, setRoles] = useState([]);

  const { from } = location.state || { from: { pathname: "/users" } };

  const password = "***************";

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form, _roles;
          // if user location, retrieve user, else set defaults
          if (location.pathname.endsWith("new")) {
            _roles = await RoleHandler.list();
            _form = { roles: ["admin"] };
          } else {
            const values = await Promise.all([
              RoleHandler.list(),
              UserHandler.get(params.id)
            ]);
            _roles = values[0];

            const _user = values[1];
            _user.password = password;
            _user.confirmation = password;
            _user.roles = (_user.roles || []).map(role => {
              return role.code;
            });
            _form = _user;
          }

          setRoles(_roles);
          setForm(_form);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);
  const shimmers = mergeStyles({
    marginTop: "20px",
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0"
      }
    }
  });

  const title = location.pathname.endsWith("new") ? "New User" : "User";

  const [isValidated, setValidated] = useState(false);
  const isNameValid = !validator.isEmpty((form.name || "").trim());
  const isEmailValid =
    validator.isEmpty((form.email || "").trim()) ||
    validator.isEmail(form.email || "");

  const isUsernameValid = !validator.isEmpty((form.username || "").trim());
  const isPasswordValid = !validator.isEmpty((form.password || "").trim());
  const isConfirmationValid =
    isPasswordValid && form.password === form.confirmation;

  // general
  const _nameTextField = React.createRef();
  const _emailTextField = React.createRef();

  // access
  const _usernameTextField = React.createRef();
  const _passwordTextField = React.createRef();
  const _confirmationTextField = React.createRef();
  const _rolesDropdownField = React.createRef();

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text
    });
  };

  const _onChangeDropdown = (attr, event, item) => {
    const selected = [...(form[attr] || [])];
    if (item.selected) {
      selected.push(item.key);
    } else {
      const index = selected.indexOf(item.key);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setForm({
      ...form,
      [attr]: selected
    });
  };

  const _onClickSave = () => {
    if (isNameValid && isEmailValid && isUsernameValid && isPasswordValid) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [isNameValid, isEmailValid],
    access: [isUsernameValid, isPasswordValid, isConfirmationValid]
  };
  const _getInvalidPivotItemFormCount = itemKey => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);
          if (data.password === password) {
            delete data.password;
          }
          delete data.confirmation;

          data.roles = data.roles.map(code => {
            return roles.find(role => {
              return role.code === code;
            });
          });

          if (form.user_id) {
            await UserHandler.update(data);
          } else {
            await UserHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "SETTINGS" }, { text: "USERS" }]}
        dividerAs={dividerProps => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || ""
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" }
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 }
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" }
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" }
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <CommandBar
              items={[
                {
                  key: "saveItem",
                  text: "Save",
                  iconProps: { iconName: "Save" },
                  onClick: _onClickSave
                },
                {
                  key: "discardItem",
                  text: "Discard",
                  iconProps: { iconName: "Clear" },
                  onClick: () => history.replace(from)
                }
                // {
                //   key: "resetItem",
                //   text: "Reset",
                //   disabled: selectedItems.length !== 1,
                //   iconProps: { iconName: "Edit" },
                //   onClick: () => history.push("/users/" + selectedItems[0].user_id)
                // },
                // {
                //   key: "deleteItem",
                //   text: "Delete",
                //   disabled: selectedItems.length <= 0,
                //   iconProps: { iconName: "Trash" },
                //   onClick: () => {
                //     setIsDeleteDialogVisible(true);
                //   }
                // }
              ]}
              styles={{ root: styles.commandbar }}
            />
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText: "Please be patient while the user is being saved."
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } }
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot styles={{ count: styles.pivotItemInvalidCount }}>
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.form}>
                    <TextField
                      componentRef={_nameTextField}
                      label="Name"
                      styles={{ root: styles.field }}
                      onChange={_onChangeTextField.bind(this, "name")}
                      value={form.name}
                      required={true}
                      errorMessage={
                        isValidated && !isNameValid
                          ? "Name must be at least one character in length."
                          : ""
                      }
                    ></TextField>
                    <TextField
                      componentRef={_emailTextField}
                      label="Email"
                      styles={{ root: styles.field }}
                      onChange={_onChangeTextField.bind(this, "email")}
                      value={form.email}
                      errorMessage={
                        isValidated && !isEmailValid
                          ? "Email must be a valid email address i.e. name@domain.com"
                          : ""
                      }
                    ></TextField>
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey="access"
                  headerText="Access"
                  itemCount={_getInvalidPivotItemFormCount("access")}
                >
                  <div className={styles.form}>
                    <TextField
                      componentRef={_usernameTextField}
                      label="Username"
                      styles={{ root: styles.field }}
                      onChange={_onChangeTextField.bind(this, "username")}
                      value={form.username}
                      required={true}
                      errorMessage={
                        isValidated && !isUsernameValid
                          ? "Username must be at least one character in length."
                          : ""
                      }
                    ></TextField>
                    <TextField
                      componentRef={_passwordTextField}
                      label="Password"
                      styles={{ root: styles.field }}
                      type="password"
                      onChange={_onChangeTextField.bind(this, "password")}
                      value={form.password}
                      required={true}
                      errorMessage={
                        isValidated && !isPasswordValid
                          ? "Password must meet the minimum complexity requirements."
                          : ""
                      }
                    ></TextField>
                    <TextField
                      componentRef={_confirmationTextField}
                      label="Confirmation"
                      styles={{ root: styles.field }}
                      type="password"
                      onChange={_onChangeTextField.bind(this, "confirmation")}
                      value={form.confirmation}
                      required={true}
                      errorMessage={
                        isValidated && !isConfirmationValid
                          ? "Confirmation password must match password."
                          : ""
                      }
                    ></TextField>
                    <Separator
                      alignContent="start"
                      styles={{
                        root: { margin: "25px 0 10px 0" },
                        content: { paddingLeft: "0px" }
                      }}
                    >
                      Authorization
                    </Separator>
                    <Dropdown
                      componentRef={_rolesDropdownField}
                      label="Roles"
                      placeholder="Select roles"
                      selectedKeys={form.roles || []}
                      onChange={_onChangeDropdown.bind(this, "roles")}
                      multiSelect
                      options={roles.map(role => {
                        return { key: role.code, text: role.name };
                      })}
                    ></Dropdown>
                  </div>
                </PivotItem>
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default UserPage;
