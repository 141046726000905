import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AdapterHandler, Utilities } from "../../api";
import { useStateContext } from "../../state";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from "office-ui-fabric-react";

const _getItemsSortedByName = (items, locale_id) => {
  return (items || []).sort((a, b) => {
    let aResource = Utilities.getResource(a, locale_id);
    let bResource = Utilities.getResource(b, locale_id);
    return Utilities.getField(aResource, "name").localeCompare(
      Utilities.getField(bResource, "name")
    );
  });
};

function AdaptersPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let adapters = await AdapterHandler.list();
          setItems(adapters);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _getKey = (item) => {
    return item ? item.adapter_id : undefined;
  };
  const _onItemInvoked = (item) => {
    history.push("/adapters/" + item.adapter_id);
  };

  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(_selection.getSelection());
    },
  });

  const _onClickDeleteButton = () => {
    setIsDeleting(true);

    (async () => {
      try {
        await AdapterHandler.delete(selectedItems);
        setItems(
          items.filter((item) => {
            return !selectedItems.includes(item);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteDialogVisible(false);
      setIsDeleting(false);
    })();
  };

  const _onClickCancelButton = () => {
    setIsDeleteDialogVisible(false);
    setIsDeleting(false);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "ADAPTERS" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Adapters
          </Text>
        </div>
        <CommandBar
          items={[
            {
              key: "newItem",
              text: "New adapter",
              iconProps: { iconName: "Add" },
              onClick: () => history.push("/adapters/new"),
            },
            {
              key: "editItem",
              text: "Edit",
              disabled: selectedItems.length !== 1,
              iconProps: { iconName: "Edit" },
              onClick: () =>
                history.push("/adapters/" + selectedItems[0].adapter_id),
            },
            {
              key: "deleteItem",
              text: "Delete",
              disabled: selectedItems.length <= 0,
              iconProps: { iconName: "Trash" },
              onClick: () => {
                setIsDeleteDialogVisible(true);
              },
            },
          ]}
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Adapter(s)",
            subText: "Are you sure you want to delete the selected adapter(s)?",
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {},
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteButton}
              text="Delete"
              disabled={isDeleting}
            />
            <DefaultButton onClick={_onClickCancelButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isDeleting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <AdapterTable
          items={items}
          selectionMode={SelectionMode.multiple}
          getKey={_getKey}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={_selection}
          onItemInvoked={_onItemInvoked}
          enableShimmer={isLoading}
        />
      </div>
    </>
  );
}

export const AdapterTable = (props) => {
  const [state] = useStateContext();

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 250,
      isSorted: true,
      onRender: (item) => {
        let resource = Utilities.getResource(item, state.locale_id);
        return Utilities.getField(resource, "name");
      },
    },
    {
      ...columnAttrCommon,
      name: "Description",
      key: "description",
      fieldName: "description",
      data: "string",
      minWidth: 150,
      maxWidth: 550,
      onRender: (item) => {
        let resource = Utilities.getResource(item, state.locale_id);
        return Utilities.getField(resource, "description");
      },
    },
  ].concat(
    !props.hideRelated
      ? [
          {
            ...columnAttrCommon,
            name: "# Products",
            key: "products",
            fieldName: "product_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90,
          },
          {
            ...columnAttrCommon,
            name: "# Models",
            key: "models",
            fieldName: "model_count",
            data: "number",
            minWidth: 80,
            maxWidth: 90,
          },
        ]
      : []
  );

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={_getItemsSortedByName(props.items || [], state.locale_id)}
    />
  );
};

export default AdaptersPage;
