import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";
import validator from "validator";

import { SessionHandler } from "../api";
import { useStateContext } from "../state";

import "./LoginPage.css";
import logo from "../logo.svg";

import {
  getTheme,
  FontSizes,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField
} from "office-ui-fabric-react";
import { NeutralColors } from "@uifabric/fluent-theme";

SuperTokensRequest.makeSuper(axios);

function LoginPage() {
  const theme = getTheme();
  const [, dispatch] = useStateContext();
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setValidated] = useState(false);
  const [isError, setError] = useState(false);
  const [form, setForm] = useState({ username: "", password: "" });

  const isUsernameValid = !validator.isEmpty(form.username || "");
  const isPasswordValid = !validator.isEmpty(form.password || "");

  const { from } = location.state || { from: { pathname: "/" } };
  const _usernameTextField = React.createRef();

  const errorMessage = useRef("");

  const _onSubmitForm = evt => {
    evt.preventDefault();
  };

  const _onChangeTextFieldUsername = (event, text) => {
    setForm({
      ...form,
      username: text.trim()
    });
  };

  const _onChangeTextFieldPassword = (event, text) => {
    setForm({
      ...form,
      password: text.trim()
    });
  };

  const _onClickSignIn = () => {
    if (isUsernameValid && isPasswordValid) {
      setIsLoading(true);
    }
    setValidated(true);
  };

  useEffect(() => {
    _usernameTextField.current.focus();
    _usernameTextField.current.select();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          const sessionUser = await SessionHandler.authenticate(
            form.username,
            form.password
          );
          dispatch({
            type: "sessionUser",
            sessionUser
          });
          history.replace(from);
        } catch (e) {
          errorMessage.current =
            e.response && e.response.data ? e.response.data.message : e.message;
          setError(true);
          setIsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div className="login-page">
      <Stack className="login-container">
        <div
          className="header-container"
          style={{ borderColor: NeutralColors.gray50 }}
        >
          <img src={logo} className="logo" alt="logo" />
          <div
            className="progress"
            style={{ display: isLoading ? "" : "none" }}
          >
            <ProgressIndicator />
          </div>
        </div>
        <Stack tokens={{ childrenGap: 5 }}>
          <div
            style={{
              position: "relative",
              marginTop: "30px",
              fontSize: FontSizes.small
            }}
          >
            <Text
              styles={{ root: { verticalAlign: "top", fontSize: "inherit" } }}
            >
              ADMIN PORTAL
            </Text>
          </div>
          <Text styles={{ root: { fontSize: FontSizes.xxLarge } }}>
            Sign in
          </Text>
          <Text
            styles={{
              root: {
                marginTop: "20px !important",
                fontSize: FontSizes.small
              }
            }}
          >
            Enter your credentials to access the admin portal.
          </Text>
          <form onSubmit={_onSubmitForm}>
            <div style={{ marginTop: "20px" }}>
              {isValidated && isError ? (
                <Text
                  block
                  className="ms-TextField-errorMessage"
                  variant="small"
                  styles={{
                    root: { color: theme.palette.redDark, paddingBottom: "5px" }
                  }}
                >
                  {errorMessage.current}
                </Text>
              ) : (
                <></>
              )}
              <TextField
                componentRef={_usernameTextField}
                label="Username"
                onChange={_onChangeTextFieldUsername}
                value={form.username}
                errorMessage={
                  isValidated && !isUsernameValid
                    ? "Please enter your username."
                    : ""
                }
              ></TextField>
              <TextField
                type="password"
                label="Password"
                onChange={_onChangeTextFieldPassword}
                value={form.password}
                errorMessage={
                  isValidated && !isPasswordValid
                    ? "Please enter your password."
                    : ""
                }
                styles={{ root: { marginTop: "5px" } }}
              ></TextField>
            </div>
            <div style={{ marginTop: "35px" }}>
              <PrimaryButton
                onClick={_onClickSignIn}
                text="Sign in"
                type="submit"
                disabled={isLoading}
              />
            </div>
          </form>
        </Stack>
      </Stack>
    </div>
  );
}

export default LoginPage;
