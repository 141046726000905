import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useStateContext } from "../state";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function ProtectedRoute({ Component, ...rest }) {
  const [state] = useStateContext();
  return (
    <Route
      {...rest}
      render={({ match, location }) =>
        state.sessionUser !== null ? (
          <Component match={match} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
