import React, { memo } from "react";

import MarkdownIt from "markdown-it";
import MarkdownItAttrs from "markdown-it-attrs";

const headerSizes = {
  h1: "36px",
  h2: "32px",
  h3: "24px",
  h4: "16px",
  h5: "14px",
  h6: "12px",
};

const mapFromArray = (arr) => {
  const map = {};
  for (let i = 0; i < arr.length; i++) {
    const entry = arr[i];
    map[entry[0]] = entry[1];
  }
  return map;
};

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

function onRenderTokensAsComponents(tokens) {
  // we need to loop through the tokens, finding start and end of each tag and pass the children to the proper render function?
  const definitions = [];
  let current = null;
  tokens.forEach((token) => {
    if (token.nesting === 1) {
      current = token;
    } else if (token.nesting === 0) {
      if (current === null) {
        definitions.push(token);
      } else {
        current.children = (current.children || []).concat([token]);
      }
    } else {
      definitions.push(current);
      current = null;
    }
  });

  return definitions.map((definition, index) => {
    const attrs = mapFromArray(definition.attrs || []);
    switch (definition.tag || definition.type) {
      case "p":
        return (
          <p key={index} style={{ textAlign: attrs["align"] }}>
            {this.render(definition.children || [])}
          </p>
        );
      case "inline":
        return (
          <span key={index}>{this.render(definition.children || [])}</span>
        );
      case "strong":
        return (
          <span key={index} style={{ fontWeight: "bold" }}>
            {this.render(definition.children || [])}
          </span>
        );
      case "em":
        return (
          <span key={index} style={{ fontStyle: "italic" }}>
            {this.render(definition.children || [])}
          </span>
        );
      case "text":
        return <span key={index}>{definition.content}</span>;
      case "h1":
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "h6":
        return (
          <div
            key={index}
            style={{
              fontSize: headerSizes[definition.tag],
              margin: "8px 0",
              fontWeight: attrs["font-weight"] || undefined,
            }}
          >
            {this.render(definition.children || [])}
          </div>
        );
      case "a":
        return (
          <a
            key={index}
            href={attrs["href"] || undefined}
            target={attrs["target"] || undefined}
            style={{ position: "relative", display: "block" }}
          >
            {attrs["overlay"] === "video" ? (
              <span
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  width="64"
                  height="64"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#FFFFFF"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
                  ></path>
                </svg>
              </span>
            ) : (
              <></>
            )}
            {this.render(definition.children || [])}
          </a>
        );
      case "img":
        const image = !isValidUrl(attrs["src"])
          ? this.images.find((image) => {
              return image.filename === attrs["src"];
            })
          : null;

        return (
          <img
            key={index}
            src={
              image
                ? `data:${image.mime_type};base64,${image.content}`
                : attrs["src"] || undefined
            }
            alt={attrs["alt"] || undefined}
            style={{
              width: attrs["width"] || undefined,
              height: attrs["height"] || undefined,
            }}
          />
        );
      case "hr":
        return (
          <hr
            key={index}
            style={{
              borderTopColor: attrs["color"] || undefined,
              borderWidth: "1px 0 0 0",
              borderStyle: "solid",
            }}
          />
        );
      case "br":
        return <br key={index} />;
      case "products-carousel":
        return (
          <span key={index} style={{ display: "block", paddingBottom: "16px" }}>
            <span
              style={{
                width: "100%",
                height: "300px",
                display: "block",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
              }}
            ></span>
          </span>
        );
      default:
        // console.log(definition.tag || definition.type);
        return <></>;
    }
  });
}

const mdComponents = new MarkdownIt().disable([
  "blockquote",
  "code",
  "fence",
  "list",
]);
mdComponents.use(MarkdownItAttrs);
mdComponents.use((md, options) => {
  const PRODUCTS_CAROUSEL = "products-carousel";
  md.inline.ruler.before("emphasis", PRODUCTS_CAROUSEL, (state, silent) => {
    if (state.src.indexOf("@" + PRODUCTS_CAROUSEL, state.pos) === 0) {
      state.pos += ("@" + PRODUCTS_CAROUSEL).length;
      state.push(PRODUCTS_CAROUSEL + "_content", PRODUCTS_CAROUSEL, 0);
      return true;
    } else {
      return false;
    }
  });
});
mdComponents.renderer.render = onRenderTokensAsComponents;

export const MarkdownPreview = memo(({ markdown = "", images = [] }) => {
  mdComponents.renderer.images = images;
  return mdComponents.render(markdown || "");
});
