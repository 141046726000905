import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";
import { ApiHandler } from "./ApiHandler";

SuperTokensRequest.makeSuper(axios);

class DownloadHandler extends ApiHandler {
  async download(path) {
    let response = await axios({
      url: `${this.uri}${path}`,
      method: "GET",
      responseType: "blob",
    });
    return response.data;
  }
}

export default new DownloadHandler();
