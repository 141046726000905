const Utilities = {
  getUserInitials: function (user) {
    return user && user.name
      ? user.name
          .split(" ")
          .map((n) => n[0])
          .join("")
          .toUpperCase()
      : "";
  },
  getResource: function (_object, locale_id) {
    return (_object.resources || []).find((resource) => {
      return resource.locale_id === locale_id;
    });
  },
  getField: function (_object, name) {
    return _object ? _object[name] || "" : "-";
  },
  getResourceFieldByLocale: function (_object, name, locale_id) {
    const resource = Utilities.getResource(_object, locale_id);
    return Utilities.getField(resource || {}, name);
  },
  initResources: function (_object, locales, defaults) {
    const resources = _object.resources || [];
    _object.resources = locales.map((locale) => {
      let resource = resources.find((resource) => {
        return resource.locale_id === locale.locale_id;
      });
      return Object.assign(
        Object.assign(
          {
            locale_id: locale.locale_id,
          },
          defaults
        ),
        resource || {}
      );
    });
  },
};
export default Utilities;
