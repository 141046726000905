import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ThreadSizeHandler, ThreadStandardHandler, Utilities } from "../../api";
import { useStateContext } from "../../state";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

function ThreadSizesPage() {
  const history = useHistory();
  const [state] = useStateContext();

  const [isLoadingThreadSizes, setIsLoadingThreadSizes] = useState(true);

  const [threadStandards, setThreadStandards] = useState([]);
  const [threadSizes, setThreadSizes] = useState([]);
  const [selectedThreadSizes, setSelectedThreadSizes] = useState([]);
  const [isDeletingThreadSize, setIsDeletingThreadSize] = useState(false);
  const [isDeleteThreadSizeDialogVisible, setIsDeleteThreadSizeDialogVisible] =
    useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isLoadingThreadSizes) {
      (async () => {
        try {
          setThreadSizes([]);
          const values = await Promise.all([
            ThreadStandardHandler.list(),
            ThreadSizeHandler.sorted(),
          ]);
          let _threadStandards = values[0];
          let _threadSizes = values[1];

          setThreadStandards(_threadStandards || []);
          setThreadSizes(_threadSizes);
          setIsLoadingThreadSizes(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoadingThreadSizes]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _onItemInvoked = (item) => {
    history.push("/thread-sizes/" + item.thread_size_id);
  };

  const _selectionThreadSizes = useConst(
    new Selection({
      getKey: (threadSize) => {
        return threadSize.thread_size_id + "";
      },
      onSelectionChanged: () => {
        setSelectedThreadSizes(_selectionThreadSizes.getSelection());
      },
    })
  );

  const _onClickDeleteThreadSizeButton = () => {
    setIsDeletingThreadSize(true);

    (async () => {
      try {
        await ThreadSizeHandler.delete(selectedThreadSizes);
        setThreadSizes(
          threadSizes.filter((threadSize) => {
            return !selectedThreadSizes.includes(threadSize);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteThreadSizeDialogVisible(false);
      setIsDeletingThreadSize(false);
    })();
  };

  const _onClickCancelDeleteThreadSizeButton = () => {
    setIsDeleteThreadSizeDialogVisible(false);
    setIsDeletingThreadSize(false);
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "CODE TABLES" }, { text: "THREAD SIZES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Thread Sizes
          </Text>
        </div>

        <CommandBar
          items={[
            {
              key: "newItem",
              text: "New thread size",
              iconProps: { iconName: "Add" },
              onClick: () => history.push("/thread-sizes/new"),
            },
            {
              key: "editItem",
              text: "Edit",
              disabled: selectedThreadSizes.length !== 1,
              iconProps: { iconName: "Edit" },
              onClick: () =>
                history.push(
                  "/thread-sizes/" + selectedThreadSizes[0].thread_size_id
                ),
            },
            {
              key: "deleteItem",
              text: "Delete",
              disabled: selectedThreadSizes.length <= 0,
              iconProps: { iconName: "Trash" },
              onClick: () => {
                setIsDeleteThreadSizeDialogVisible(true);
              },
            },
            // {
            //   key: "refresh",
            //   text: "Refresh",
            //   iconProps: { iconName: "Refresh" },
            //   onClick: () => {
            //     setIsLoadingThreadSizes(true);
            //   },
            // },
          ]}
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!isDeleteThreadSizeDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Thread Size(s)",
            subText:
              "Are you sure you want to delete the selected thread size(s)?",
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {},
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteThreadSizeButton}
              text="Delete"
              disabled={isDeletingThreadSize}
            />
            <DefaultButton
              onClick={_onClickCancelDeleteThreadSizeButton}
              text="Cancel"
            />
            <div
              className={styles.progress}
              style={{ display: isDeletingThreadSize ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <ThreadSizeTable
          setKey="thread-sizes"
          items={threadSizes}
          threadStandards={threadStandards}
          groups={threadSizes
            .reduce((groups, threadSize) => {
              const threadStandard = threadSize.thread_standard
                ? threadStandards.find((threadStandard) => {
                    return (
                      threadStandard.thread_standard_id ===
                      threadSize.thread_standard.thread_standard_id
                    );
                  })
                : null;

              let group = groups.find((group) => {
                return (
                  group.key ===
                  (threadStandard ? threadStandard.thread_standard_id : -1)
                );
              });
              if (group) {
                group.count += 1;
              } else {
                const _resource = threadStandard
                  ? Utilities.getResource(threadStandard, state.locale_id)
                  : null;

                group = threadStandard
                  ? {
                      key: threadStandard.thread_standard_id,
                      name: [
                        _resource
                          ? _resource.name
                          : (threadStandard.code || "").toUpperCase(),
                      ].join(" - "),
                      count: 1,
                    }
                  : {
                      key: -1,
                      name: "-",
                      count: 1,
                    };
                groups.push(group);
              }
              return groups;
            }, [])
            .map((group, index, groups) => {
              if (index === 0) {
                group.startIndex = 0;
              } else {
                group.startIndex =
                  groups[index - 1].startIndex + groups[index - 1].count;
              }
              return group;
            })}
          selection={_selectionThreadSizes}
          selectionPreservedOnEmptyClick={true}
          selectionMode={SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          compact={true}
          onItemInvoked={_onItemInvoked}
          enableShimmer={isLoadingThreadSizes}
        />
      </div>
    </>
  );
}

export default ThreadSizesPage;

export const ThreadSizeTable = (props) => {
  const [state] = useStateContext();

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Description",
      key: "description",
      fieldName: "description",
      data: "string",
      minWidth: 90,
      maxWidth: 120,
      isSorted: true,
    },
    {
      ...columnAttrCommon,
      name: "Nominal Size",
      key: "nominal",
      fieldName: "nominal_size",
      data: "string",
      minWidth: 70,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Pitch / TPI",
      key: "pitch",
      fieldName: "pitch_tpi",
      data: "string",
      minWidth: 90,
      maxWidth: 120,
    },
    {
      ...columnAttrCommon,
      name: "Extra",
      key: "extra",
      fieldName: "extra",
      data: "string",
      minWidth: 90,
      maxWidth: 120,
      onRender: (item) => {
        const resource = Utilities.getResource(item, state.locale_id);
        return Utilities.getField(resource, "extra");
      },
    },
    {
      ...columnAttrCommon,
      name: "Standard",
      key: "standard",
      fieldName: "thread_standard",
      data: "string",
      minWidth: 150,
      maxWidth: 250,
      onRender: (item) => {
        return item.thread_standard
          ? (item.thread_standard.code || "").toUpperCase()
          : "";
      },
    },
  ];

  return <ShimmeredDetailsList {...props} columns={_columns} />;
};
