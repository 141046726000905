import React, { useEffect, useState } from "react";

import { CustomerInputHandler, BulkHandler, DownloadHandler } from "../../api";
import { useStateContext } from "../../state";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from "office-ui-fabric-react";

import { useConst } from "@uifabric/react-hooks";

function CustomerInputsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [exports, setExports] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isExportDialogVisible, setIsExportDialogVisible] = useState(false);

  const CustomerInputsBulkHandler = new BulkHandler("customer-inputs");

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          const values = await Promise.all([
            CustomerInputHandler.list(),
            CustomerInputHandler.exports(),
          ]);
          let inputs = values[0];
          let exports = values[1];

          setExports(
            exports.sort((a, b) => {
              return (a.name || "").localeCompare(b.name || "");
            })
          );
          setItems(inputs);

          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const _closeDeleteDialog = () => {
    setIsDeleteDialogVisible(false);
  };

  const _selection = useConst(
    new Selection({
      getKey: (input) => {
        return input.customer_input_id + "";
      },
      onSelectionChanged: () => {
        setSelectedItems(_selection.getSelection());
      },
    })
  );

  const _onClickDeleteButton = () => {
    setIsDeleting(true);

    (async () => {
      try {
        await CustomerInputHandler.delete(selectedItems);
        setItems(
          items.filter((item) => {
            return !selectedItems.includes(item);
          })
        );
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsDeleteDialogVisible(false);
      setIsDeleting(false);
    })();
  };

  const _onClickCancelButton = () => {
    setIsDeleteDialogVisible(false);
    setIsDeleting(false);
  };

  const _onClickExportButton = () => {
    setIsExporting(true);

    (async () => {
      try {
        await CustomerInputsBulkHandler.export();
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
      setIsExportDialogVisible(false);
      setIsExporting(false);
    })();
  };

  const _onClickCancelExportButton = () => {
    setIsExportDialogVisible(false);
    setIsExporting(false);
  };

  const _onClickDownloadExport = function () {
    (async () => {
      try {
        let data = await DownloadHandler.download(
          `/customer-inputs/exports/${encodeURIComponent(this.filename)}`
        );

        const url = window.URL.createObjectURL(new Blob([data]));
        const element = document.createElement("a");
        element.setAttribute("href", url);
        element.setAttribute("download", this.filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch (e) {
        setError(
          e.response && e.response.data
            ? e.response.data.message || e.message
            : e.message
        );
      }
    })();
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "TELEMETRY" }, { text: "CUSTOMER INPUT" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Customer Input
          </Text>
        </div>
        <CommandBar
          items={[
            {
              key: "deleteItem",
              text: "Delete",
              disabled: selectedItems.length <= 0,
              iconProps: { iconName: "Trash" },
              onClick: () => {
                setIsDeleteDialogVisible(true);
              },
            },
          ]}
          farItems={[
            {
              key: "export",
              text: "Export",
              iconProps: { iconName: "DownloadDocument" },
              split: true,
              subMenuProps: {
                items:
                  exports && exports.length > 0
                    ? exports.map((_export) => {
                        return {
                          key: _export,
                          text: _export,
                          iconProps: { iconName: "ExcelDocument" },
                          onClick: _onClickDownloadExport.bind({
                            filename: _export,
                          }),
                        };
                      })
                    : [
                        {
                          key: "export-none-available",
                          text: "No exports available",
                        },
                      ],
              },
              onClick: () => {
                setIsExportDialogVisible(true);
              },
            },
          ]}
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isDeleteDialogVisible}
          onDismiss={_closeDeleteDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Customer Input(s)",
            subText:
              "Are you sure you want to delete the selected customer input(s)?",
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {},
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickDeleteButton}
              text="Delete"
              disabled={isDeleting}
            />
            <DefaultButton onClick={_onClickCancelButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isDeleting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!isExportDialogVisible}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Export Customer Inputs",
            subText: "Are you sure you want to export all customer inputs?",
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            dragOptions: {},
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={_onClickExportButton}
              text="Export"
              disabled={isExporting}
            />
            <DefaultButton onClick={_onClickCancelExportButton} text="Cancel" />
            <div
              className={styles.progress}
              style={{ display: isExporting ? "" : "none" }}
            >
              <ProgressIndicator />
            </div>
          </DialogFooter>
        </Dialog>
        <CustomerInputTable
          setKey="customer-inputs"
          items={items}
          selectionMode={SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={_selection}
          enableShimmer={isLoading}
        />
      </div>
    </>
  );
}

export const CustomerInputTable = (props) => {
  const [state] = useStateContext();

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Category",
      key: "category",
      fieldName: "category",
      data: "string",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item) => {
        let resource = ((item.category && item.category.resources) || []).find(
          (resource) => {
            return resource.locale_id === state.locale_id;
          }
        );
        return resource ? resource.name : "";
      },
    },
    {
      ...columnAttrCommon,
      name: "Make",
      key: "make",
      fieldName: "make",
      data: "string",
      minWidth: 150,
      maxWidth: 200,
    },
    {
      ...columnAttrCommon,
      name: "Model",
      key: "model",
      fieldName: "model",
      data: "string",
      minWidth: 150,
      maxWidth: 250,
    },

    {
      ...columnAttrCommon,
      name: "Width",
      key: "width",
      fieldName: "width",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Height",
      key: "height",
      fieldName: "height",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Depth",
      key: "depth",
      fieldName: "depth",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Weight",
      key: "weight",
      fieldName: "weight",
      data: "number",
      minWidth: 70,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Units",
      key: "units",
      fieldName: "units",
      data: "string",
      minWidth: 70,
      maxWidth: 90,
      onRender: (item) => {
        return item.units === "i"
          ? "Imperial"
          : item.units === "m"
          ? "Metric"
          : "-";
      },
    },
    {
      ...columnAttrCommon,
      name: "Is Threaded",
      key: "threaded",
      fieldName: "threaded",
      data: "boolean",
      minWidth: 70,
      maxWidth: 90,
      onRender: (item) => {
        return item.threaded ? "Yes" : "No";
      },
    },
  ];

  return <ShimmeredDetailsList {...props} columns={_columns} />;
};

export default CustomerInputsPage;
