import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Nav } from "office-ui-fabric-react";

function Navigator() {
  const history = useHistory();
  const location = useLocation();

  const selectedKey = location.pathname.substr(1).split("/")[0];

  return (
    <Nav
      onLinkClick={(event, element) => {
        if (element.url) {
          event.preventDefault();
          history.push(element.url);
        }
      }}
      styles={{
        root: {
          width: 200,
          overflowY: "hidden",
        },
        groupContent: {
          marginBottom: 5,
        },
      }}
      selectedKey={selectedKey}
      groups={[
        {
          links: [
            {
              key: "",
              name: "Dashboard",
              url: "/",
              icon: "Home",
            },
          ],
        },
        {
          key: "isoacoustics",
          name: "IsoAcoustics",
          links: [
            {
              key: "products",
              name: "Products",
              url: "/products",
              icon: "ProductList",
            },
            {
              key: "product-series",
              name: "Product Series",
              url: "/product-series",
              icon: "ProductCatalog",
            },
            {
              key: "categories",
              name: "Categories",
              url: "/categories",
              icon: "GroupedList",
            },
            {
              key: "adapters",
              name: "Adapters",
              url: "/adapters",
              icon: "Flow",
            },
            {
              key: "dealers",
              name: "Dealers",
              url: "/dealers",
              icon: "CityNext2",
            },
          ],
        },
        {
          key: "manufacturers",
          name: "Manufacturers",
          links: [
            {
              key: "makes",
              name: "Makes",
              url: "/makes",
              icon: "VerifiedBrand",
            },
            {
              key: "models",
              name: "Models",
              url: "/models",
              icon: "ProductVariant",
            },
          ],
        },
        {
          key: "code-tables",
          name: "Code Tables",
          links: [
            {
              key: "locales",
              name: "Locales",
              url: "/locales",
              icon: "LocaleLanguage",
            },
            {
              key: "countries",
              name: "Countries",
              url: "/countries",
              icon: "Flag",
            },
            {
              key: "languages",
              name: "Languages",
              url: "/languages",
              icon: "Globe",
            },
            {
              key: "thread-sizes",
              name: "Thread Sizes",
              url: "/thread-sizes",
              icon: "SliderHandleSize",
            },
            {
              key: "thread-standards",
              name: "Thread Standards",
              url: "/thread-standards",
              icon: "DuplicateRow",
            },
            {
              key: "input-questions",
              name: "Input Questions",
              url: "/input-questions",
              icon: "InsertTextBox",
            },
            {
              key: "product-types",
              name: "Product Types",
              url: "/product-types",
              icon: "ProductRelease",
            },
            {
              key: "orientations",
              name: "Orientations",
              url: "/orientations",
              icon: "Orientation",
            },
          ],
        },
        {
          key: "access",
          name: "Access",
          links: [
            {
              key: "users",
              name: "Users",
              url: "/users",
              icon: "FabricUserFolder",
            },
          ],
        },
        {
          key: "telemetry",
          name: "Telemetry",
          links: [
            {
              key: "customer-inputs",
              name: "Customer Input",
              url: "/customer-inputs",
              icon: "PageHeaderEdit",
            },
            {
              key: "logs",
              name: "Logs",
              url: "/logs",
              icon: "FolderList",
            },
          ],
        },
      ]}
    />
  );
}

export default Navigator;
