import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { OrientationHandler, Utilities } from "../../api";
import { useStateContext } from "../../state";
import routeStyleSets from "./styles";

import {
  mergeStyleSets,
  Breadcrumb,
  CommandBar,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from "office-ui-fabric-react";

const _getItemsSortedByName = (items, locale_id) => {
  return (items || []).sort((a, b) => {
    let aResource = Utilities.getResource(a, locale_id);
    let bResource = Utilities.getResource(b, locale_id);
    return Utilities.getField(aResource, "name").localeCompare(
      Utilities.getField(bResource, "name")
    );
  });
};

function OrientationsPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let orientations = await OrientationHandler.list();
          setItems(orientations);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
  }, [isLoading]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const styles = mergeStyleSets(routeStyleSets);

  const _getKey = (item) => {
    return item ? item.orientation_id : undefined;
  };
  const _onItemInvoked = (item) => {
    history.push("/orientations/" + item.orientation_id);
  };

  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(_selection.getSelection());
    },
  });

  return (
    <>
      <Breadcrumb
        items={[{ text: "CODE TABLES" }, { text: "ORIENTATIONS" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            Orientations
          </Text>
        </div>
        <CommandBar
          items={[
            {
              key: "editItem",
              text: "Edit",
              disabled: selectedItems.length !== 1,
              iconProps: { iconName: "Edit" },
              onClick: () =>
                history.push("/orientations/" + selectedItems[0].orientation_id),
            },
          ]}
          styles={{ root: styles.commandbar }}
        />
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        <OrientationTable
          items={items}
          selectionMode={SelectionMode.multiple}
          getKey={_getKey}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={_selection}
          onItemInvoked={_onItemInvoked}
          enableShimmer={isLoading}
        />
      </div>
    </>
  );
}

export const OrientationTable = (props) => {
  const [state] = useStateContext();

  const columnAttrCommon = {
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick: props.onColumnClick,
  };

  const _columns = [
    {
      ...columnAttrCommon,
      name: "Code",
      key: "code",
      fieldName: "code",
      data: "string",
      minWidth: 50,
      maxWidth: 90,
    },
    {
      ...columnAttrCommon,
      name: "Name",
      key: "name",
      fieldName: "name",
      data: "string",
      minWidth: 100,
      maxWidth: 250,
      isSorted: true,
      onRender: (item) => {
        let resource = Utilities.getResource(item, state.locale_id);
        return Utilities.getField(resource, "name");
      },
    },
  ];

  return (
    <ShimmeredDetailsList
      {...props}
      columns={_columns}
      items={_getItemsSortedByName(props.items || [], state.locale_id)}
    />
  );
};

export default OrientationsPage;
