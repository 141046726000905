import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { ApiHandler } from "./ApiHandler";

SuperTokensRequest.makeSuper(axios);

class SessionHandler extends ApiHandler {
  constructor() {
    super("session");

    this.REFRESH_URL = `${this.uri}/refresh`;
  }

  async validate() {
    let response = await axios.get(`${this.uri}`);
    return response.data;
  }
  async authenticate(username, password) {
    let response = await axios.post(`${this.uri}`, {
      username,
      password,
    });
    return response.data;
  }
  async signout() {
    let response = await axios.delete(`${this.uri}`);
    return response.data;
  }
}

export default new SessionHandler();
