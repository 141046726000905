import React, { useState } from "react";

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Text,
  TextField,
} from "office-ui-fabric-react";

export const TagUtil = {
  getTagsFromString: (tags) => {
    return (tags || "")
      .split(",")
      .map((tag) => {
        return tag.trim();
      })
      .filter((tag) => {
        return tag !== "";
      });
  },
};

export const TagsDialog = (props) => {
  const [tags, setTags] = useState((props.image.tags || []).join(", "));

  const _tagsTextField = React.createRef();

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: "Set Tags",
        subText: "Enter the tags for the selected image:",
      }}
      hidden={false}
      minWidth={450}
      maxWidth={600}
      modalProps={{
        isBlocking: true,
      }}
      onDismiss={() => {
        props.dismissDialog();
      }}
    >
      <TextField
        componentRef={_tagsTextField}
        label={`Tags (${TagUtil.getTagsFromString(tags).length})`}
        styles={{ root: { marginBottom: "5px" } }}
        value={tags}
        onChange={(evt, text) => {
          setTags(text);
        }}
      />
      <Text variant="small" block styles={{ root: { marginBottom: "50px" } }}>
        Note: Multiple tags must be comma delimited.
      </Text>
      <DialogFooter>
        <PrimaryButton
          text={"Set"}
          onClick={() => {
            props.dismissDialog(TagUtil.getTagsFromString(tags));
          }}
        />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            props.dismissDialog();
          }}
        />
      </DialogFooter>
    </Dialog>
  );
};
