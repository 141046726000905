import SuperTokensRequest from "supertokens-website/axios";
import axios from "axios";

import { ApiHandler } from "./ApiHandler";

SuperTokensRequest.makeSuper(axios);

export class EntityHandler extends ApiHandler {
  constructor(path, idAttr) {
    super(path);
    this.idAttr = idAttr;
  }

  async list() {
    let response = await axios.get(`${this.uri}`);
    return response.data;
  }

  async get(id, params) {
    let response = await axios.get(`${this.uri}/${id}`, {
      params: params || {},
    });
    return response.data;
  }

  async new(obj) {
    let response = await axios.post(`${this.uri}`, obj);
    return response.data;
  }

  async update(obj) {
    let response = await axios.put(`${this.uri}/${obj[this.idAttr]}`, obj);
    return response.data;
  }

  async delete(objects) {
    let response = await axios.delete(`${this.uri}`, {
      data: (objects || []).map((obj) => {
        return obj[this.idAttr];
      }),
    });
    return response.data;
  }
}
