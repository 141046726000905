import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import filesize from "filesize";
import validator from "validator";

import {
  CategoryHandler,
  InputQuestionHandler,
  OrientationHandler,
  Utilities,
} from "../../api";
import { useStateContext } from "../../state";

import routeStyleSets from "./styles";

import {
  getId,
  mergeStyles,
  mergeStyleSets,
  Breadcrumb,
  Checkbox,
  ComboBox,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Separator,
  Shimmer,
  ShimmerElementType,
  Text,
  TextField,
} from "office-ui-fabric-react";

import { DealerTable } from "./DealersPage";
import { ModelTable } from "./ModelsPage";
import { ProductTable } from "./ProductsPage";

import TextFieldLocale from "../TextFieldLocale";

function CategoryPage() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [state] = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({});
  const [inputQuestions, setInputQuestions] = useState([]);
  const [orientations, setOrientations] = useState([]);

  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [isModelsLoading, setIsModelsLoading] = useState(false);
  const [models, setModels] = useState(null);
  const [isDealersLoading, setIsDealersLoading] = useState(false);
  const [dealers, setDealers] = useState(null);

  const { from } = location.state || {
    from: { pathname: "/categories" },
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          let _form, _inputQuestions, _orientations;
          // if categoy location, retrieve category, else set defaults
          if (location.pathname.endsWith("new")) {
            const values = await Promise.all([
              InputQuestionHandler.list(),
              OrientationHandler.list(),
            ]);

            _inputQuestions = values[0];
            _orientations = values[1];

            _form = { input_questions: [] };
          } else {
            const values = await Promise.all([
              InputQuestionHandler.list(),
              OrientationHandler.list(),
              CategoryHandler.get(params.id),
            ]);
            _inputQuestions = values[0];
            _orientations = values[1];

            const _category = values[2];
            _category.input_questions = (_category.input_questions || []).map(
              (inputQuestion) => {
                return inputQuestion.input_question_id;
              }
            );
            _form = _category;
          }

          Utilities.initResources(_form, state.locales, {
            name: "",
            category_id: _form.category_id,
          });

          setInputQuestions(_inputQuestions || []);
          setOrientations(_orientations || []);

          setForm(_form);
          setIsLoading(false);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const styles = mergeStyleSets(routeStyleSets);
  const shimmers = mergeStyles({
    marginTop: "20px",
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });

  const title = location.pathname.endsWith("new")
    ? "New Category"
    : `Category: ${Utilities.getResourceFieldByLocale(
        form,
        "name",
        state.locale_id
      )}`;

  const [isValidated, setValidated] = useState(false);
  const isCodeValid = !validator.isEmpty((form.code || "").trim());
  const isNameValid = (form.resources || []).every(
    (resource) => !validator.isEmpty((resource.name || "").trim())
  );

  // general
  const _codeTextField = React.createRef();
  const _inputQuestionsComboboxField = React.createRef();
  const _imageUploadField = React.createRef();

  const _onChangeTextField = (attr, event, text) => {
    setForm({
      ...form,
      [attr]: text,
    });
  };

  const _onChangeCheckbox = (attr, event, checked) => {
    setForm({
      ...form,
      [attr]: checked,
    });
  };

  const _onChangeComboBox = (attr, event, item) => {
    const selected = [...(form[attr] || [])];
    if (item.selected) {
      selected.push(item.key);
    } else {
      const index = selected.indexOf(item.key);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setForm({
      ...form,
      [attr]: selected,
    });
  };

  const _onClickSave = () => {
    if (isCodeValid && isNameValid) {
      setIsSaving(true);
    }
    setValidated(true);
  };

  const requiredPivotFields = {
    general: [isCodeValid, isNameValid],
  };
  const _getInvalidPivotItemFormCount = (itemKey) => {
    var count = 0;
    if (isValidated) {
      count = (requiredPivotFields[itemKey] || []).reduce((count, isValid) => {
        if (!isValid) {
          count += 1;
        }
        return count;
      }, 0);
    }
    return count === 0 ? undefined : count;
  };

  const _onLinkClickPivot = (pivotItem) => {
    const key = pivotItem.props.itemKey;
    if (key === "products") {
      if (products == null && !isProductsLoading) {
        setIsProductsLoading(true);
      }
    } else if (key === "models") {
      if (models == null && !isModelsLoading) {
        setIsModelsLoading(true);
      }
    } else if (key === "dealers") {
      if (dealers == null && !isDealersLoading) {
        setIsDealersLoading(true);
      }
    }
  };

  useEffect(() => {
    if (isProductsLoading) {
      (async () => {
        try {
          setProducts(await CategoryHandler.products(form.category_id));
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        } finally {
          setIsProductsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductsLoading]);

  useEffect(() => {
    if (isModelsLoading) {
      (async () => {
        try {
          setModels(await CategoryHandler.models(form.category_id));
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        } finally {
          setIsModelsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModelsLoading]);

  useEffect(() => {
    if (isDealersLoading) {
      (async () => {
        try {
          setDealers(await CategoryHandler.dealers(form.category_id));
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        } finally {
          setIsDealersLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDealersLoading]);

  useEffect(() => {
    if (isSaving) {
      (async () => {
        try {
          const data = Object.assign({}, form);

          data.input_questions = data.input_questions.map(
            (input_question_id) => {
              return {
                input_question_id: input_question_id,
              };
            }
          );

          if (data.image) {
            if (Number.isFinite(data.image.category_image_id)) {
              // nothing to update
              delete data.image.content;
              delete data.image.mime_type;
              delete data.image.filename;
              delete data.image.filesize;
            } else {
              delete data.image.category_image_id;
            }
          }

          if (data.category_id) {
            await CategoryHandler.update(data);
          } else {
            await CategoryHandler.new(data);
          }

          history.replace(from);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
          setIsSaving(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const _onClickCloseErrorDialog = () => {
    setError(null);
  };

  const _onChangeUploadFile = () => {
    if (_imageUploadField.current) {
      const file = _imageUploadField.current.files[0];
      if (file) {
        const reader = new FileReader();

        reader.addEventListener(
          "load",
          function () {
            // convert image file to base64 string
            setForm({
              ...form,
              image: {
                category_image_id: getId(),
                filename: file.name,
                filesize: file.size,
                content: reader.result.substring(
                  reader.result.indexOf("base64,") + "base64,".length
                ),
                mime_type: file.type,
              },
            });
          },
          false
        );
        reader.readAsDataURL(file);
      }
    }
  };

  const _onClickDownloadImage = () => {
    if (form.image) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:${form.image.mime_type};base64, ${form.image.content}`
      );
      element.setAttribute("download", form.image.filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  };

  const _onClickDeleteImage = () => {
    if (form.image) {
      setForm({
        ...form,
        image: null,
      });
    }
  };

  return (
    <>
      <Breadcrumb
        items={[{ text: "ISOACOUSTICS" }, { text: "CATEGORIES" }]}
        dividerAs={(dividerProps) => (
          <span className={styles.breadcrumbDivider}>/</span>
        )}
        styles={{
          root: styles.breadcrumb,
          item: styles.breadcrumbItem,
        }}
      />
      <div className={styles.body}>
        <div className={styles.header}>
          <Text variant="large" block>
            {title}
          </Text>
        </div>
        <Dialog
          hidden={!error}
          dialogContentProps={{
            type: DialogType.close,
            title: "Error",
            subText: error || "",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={_onClickCloseErrorDialog} text="Ok" />
          </DialogFooter>
        </Dialog>
        {isLoading ? (
          <div className={shimmers}>
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: 10 },
                { type: ShimmerElementType.line, width: 75, height: 30 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.gap, width: "100%", height: 35 },
              ]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 50, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
            <Shimmer
              shimmerElements={[
                { type: ShimmerElementType.line, width: 75, height: 25 },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            />
            <Shimmer
              shimmerElements={[{ type: ShimmerElementType.line, height: 35 }]}
            />
          </div>
        ) : (
          <>
            <div className={styles.buttonbar}>
              <PrimaryButton text="Save" onClick={_onClickSave} />
              <DefaultButton
                text="Discard"
                onClick={() => history.replace(from)}
              />
            </div>
            <div className={styles.pivot}>
              <Dialog
                hidden={!isSaving}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Saving...",
                  subText:
                    "Please be patient while the category is being saved.",
                }}
                modalProps={{
                  isBlocking: true,
                  styles: { main: { maxWidth: 450 } },
                }}
              >
                <ProgressIndicator />
              </Dialog>
              <Pivot
                styles={{
                  root: styles.pivotControl,
                  count: styles.pivotItemInvalidCount,
                }}
                onLinkClick={_onLinkClickPivot}
              >
                <PivotItem
                  itemKey="general"
                  headerText="General"
                  itemCount={_getInvalidPivotItemFormCount("general")}
                >
                  <div className={styles.formSectioned}>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Information
                      </Separator>
                      <TextField
                        componentRef={_codeTextField}
                        label="Code"
                        styles={{ root: styles.field }}
                        onChange={_onChangeTextField.bind(this, "code")}
                        value={form.code}
                        required={true}
                        errorMessage={
                          isValidated && !isCodeValid
                            ? "Code must be at least one character in length."
                            : ""
                        }
                      ></TextField>
                      <TextFieldLocale
                        field="name"
                        label="Name"
                        form={form}
                        setForm={setForm}
                        styles={{ root: styles.field }}
                        required={true}
                        errorMessage={
                          isValidated && !isNameValid
                            ? "Name must be at least one character in length for each locale."
                            : ""
                        }
                      />
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Details
                      </Separator>
                      <ComboBox
                        componentRef={_inputQuestionsComboboxField}
                        multiSelect
                        selectedKey={form.input_questions || []}
                        label="Input Questions"
                        allowFreeform={false}
                        autoComplete="off"
                        styles={{ container: styles.field }}
                        onChange={_onChangeComboBox.bind(
                          this,
                          "input_questions"
                        )}
                        options={(inputQuestions || []).map((inputQuestion) => {
                          let resource = (inputQuestion.resources || []).find(
                            (resource) => {
                              return resource.locale_id === state.locale_id;
                            }
                          );
                          return {
                            key: inputQuestion.input_question_id,
                            text: resource ? resource.name : "",
                          };
                        })}
                      />
                      <Checkbox
                        label="Allows multiple orientation models"
                        styles={{ root: styles.checkbox }}
                        checked={form.multi_orientation}
                        onChange={_onChangeCheckbox.bind(
                          this,
                          "multi_orientation"
                        )}
                      />
                      <Checkbox
                        label="Allows non-threaded models"
                        styles={{ root: styles.checkbox }}
                        checked={form.allow_non_threaded}
                        onChange={_onChangeCheckbox.bind(
                          this,
                          "allow_non_threaded"
                        )}
                      />
                    </div>
                    <div className={styles.formSection}>
                      <Separator
                        alignContent="start"
                        styles={{
                          root: styles.separator,
                          content: { paddingLeft: "0px" },
                        }}
                      >
                        Image
                      </Separator>
                      <div className={styles.field}>
                        <Label>
                          {form.image
                            ? `${form.image.filename} ${filesize(
                                form.image.filesize
                              )}`
                            : "Upload an image"}
                        </Label>
                        <div
                          style={{
                            width: "200px",
                            height: "198px",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "rgb(163, 162, 160)",
                            boxSizing: "border-box",
                            borderRadius: "2px",
                            textAlign: "center",
                          }}
                        >
                          {form.image ? (
                            <img
                              src={`data:${form.image.mime_type};base64,${form.image.content}`}
                              style={{ width: "75px", marginTop: "59px" }}
                              alt={form.image.filename}
                            />
                          ) : null}
                        </div>
                      </div>
                      <CommandBar
                        items={[
                          {
                            key: "upload",
                            text: "Upload",
                            iconProps: { iconName: "Upload" },
                            onClick: () => {
                              if (_imageUploadField.current) {
                                _imageUploadField.current.click();
                              }
                            },
                          },
                          {
                            key: "upload-image",
                            onRender: () => (
                              <input
                                ref={_imageUploadField}
                                style={{ display: "none" }}
                                type="file"
                                accept="image/jpg,image/jpeg,image/svg+xml"
                                onChange={_onChangeUploadFile}
                              />
                            ),
                          },
                          {
                            key: "download",
                            text: "Download",
                            iconProps: { iconName: "Download" },
                            disabled: !form.image,
                            onClick: _onClickDownloadImage,
                          },
                          {
                            key: "delete",
                            iconProps: { iconName: "Delete" },
                            disabled: !form.image,
                            onClick: _onClickDeleteImage,
                          },
                        ]}
                        styles={{ root: styles.commandbar }}
                      />
                    </div>
                  </div>
                </PivotItem>
                {form && form.category_id ? (
                  <PivotItem itemKey="products" headerText="Products">
                    <ProductTable
                      setKey="products"
                      items={products || []}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      hideRelated={true}
                      enableShimmer={isProductsLoading}
                    />
                  </PivotItem>
                ) : null}
                {form && form.category_id ? (
                  <PivotItem itemKey="models" headerText="Models">
                    <ModelTable
                      setKey="models"
                      orientations={orientations || []}
                      items={models || []}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      hideMake={false}
                      hideRelated={true}
                      enableShimmer={isModelsLoading}
                    />
                  </PivotItem>
                ) : null}
                {form && form.category_id ? (
                  <PivotItem itemKey="dealers" headerText="Dealers">
                    <DealerTable
                      setKey="dealers"
                      items={dealers || []}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      hideMake={false}
                      hideRelated={true}
                      enableShimmer={isDealersLoading}
                    />
                  </PivotItem>
                ) : null}
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CategoryPage;
