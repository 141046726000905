import { EntityHandler } from "./EntityHandler";

class MakeHandler extends EntityHandler {
  constructor() {
    super("makes", "make_id");
  }

  async sorted() {
    const list = await this.list();
    return list.sort((a, b) => {
      return (a.name || "").localeCompare(b.name || "");
    });
  }
}

export default new MakeHandler();
